import { Canvas, EvaluateExpressionType } from "../Types";

export function migrateFileCanvasForLegacy(
  fileCanvas: any,
  version: number
): Canvas {
  const rv = { ...fileCanvas };
  let hasChanges = false;
  rv.panes = rv.panes.map((p: any) => {
    const returnPane = { ...p };
    if (
      returnPane.renderOutput?.[0] === "custom" &&
      returnPane.renderOutput[1] === undefined
    ) {
      const customPaneInput = returnPane.inputs?.find(
        (input: any) => input.name === "__OUTPUT__"
      );
      if (customPaneInput !== undefined) {
        hasChanges = true;
        returnPane.inputs = returnPane.inputs.filter(
          (input: any) => input.id !== customPaneInput.id
        );
        returnPane.renderOutput = ["custom", customPaneInput.source];
      }
    }
    if (returnPane.isFunctionBody !== undefined) {
      hasChanges = true;
      if (returnPane.isFunctionBody) {
        returnPane.expressionType = EvaluateExpressionType.FunctionBody;
      }
      delete returnPane.isFunctionBody;
    }
    return returnPane;
  });
  return hasChanges ? rv : fileCanvas;
}
