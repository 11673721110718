import { EventEmitter } from "eventemitter3";
import { logEvent } from "../Amplitude";
import { syncCanvasIdAtom } from "../SharedIframeState";

export const EnvironmentVariablesChangeEmitter = new EventEmitter();

let hasSessionPermission = false;

export function hasEnvironmentVariablesSessionPermission() {
  return hasSessionPermission;
}

export function isAuthorizedForEnvironmentVariables() {
  return syncCanvasIdAtom.value?.[0] === "canvas" || hasSessionPermission;
}

export function grantEnvironmentVariablesSession() {
  if (
    window.confirm(
      "Do you want to grant this canvas access to your environment variables for this session? You can revoke access from the header dropdown."
    )
  ) {
    hasSessionPermission = true;
    EnvironmentVariablesChangeEmitter.emit("change", "*");
    logEvent("grant environment access");
  }
}

export function revokeEnvironmentVariablesSession() {
  hasSessionPermission = false;
  EnvironmentVariablesChangeEmitter.emit("change", "*");
  logEvent("revoke environment access");
}

export function resetEnvironmentVariablesSession() {
  hasSessionPermission = false;
  EnvironmentVariablesChangeEmitter.emit("change", "*");
}
