import * as Sentry from "@sentry/react";
import { action, autorun, makeAutoObservable, reaction, set, toJS } from "mobx";
import { atom } from "./atom";
import { PARENT_ORIGIN, SANDBOX_ORIGIN } from "./Constants";
import { CanvasSelection, SyncCanvasId, User } from "./Types";

export const canvasSelectionAtom = atom<CanvasSelection | undefined>(undefined);
export const syncCanvasIdAtom = atom<SyncCanvasId | undefined>(undefined);
export const userAtom = atom<User | undefined>(undefined);

type Frame = "parent" | "sandbox";
const frame: Frame =
  VITE_APP_IS_SANDBOX || window.__IS_SANDBOX === true ? "sandbox" : "parent";

const LOCAL_STORAGE_KEYS = {
  theme: "theme",
  vimEnabled: "vim-enabled",
};

type AppSettings = {
  theme: "light" | "dark";
  vimEnabled: boolean;
};
export const appSettingsMobx = makeAutoObservable<AppSettings>({
  vimEnabled:
    frame === "parent" &&
    localStorage.getItem(LOCAL_STORAGE_KEYS.vimEnabled) === "true",
  theme: document.documentElement.classList.contains("dark") ? "dark" : "light",
});

type UIState = {
  maximizedPaneId: string | undefined;
};
export const uiStateMobx = makeAutoObservable<UIState>({
  maximizedPaneId: undefined,
});

// Theme is a little different because the sandbox needs to know the theme
// before the parent iframe sends any messages.
// For others, we can wait for the parent iframe to set the value.
reaction(
  () => appSettingsMobx.theme,
  (theme) => {
    document.documentElement.classList.add("disable-transition");
    setTimeout(() => {
      document.documentElement.classList.remove("disable-transition");
    }, 300);
    if (theme === "dark") {
      document.documentElement.classList.add("dark");
      localStorage.setItem(LOCAL_STORAGE_KEYS.theme, "dark");
    } else {
      document.documentElement.classList.remove("dark");
      localStorage.setItem(LOCAL_STORAGE_KEYS.theme, "light");
    }
  }
);

let initializeSharedIframeState = () => {};

type SandboxStateId =
  | "CanvasSelection"
  | "SyncCanvasId"
  | "User"
  | "UI"
  | "AppSettings"
  | "Theme"
  | "VimEnabled";

if (frame === "sandbox") {
  function onMessage(message: MessageEvent) {
    switch (message.data.type) {
      case "SYNC_IFRAME_STATE": {
        const { stateId, value }: { stateId: SandboxStateId; value: any } =
          message.data;
        switch (stateId) {
          case "CanvasSelection":
            // don't update canvasSelection. sandbox iframe will reload
            if (canvasSelectionAtom.value === undefined) {
              canvasSelectionAtom.value = value;
            }
            break;
          case "SyncCanvasId":
            syncCanvasIdAtom.value = value;
            break;
          case "User":
            userAtom.value = value;
            Sentry.setUser(
              value !== undefined
                ? {
                    id: value.id,
                    username: value.username,
                  }
                : null
            );
            break;
          case "AppSettings":
            appSettingsMobx.theme = value.theme;
            appSettingsMobx.vimEnabled = value.vimEnabled;
            break;
          case "Theme":
            appSettingsMobx.theme = value;
            break;
          case "VimEnabled":
            appSettingsMobx.vimEnabled = value;
            break;
        }
        break;
      }
    }
  }
  window.addEventListener("message", action(onMessage));

  function syncIframeState(stateId: SandboxStateId, value: any) {
    window.parent.postMessage(
      {
        type: "SYNC_IFRAME_STATE",
        stateId,
        value,
      },
      PARENT_ORIGIN
    );
  }
  autorun(() => {
    syncIframeState("UI", toJS(uiStateMobx));
  });
  autorun(() => {
    syncIframeState("Theme", appSettingsMobx.theme);
  });
  autorun(() => {
    syncIframeState("VimEnabled", appSettingsMobx.vimEnabled);
  });
} else {
  initializeSharedIframeState = () => {
    const iframe = document.getElementById(
      "sandbox-iframe"
    ) as HTMLIFrameElement;
    function syncIframeState(stateId: SandboxStateId, value: any) {
      iframe.contentWindow!.postMessage(
        {
          type: "SYNC_IFRAME_STATE",
          stateId,
          value,
        },
        SANDBOX_ORIGIN
      );
    }
    let unsubscribe: (() => void)[] = [];
    function initSandbox() {
      unsubscribe.forEach((unsub) => unsub());
      unsubscribe = [
        autorun(() => {
          syncIframeState("CanvasSelection", canvasSelectionAtom.value);
        }),
        autorun(() => {
          syncIframeState("SyncCanvasId", syncCanvasIdAtom.value);
        }),
        autorun(() => {
          syncIframeState("User", userAtom.value);
        }),
      ];
      syncIframeState("AppSettings", toJS(appSettingsMobx));
    }
    function onMessage(message: MessageEvent) {
      switch (message.data.type) {
        case "SANDBOX_INIT": {
          initSandbox();
          break;
        }
        case "SYNC_IFRAME_STATE": {
          const { stateId, value }: { stateId: SandboxStateId; value: any } =
            message.data;
          switch (stateId) {
            case "UI":
              set(uiStateMobx, value);
              break;
            case "Theme":
              appSettingsMobx.theme = value;
              break;
            case "VimEnabled":
              appSettingsMobx.vimEnabled = value;
              break;
          }
          break;
        }
      }
    }
    if (window.__DID_SANDBOX_INIT) {
      initSandbox();
    }
    window.addEventListener("message", action(onMessage));

    reaction(
      () => appSettingsMobx.vimEnabled,
      (isVimEnabled) => {
        if (isVimEnabled) {
          localStorage.setItem(LOCAL_STORAGE_KEYS.vimEnabled, "true");
        } else {
          localStorage.removeItem(LOCAL_STORAGE_KEYS.vimEnabled);
        }
      }
    );
  };
}

export { initializeSharedIframeState };
