import { makeObservable, observable } from "mobx";

export type MobxAtom<T> = {
  value: T;
};

export function atom<T>(initialValue: T): MobxAtom<T> {
  return makeObservable({ value: initialValue }, { value: observable.ref });
}

export function deepAtom<T>(initialValue: T): MobxAtom<T> {
  return makeObservable({ value: initialValue }, { value: observable.deep });
}
