import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import "focus-visible";
import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import { initSession } from "./parent/API";
import App from "./parent/App";
import {
  exposeParentComlink,
  initializeParentFrame,
} from "./parent/FrameParentAPI";
import { initPath } from "./parent/PathHandler";
import { initializeSharedIframeState } from "./SharedIframeState";

if (import.meta.env.PROD) {
  Sentry.init({
    dsn: "https://76cc0c27745845258a09a76d2ffcbe6a@o549520.ingest.sentry.io/5672409",
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.1,
    release: VITE_APP_COMMIT_REF,
    ignoreErrors: [
      // https://sentry.io/organizations/natto/issues/2269408220/
      `Failed to execute 'transaction' on 'IDBDatabase': The database connection is closing.`,
    ],
  });
}

initSession();
initPath();
exposeParentComlink();
initializeParentFrame();
initializeSharedIframeState();

const root = createRoot(document.getElementById("root")!);
root.render(<App />);
