import { ChangeSet, Text } from "@codemirror/state";
import deepEqual from "fast-deep-equal";
import { useLayoutEffect, useRef } from "react";
import { Pane, Position, Rect } from "./Types";

export * from "../../shared/Utils";

export const isSafari = /^((?!chrome|android).)*safari/i.test(
  navigator.userAgent
);

export function doArraysIntersect(a: Array<any>, b: Array<any>): boolean {
  return a.some((v) => b.includes(v));
}
export function isPointInsideRect(point: Position, rect: Rect): boolean {
  return (
    point[0] >= rect[0] &&
    point[0] < rect[0] + rect[2] &&
    point[1] >= rect[1] &&
    point[1] < rect[1] + rect[3]
  );
}
export function areArraysEqual(a: readonly any[], b: readonly any[]): boolean {
  return a.length === b.length && a.every((v, i) => b[i] === v);
}

export function areRecordsEqual(
  a: Record<string, any>,
  b: Record<string, any>
): boolean {
  const aKeys = Object.keys(a);
  const bKeys = Object.keys(b);
  if (aKeys.length !== bKeys.length) {
    return false;
  }
  return aKeys.every((key) => a[key] === b[key]);
}

export function useSyncRef<T>(value: T): React.MutableRefObject<T> {
  const ref = useRef(value);
  useLayoutEffect(() => void (ref.current = value));
  return ref;
}
export function throttle<F extends (...args: any) => void>(
  f: F,
  ms: number | ((...args: Parameters<F>) => number)
): F & { cancel: () => void } {
  let timeout: number | undefined;
  const rv = ((...args) => {
    if (timeout !== undefined) {
      clearTimeout(timeout);
    }
    timeout = window.setTimeout(
      () => {
        f(...args);
        timeout = undefined;
      },
      typeof ms === "function" ? ms(...args) : ms
    );
  }) as F;
  // @ts-ignore
  rv.cancel = () => {
    if (timeout !== undefined) {
      clearTimeout(timeout);
      timeout = undefined;
    }
  };
  // @ts-ignore
  return rv;
}

export function debounce<F extends (...args: any) => void>(
  f: F,
  ms: number
): F & { cancel: () => void } {
  let timeout: number | undefined;
  let argsToCall: any[] = [];
  const rv = ((...args) => {
    argsToCall = args;
    if (timeout === undefined) {
      timeout = window.setTimeout(() => {
        f(...argsToCall);
        timeout = undefined;
      }, ms);
    }
  }) as F;
  // @ts-ignore
  rv.cancel = () => {
    if (timeout !== undefined) {
      clearTimeout(timeout);
      timeout = undefined;
    }
  };
  // @ts-ignore
  return rv;
}

export function downloadAsTextFile(filename: string, contents: string) {
  const element = document.createElement("a");
  element.setAttribute(
    "href",
    "data:text/plain;charset=utf-8," + encodeURIComponent(contents)
  );
  element.setAttribute("download", filename);
  element.style.display = "none";
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}

export function doRectsOverlap(r1: Rect, r2: Rect): boolean {
  if (r1[0] > r2[0] + r2[2] || r2[0] > r1[0] + r1[2]) {
    return false;
  }
  if (r1[1] > r2[1] + r2[3] || r2[1] > r1[1] + r1[3]) {
    return false;
  }
  return true;
}

export function getParentByFilter(
  element: HTMLElement | { parentElement: HTMLElement | null },
  filter: (element: HTMLElement) => boolean
): HTMLElement | undefined {
  let iter: HTMLElement | null =
    element instanceof HTMLElement ? element : element.parentElement;
  while (iter !== null) {
    if (filter(iter)) {
      return iter;
    }
    iter = iter.parentElement;
  }
  return undefined;
}

export function getParentByClassName(
  element: HTMLElement | { parentElement: HTMLElement | null },
  className: string
): HTMLElement | undefined {
  let iter: HTMLElement | null =
    element instanceof HTMLElement ? element : element.parentElement;
  while (iter !== null) {
    if (iter.classList.contains(className)) {
      return iter;
    }
    iter = iter.parentElement;
  }
  return undefined;
}

export const isWindowsPlatform = navigator.platform.indexOf("Win") !== -1;
export const isMacPlatform = navigator.platform.indexOf("Mac") !== -1;
export const isFirefox = navigator.userAgent.indexOf("Gecko") !== -1;
export const modifierKey = isMacPlatform ? "Meta" : "Control";
// modifier key paired with ctrl
export const secondaryModifierKey = isMacPlatform ? "Meta" : "Alt";
export const secondaryModifierKeyDisplayText = isMacPlatform ? "⌘" : "⌥";

export function isModifierKeyDown(metaKey: boolean, ctrlKey: boolean) {
  switch (modifierKey) {
    case "Meta":
      return metaKey;
    case "Control":
      return ctrlKey;
  }
}

export function isSecondaryModifierKeyDown(metaKey: boolean, altKey: boolean) {
  switch (secondaryModifierKey) {
    case "Meta":
      return metaKey;
    case "Alt":
      return altKey;
  }
}

export function applyTextChangeSet(text: string, changesJSON: any): string {
  return ChangeSet.fromJSON(changesJSON)
    .apply(Text.of(text.split("\n")))
    .toString();
}

export function useEqualRefValue<T>(
  value: T,
  equalityFn: (a: T, b: T) => boolean = deepEqual
): T {
  const valueRef = useRef(value);
  let v = value;
  // use previous value if deepEqual
  if (valueRef.current === value || equalityFn(valueRef.current, value)) {
    v = valueRef.current;
  }
  useLayoutEffect(() => {
    valueRef.current = v;
  });
  return v;
}

export function arePanesEqualIgnoringLayout(
  prevPane: Pane | undefined,
  nextPane: Pane | undefined
) {
  if (prevPane === nextPane) {
    return true;
  }
  if (prevPane === undefined) {
    return nextPane === undefined;
  }
  if (nextPane === undefined) {
    return false;
  }
  const prevPaneKeys = Object.keys(prevPane);
  const nextPaneKeys = Object.keys(nextPane);
  if (prevPaneKeys.length !== nextPaneKeys.length) {
    return false;
  }
  for (const key of prevPaneKeys) {
    if (key === "rect" || key === "z" || key === "editorHeight") {
      continue;
    }
    // @ts-ignore
    if (!Object.is(prevPane[key], nextPane[key])) {
      return false;
    }
  }
  return true;
}
