import classNames from "classnames";
import { action, computed, runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { logEvent } from "../Amplitude";
import { atom } from "../atom";
import { userAtom } from "../SharedIframeState";
import {
  DialogContent,
  DialogHeader,
  DialogOverlay,
  DialogPortal,
  DialogRoot,
} from "../ui/Dialog";
import { FeatherIcon } from "../ui/FeatherIcon";
import {
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuRoot,
  DropdownMenuTrigger,
} from "../ui/Menu";
import { SecretValue } from "../ui/SecretValue";
import { TextInput } from "../ui/TextInput";
import { Tooltip } from "../ui/Tooltip";
import {
  deleteUserEnvironmentVariable,
  fetchUserEnvironmentVariables,
  sessionAtom,
  setUserEnvironmentVariable,
  userEnvironmentVariablesMobx,
} from "./API";
import { EnvironmentVariableDB } from "./EnvironmentVariablesDB";
import { EnvironmentVariablesChangeEmitter } from "./EnvironmentVariablesSession";
import { showLoginDialog } from "./LoginDialog";

const NewVariableForm = observer(
  ({
    initialKey,
    refreshBrowserVariables,
  }: {
    initialKey: string | undefined;
    refreshBrowserVariables: () => void;
  }) => {
    const isLoggedIn = computed(() => sessionAtom.value !== undefined).get();
    const [key, setKey] = useState(initialKey ?? "");
    const [value, setValue] = useState("");
    const [variableType, setVariableType] = useState<"user" | "browser">(() =>
      isLoggedIn ? "user" : "browser"
    );
    if (!isLoggedIn && variableType === "user") {
      setVariableType("browser");
    }
    const [isAdding, setIsAdding] = useState(false);
    const [error, setError] = useState<string | undefined>(undefined);
    const valueInputRef = useRef<HTMLInputElement | null>(null);
    useEffect(() => {
      if (initialKey !== undefined) {
        valueInputRef.current!.focus();
      }
    }, []);

    return (
      <form
        onSubmit={async (e) => {
          e.preventDefault();
          if (key.trim() === "") {
            return;
          }
          setIsAdding(true);
          try {
            switch (variableType) {
              case "browser": {
                const existingRow = await EnvironmentVariableDB.getVariable(
                  key
                );
                if (existingRow !== undefined) {
                  setError(`${key} already exists`);
                  return;
                }
                await EnvironmentVariableDB.putVariable(key, value);
                refreshBrowserVariables();
                break;
              }
              case "user": {
                try {
                  await setUserEnvironmentVariable(key, value);
                } catch (e: any) {
                  setError(e.message);
                  return;
                }
                break;
              }
            }
            setKey("");
            setValue("");
            logEvent("add environment variable", {
              type: variableType,
            });
            EnvironmentVariablesChangeEmitter.emit("change", key);
          } finally {
            setIsAdding(false);
          }
        }}
      >
        <div className="text-sm font-medium mb-2">add variable</div>
        <div className="p-4 bg-neutral-50 border border-neutral-200 rounded">
          <div className="flex items-end">
            <label className="w-1/3 group relative">
              <div className="mb-1 text-neutral-500 group-focus-within:text-neutral-700 transition">
                key
              </div>
              <div className="mr-2 sm:mr-4">
                <TextInput
                  value={key}
                  placeholder="MY_API_KEY"
                  onChange={(e) => {
                    setKey(e.target.value);
                    setError(undefined);
                  }}
                  className="w-full"
                />
              </div>
              {error !== undefined ? (
                <div className="absolute top-full mt-px text-red-500 text-2xs whitespace-nowrap">
                  {error}
                </div>
              ) : null}
            </label>
            <label className="w-1/3 group">
              <div className="mb-1 text-neutral-500 group-focus-within:text-neutral-700 transition">
                value
              </div>
              <div className="mr-2 sm:mr-4">
                <TextInput
                  value={value}
                  placeholder="5B10OQJGGY9A"
                  onChange={(e) => setValue(e.target.value)}
                  className="w-full"
                  ref={valueInputRef}
                />
              </div>
            </label>
            <button
              className="button-dark disabled:opacity-50"
              disabled={key.trim() === "" || isAdding}
            >
              add
            </button>
          </div>
          <div className="flex gap-4 mt-4">
            <label className="flex items-center gap-1">
              <input
                type="radio"
                name="variable_type"
                value="user"
                checked={variableType === "user"}
                disabled={!isLoggedIn}
                onChange={(e) => {
                  if (e.target.checked) {
                    setVariableType("user");
                  }
                }}
              />
              <span>user variable</span>
              {!isLoggedIn ? (
                <span className="text-neutral-400">
                  {" – "}
                  <button
                    onClick={() => {
                      showLoginDialog("environment variables dialog");
                    }}
                    className="text-primary-500 hover:underline"
                  >
                    log in
                  </button>
                </span>
              ) : null}
              <Tooltip
                content={"synced to natto's server\nencrypted at rest"}
                delayDuration={100}
              >
                <FeatherIcon
                  icon="help-circle"
                  size="sm"
                  className="text-neutral-400 hover:text-neutral-500 transition"
                />
              </Tooltip>
            </label>
            <label className="flex items-center gap-1">
              <input
                type="radio"
                name="variable_type"
                value="browser"
                checked={variableType === "browser"}
                onChange={(e) => {
                  if (e.target.checked) {
                    setVariableType("browser");
                  }
                }}
              />
              <span className="line-through">browser variable</span>
              <Tooltip
                content={
                  "stored in browser\nnot tied to user\nmay be deprecated"
                }
                delayDuration={100}
              >
                <FeatherIcon
                  icon="help-circle"
                  size="sm"
                  className="text-neutral-400 hover:text-neutral-500 transition"
                />
              </Tooltip>
            </label>
          </div>
        </div>
      </form>
    );
  }
);

function VariablePopoutButton({
  onEdit,
  onDelete,
}: {
  onEdit: () => void;
  onDelete: () => void;
}) {
  return (
    <DropdownMenuRoot>
      <DropdownMenuTrigger asChild={true}>
        <button className="flex p-1">
          <FeatherIcon icon="more-horizontal" size="md" />
        </button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuItem onClick={() => onEdit()}>edit</DropdownMenuItem>
        <DropdownMenuItem onClick={() => onDelete()} color="red">
          delete
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenuRoot>
  );
}

function UserVariableRow({
  variableKey,
  value,
}: {
  variableKey: string;
  value: string;
}) {
  const [isEditing, setIsEditing] = useState(false);

  return (
    <div
      className={classNames(
        "flex items-center border-b border-l border-r border-neutral-200 dark:border-neutral-300 px-4 min-h-[3rem] first:rounded-tl first:rounded-tr last:rounded-bl last:rounded-br",
        {
          "bg-neutral-50": isEditing,
        }
      )}
    >
      <div className="w-1/3 shrink-0 font-medium">{variableKey}</div>
      {isEditing ? (
        <VariableEditForm
          variableKey={variableKey}
          initialValue={value}
          isUser={true}
          onClose={() => {
            setIsEditing(false);
          }}
        />
      ) : (
        <>
          <div className="grow overflow-hidden">
            <SecretValue value={value} />
          </div>
          <VariablePopoutButton
            onEdit={() => {
              setIsEditing(true);
            }}
            onDelete={async () => {
              await deleteUserEnvironmentVariable(variableKey);
              EnvironmentVariablesChangeEmitter.emit("change", variableKey);
            }}
          />
        </>
      )}
    </div>
  );
}

function VariableEditForm({
  variableKey,
  initialValue,
  isUser,
  refreshVariables,
  onClose,
}: {
  variableKey: string;
  initialValue: string;
  isUser: boolean;
  refreshVariables?: () => void;
  onClose: () => void;
}) {
  const [editingValue, setEditingValue] = useState(initialValue);
  return (
    <form
      onSubmit={async (e) => {
        e.preventDefault();
        if (editingValue !== initialValue) {
          if (isUser) {
            await setUserEnvironmentVariable(variableKey, editingValue, true);
          } else {
            await EnvironmentVariableDB.putVariable(variableKey, editingValue);
          }
          EnvironmentVariablesChangeEmitter.emit("change", variableKey);
          refreshVariables?.();
        }
        onClose();
      }}
      className="flex grow"
    >
      <input
        type="text"
        value={editingValue}
        onChange={(e) => {
          setEditingValue(e.target.value);
        }}
        className="grow px-1 py-1 border border-neutral-300 bg-neutral-0 rounded-sm mr-4"
        autoFocus={true}
      />
      <button disabled={editingValue === ""} className="button-dark mr-1">
        update
      </button>
      <button
        type="button"
        onClick={() => {
          onClose();
        }}
        className="text-neutral-400 hover:text-neutral-600 transition-colors px-1"
      >
        cancel
      </button>
    </form>
  );
}

function BrowserVariableRow({
  variableKey,
  value,
  refreshVariables,
}: {
  variableKey: string;
  value: string;
  refreshVariables: () => void;
}) {
  const [isEditing, setIsEditing] = useState(false);

  return (
    <div
      className={classNames(
        "flex items-center border-b border-l border-r border-neutral-200 px-4 min-h-[3rem] first:rounded-tl first:rounded-tr last:rounded-bl last:rounded-br",
        {
          "bg-neutral-50": isEditing,
        }
      )}
    >
      <div className="w-1/3 shrink-0 font-medium">{variableKey}</div>
      {isEditing ? (
        <VariableEditForm
          variableKey={variableKey}
          initialValue={value}
          isUser={false}
          refreshVariables={refreshVariables}
          onClose={() => {
            setIsEditing(false);
          }}
        />
      ) : (
        <>
          <div className="grow overflow-hidden">
            <SecretValue value={value} />
          </div>
          <VariablePopoutButton
            onEdit={() => {
              setIsEditing(true);
            }}
            onDelete={async () => {
              await EnvironmentVariableDB.deleteVariable(variableKey);
              EnvironmentVariablesChangeEmitter.emit("change", variableKey);
              refreshVariables();
            }}
          />
        </>
      )}
    </div>
  );
}

const EnvironmentVariablesDialogContent = observer(
  ({ initialKey }: { initialKey: string | undefined }) => {
    const [browserVariables, setBrowserVariables] = useState<
      [key: string, value: string][] | undefined
    >(undefined);

    const refreshBrowserVariables = useCallback(async () => {
      const browserVariables = await EnvironmentVariableDB.getAllVariables();
      setBrowserVariables(browserVariables);
    }, []);
    useEffect(() => {
      refreshBrowserVariables();
    }, []);

    useEffect(() => {
      fetchUserEnvironmentVariables();
    }, []);
    const userVariables = userEnvironmentVariablesMobx.value;
    const userUsername = computed(() => userAtom.value?.username).get();

    return (
      <div className="h-full max-h-[95vh] overflow-auto pb-8">
        <DialogHeader>environment variables</DialogHeader>
        <div className="px-6">
          <div className="mt-4 mb-4 bg-primary-50 p-3 rounded">
            Reference variables from <strong>environment panes</strong>. Your
            own user canvases have access. Other canvases need to be granted
            permission for each session. Be careful granting access! Untrusted
            canvases could broadcast your secrets.
          </div>
          <div className="mb-8">
            <NewVariableForm
              initialKey={initialKey}
              refreshBrowserVariables={refreshBrowserVariables}
            />
          </div>
          <div className="mb-8">
            <div className="text-sm font-medium mb-2">
              {userUsername !== undefined ? `@${userUsername}'s` : "user"}{" "}
              variables
            </div>
            {userVariables !== undefined ? (
              userVariables.length > 0 ? (
                <div className="border-t border-neutral-200 dark:border-neutral-300 rounded-tl rounded-tr">
                  {userVariables.map(({ key: variableKey, value }) => (
                    <UserVariableRow
                      variableKey={variableKey}
                      value={value}
                      key={variableKey}
                    />
                  ))}
                </div>
              ) : (
                <div>No user variables stored on the server</div>
              )
            ) : (
              <button
                onClick={() => {
                  showLoginDialog("environment variables dialog");
                }}
                className="button"
              >
                log in for user variables
              </button>
            )}
          </div>
          <div>
            <div className="text-sm font-medium mb-2">browser variables</div>
            {browserVariables !== undefined && browserVariables.length > 0 ? (
              <div className="border-t border-neutral-200 rounded-tl rounded-tr">
                {browserVariables.map(([variableKey, value]) => (
                  <BrowserVariableRow
                    variableKey={variableKey}
                    value={value}
                    refreshVariables={refreshBrowserVariables}
                    key={variableKey}
                  />
                ))}
              </div>
            ) : (
              <div>No variables stored in this browser</div>
            )}
          </div>
        </div>
      </div>
    );
  }
);

const showEnvironmentVariablesDialogAtom = atom<
  false | [initialKey: string | undefined]
>(false);
export function showEnvironmentVariablesDialog(
  initialKey?: string | undefined
) {
  runInAction(() => {
    showEnvironmentVariablesDialogAtom.value = [initialKey];
  });
}

export const EnvironmentVariablesDialogController = observer(() => {
  return (
    <DialogRoot
      open={showEnvironmentVariablesDialogAtom.value !== false}
      onOpenChange={action((open) => {
        if (!open) {
          showEnvironmentVariablesDialogAtom.value = false;
        }
      })}
    >
      <DialogPortal>
        <DialogOverlay />
        <DialogContent className="w-[90vw] max-w-lg">
          <EnvironmentVariablesDialogContent
            initialKey={
              showEnvironmentVariablesDialogAtom.value !== false
                ? showEnvironmentVariablesDialogAtom.value[0]
                : undefined
            }
          />
        </DialogContent>
      </DialogPortal>
    </DialogRoot>
  );
});
