import { DBSchema, IDBPDatabase, openDB } from "idb";
import { CURRENT_VERSION } from "../Constants";
import { Canvas, Pane, PaneInput, PaneLayoutLegacy, PaneType } from "../Types";
import { generatePaneId, generatePaneInputId } from "../Utils";
import { addUuidDashes } from "./ParentUtils";

interface CanvasDBSchema extends DBSchema {
  canvases: {
    value: {
      id: string;
      name: string;
      panes: any[];
      layouts?: any[];
      createdTime: number;
      version?: number;
    };
    key: string;
    indexes: {
      createdTime: "createdTime";
    };
  };
}

let canvasDBPromise: Promise<IDBPDatabase<CanvasDBSchema>> | undefined;
export function getCanvasDB() {
  if (canvasDBPromise === undefined) {
    canvasDBPromise = openDB("canvas", 1, {
      upgrade(db, oldVersion, newVersion, transaction) {
        const canvasStore = db.createObjectStore("canvases", {
          keyPath: "id",
        });
        canvasStore.createIndex("createdTime", "createdTime", {
          unique: false,
        });
      },
    });
  }
  return canvasDBPromise;
}

function processPanesForLegacy(panes: any[], version: number | undefined) {
  if (version === CURRENT_VERSION) {
    return panes;
  }
  const shouldRemapPaneIds = panes.some(
    (p) => typeof p.id === "number" || p.id.includes("-") || p.id.includes("_")
  );
  const remapPaneIds = shouldRemapPaneIds
    ? new Map(panes.map((p) => [p.id, generatePaneId()]))
    : undefined;
  let hasChange = shouldRemapPaneIds;
  const rv: Pane[] = panes.map((pane) => {
    let p = pane;
    if (remapPaneIds !== undefined) {
      p = {
        ...p,
        id: remapPaneIds.get(p.id)!,
      };
    }
    if (p.inputIds !== undefined) {
      hasChange = true;
      const updatedPane = {
        ...p,
        inputs: p.inputIds.map(
          (inputId: [number, number] | number | undefined) => {
            return {
              id: generatePaneInputId(),
              name: undefined,
              source:
                inputId === undefined
                  ? undefined
                  : typeof inputId === "number"
                  ? [inputId, 0]
                  : inputId,
            };
          }
        ),
      };
      delete updatedPane.inputIds;
      p = updatedPane;
    }
    if (p.type === PaneType.DomElement) {
      hasChange = true;
      p = {
        ...p,
        type: PaneType.Evaluate,
        inputs: [],
        autorun: true,
        expression: `document.createElement('div')`,
        editorHeight: undefined,
        renderOutput: ["dom"],
      };
    }
    if (p.type === PaneType.OutputRenderer && p.rendererId === "table") {
      hasChange = true;
      p = {
        ...p,
        type: PaneType.Evaluate,
        inputs: p.inputs,
        autorun: true,
        expression: "value",
        editorHeight: 0,
        renderOutput: ["table"],
      };
    }
    if (version === undefined || version < 20210623) {
      if (
        p.type === PaneType.Evaluate &&
        /\bvalue\b/.test(p.expression) &&
        p.inputs[0] !== undefined &&
        p.inputs[0].name === undefined &&
        p.inputs.every((input: PaneInput) => input.name !== "value")
      ) {
        hasChange = true;
        p = {
          ...p,
          inputs: [{ ...p.inputs[0], name: "value" }, ...p.inputs.slice(1)],
        };
      }
    }
    if (
      p.inputs !== undefined &&
      p.inputs.some((paneInput: any) => paneInput.id === undefined)
    ) {
      hasChange = true;
      p = {
        ...p,
        inputs: p.inputs.map((paneInput: any) => ({
          ...paneInput,
          id: generatePaneInputId(),
        })),
      };
    }
    if (
      p.inputs !== undefined &&
      p.inputs.some((paneInput: any) => paneInput.paneId !== undefined)
    ) {
      hasChange = true;
      p = {
        ...p,
        inputs: p.inputs.map((paneInput: any) => {
          const rv = { ...paneInput, source: paneInput.paneId };
          delete rv.paneId;
          return rv;
        }),
      };
    }
    if (
      remapPaneIds !== undefined &&
      p.inputs !== undefined &&
      p.inputs.some((paneInput: any) => paneInput.source !== undefined)
    ) {
      p = {
        ...p,
        inputs: p.inputs.map((paneInput: any) => {
          if (paneInput.source !== undefined) {
            return {
              ...paneInput,
              source: [
                remapPaneIds.get(paneInput.source[0])!,
                paneInput.source[1],
              ],
            };
          }
          return paneInput;
        }),
      };
    }
    return p;
  });
  return hasChange ? rv : panes;
}

export function processLayoutsForLegacy(
  layouts: any[],
  version: number | undefined
) {
  if (version === CURRENT_VERSION) {
    return layouts;
  }
  let hasChange = false;
  const rv: PaneLayoutLegacy[] = layouts
    .map((layout) => {
      if ("paneMetadata" in layout) {
        hasChange = true;
        const paneEntries = Array.from(
          (layout.paneMetadata as Map<any, any>).entries()
        );
        // not worth the work to remap pane ids
        if (paneEntries.some(([paneId]) => typeof paneId === "number")) {
          return undefined;
        }
        return {
          id: layout.id,
          name: layout.name,
          panes: Object.fromEntries(paneEntries),
        };
      }
      return layout;
    })
    .filter(Boolean);
  return hasChange ? rv : layouts;
}

export const CanvasDB = {
  getAllCanvasIdsAndNames: async (): Promise<[id: string, name: string][]> => {
    const db = await getCanvasDB();
    const canvasesRaw = await db!.getAllFromIndex("canvases", "createdTime");
    const canvases = canvasesRaw.reverse();
    if (canvases[0] !== undefined && canvases[0].id.length === 32) {
      await Promise.all(
        canvases.map((canvas) =>
          db!.put("canvases", {
            ...canvas,
            id: addUuidDashes(canvas.id),
          })
        )
      );
      await Promise.all(
        canvases.map((canvas) => CanvasDB.deleteCanvas(canvas.id))
      );
      return CanvasDB.getAllCanvasIdsAndNames();
    }
    return canvases.map(({ id, name }) => [id, name]);
  },
  getCanvas: async (canvasId: string): Promise<Canvas | undefined> => {
    const db = await getCanvasDB();
    const row = await db!.get("canvases", canvasId);
    if (row === undefined) {
      return undefined;
    }
    try {
      let panes = row.panes;
      let layouts = row.layouts ?? [];
      if (row.version !== CURRENT_VERSION) {
        panes = processPanesForLegacy(panes, row.version);
        layouts = processLayoutsForLegacy(layouts, row.version);
        await db!.put("canvases", {
          ...row,
          panes,
          layouts,
          version: CURRENT_VERSION,
        });
      }
      return {
        id: row.id,
        name: row.name,
        panes,
        layouts,
        createdTime: row.createdTime,
      };
    } catch {
      return undefined;
    }
  },
  putCanvas: async (canvas: Canvas): Promise<void> => {
    const db = await getCanvasDB();
    const value: any = {
      id: canvas.id,
      name: canvas.name,
      panes: canvas.panes,
      layouts: canvas.layouts,
      createdTime: canvas.createdTime,
      version: CURRENT_VERSION,
    };
    await db!.put("canvases", value);
  },
  deleteCanvas: async (canvasId: string): Promise<void> => {
    const db = await getCanvasDB();
    await db!.delete("canvases", canvasId);
  },
};

export async function exportAllCanvases() {
  const db = await getCanvasDB();
  const canvases = await db!.getAllFromIndex("canvases", "createdTime");
  // TODO: process for legacy
  return canvases;
}

export async function importCanvas(canvas: Canvas, version: number) {
  // TODO: do more validation, upgrade version
  const db = await getCanvasDB();
  return db!.add("canvases", {
    id: canvas.id,
    name: canvas.name,
    panes: canvas.panes,
    layouts: canvas.layouts,
    createdTime: canvas.createdTime,
    version,
  });
}

interface SettingsDBSchema extends DBSchema {
  settings: {
    value: {
      key: string;
      value: any;
    };
    key: string;
  };
}

let settingsDBPromise: Promise<IDBPDatabase<SettingsDBSchema>> | undefined;
export function getSettingsDB() {
  if (settingsDBPromise === undefined) {
    settingsDBPromise = openDB("settings", 1, {
      upgrade(db, oldVersion, newVersion, transaction) {
        const settingsStore = db.createObjectStore("settings", {
          keyPath: "key",
        });
      },
    });
  }
  return settingsDBPromise;
}

export const SettingsDB = {
  getSetting: async (key: string): Promise<any | undefined> => {
    const db = await getSettingsDB();
    const row = await db!.get("settings", key);
    return row?.value;
  },
  putSetting: async (key: string, value: any): Promise<void> => {
    const db = await getSettingsDB();
    await db!.put("settings", { key, value });
  },
};
