import { action, runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import React from "react";
import { logEvent } from "../Amplitude";
import { atom } from "../atom";
import {
  DialogCloseButton,
  DialogContent,
  DialogOverlay,
  DialogPortal,
  DialogRoot,
} from "../ui/Dialog";
import { signIn } from "./API";

function LoginDialogContent() {
  return (
    <div className="px-6 py-6 max-w-[18rem]">
      <div className="text-lg font-medium mb-4 text-center">log in</div>
      <div className="natto-icon mx-auto mb-4" />
      <div className="pt-2 mb-4 text-center">
        Create user canvases that you can share and access from any browser!
      </div>
      <button
        onClick={() => {
          logEvent("click google login button");
          signIn();
        }}
        className="flex items-center justify-center w-full font-medium py-2 rounded bg-primary-50 text-primary-500 hover:bg-primary-500 hover:text-neutral-0 transition-colors"
      >
        log in with Google
      </button>
    </div>
  );
}

const showLoginDialogAtom = atom(false);
export function showLoginDialog(source: string) {
  runInAction(() => {
    showLoginDialogAtom.value = true;
    logEvent("view login modal", { source });
  });
}

export const LoginDialogController = observer(() => {
  return (
    <DialogRoot
      open={showLoginDialogAtom.value}
      onOpenChange={action((open) => {
        showLoginDialogAtom.value = open;
      })}
    >
      <DialogPortal>
        <DialogOverlay />
        <DialogContent className="w-[90vw] max-w-[18rem]">
          <LoginDialogContent />
          <DialogCloseButton className="absolute top-2 right-2" />
        </DialogContent>
      </DialogPortal>
    </DialogRoot>
  );
});
