import { fileOpen, fileSave, FileSystemHandle } from "browser-fs-access";
import classNames from "classnames";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { logEvent } from "../Amplitude";
import {
  CURRENT_VERSION,
  FILE_EXTENSION,
  NEW_LOCAL_QUERY_PARAM,
} from "../Constants";
import { canvasSelectionAtom, userAtom } from "../SharedIframeState";
import { CanvasSelection } from "../Types";
import { FeatherIcon } from "../ui/FeatherIcon";
import { Tooltip } from "../ui/Tooltip";
import { removeUuidDashes } from "../Utils";
import { publishCanvas, signOut } from "./API";
import {
  addMyPublishedCanvasIdAndName,
  myPublishedCanvasIdsAndNamesAtom,
} from "./AppState";
import { migrateFileCanvasForLegacy } from "./CanvasMigration";
import { getCanvasFileName } from "./FrameParentAPI";
import { showGalleryDialog } from "./GalleryDialog";
import { CanvasDB } from "./IndexedDB";
import {
  addRecentFile,
  getRecentFiles,
  removeRecentFile,
} from "./LocalSettings";
import { showLoginDialog } from "./LoginDialog";
import { recentFilesAtom } from "./ParentAtoms";
import { getNewCanvasName, getPublishedCanvasPath } from "./ParentUtils";
import { setNextFileCanvas, setNextFileCanvasNew } from "./PathHandler";
import { showSettingsDialog } from "./SettingsDialog";

const CanvasSelectorHeaderClassName =
  "sticky z-1 h-10 flex items-center top-0 mb-1 bg-neutral-0 dark:bg-neutral-100 text-2xs text-neutral-400 font-mono-natto uppercase border-b border-neutral-150";
const CanvasSelectorItemClassName =
  "flex items-center w-full h-8 pl-1.5 rounded-sm text-left whitespace-nowrap overflow-hidden text-ellipsis";
const CanvasSelectorActionItemClassName =
  "flex items-center gap-2 text-primary-500 hover:bg-primary-50 dark:hover:bg-primary-200 hover:text-primary-600 transition";
const CanvasSelectorSelectedItemClassName =
  "bg-primary-500 text-neutral-0 dark:bg-accent-200 dark:text-primary-600";
const CanvasSelectorNotSelectedItemClassName =
  "text-neutral-600 hover:bg-primary-50 dark:hover:bg-primary-200";

const EXAMPLE_CANVASES = [
  ["0a7aeb80-5e76-4129-8994-6c5f270f84e5", "welcome!"],
  ["5127e6ca-c7b8-4add-b421-4b653df7fef8", "1. tip calculator"],
  ["71f60fdf-7919-412f-9fb1-0b78ee6da774", "2. http request"],
  ["2444ffca-064a-4890-a7f0-5ca1de386c5a", "3. uuid generator"],
  ["f33b7f59-2463-4fb6-87a9-0d85ed03f289", "4. art browser"],
  ["1dcbeb75-8362-4bf1-9d69-b9942271a703", "5. react playground"],
];

function IndexedDBCanvasDownloader() {
  const [hasIndexedDBCanvases, setHasIndexedDBCanvases] = useState(false);
  useEffect(() => {
    async function go() {
      const canvasIdsAndNames = await CanvasDB.getAllCanvasIdsAndNames();
      if (canvasIdsAndNames.length > 0) {
        setHasIndexedDBCanvases(true);
      }
    }
    go();
  }, []);
  if (!hasIndexedDBCanvases) {
    return null;
  }
  return (
    <button
      onClick={async () => {
        const canvasIdsAndNames = await CanvasDB.getAllCanvasIdsAndNames();
        const canvases = await Promise.all(
          canvasIdsAndNames.map(([id, name]) => CanvasDB.getCanvas(id))
        );
        const JSZip = (await import("jszip")).default;
        const zip = new JSZip();
        const folder = zip.folder("natto")!;
        for (const canvas of canvases) {
          if (canvas === undefined) {
            continue;
          }
          const canvasWithoutName: any = { ...canvas };
          delete canvasWithoutName.name;
          folder.file(
            getCanvasFileName(canvas.name),
            JSON.stringify({
              canvas: canvasWithoutName,
              version: CURRENT_VERSION,
            })
          );
        }
        const zipBlob = await zip.generateAsync({ type: "blob" });
        const date = new Date();
        fileSave(zipBlob, {
          fileName: `natto_${date.getFullYear()}${(date.getMonth() + 1)
            .toString()
            .padStart(2, "0")}${date
            .getDate()
            .toString()
            .padStart(2, "0")}.zip`,
          description: "save canvases",
          extensions: [".zip"],
        });
        logEvent("download legacy canvases");
      }}
      className="bg-neutral-100 hover:bg-primary-50 flex gap-2 items-center p-2 rounded-sm mt-1 text-2xs group transition"
    >
      <FeatherIcon
        icon="download-cloud"
        size="sm"
        className="text-primary-300 group-hover:text-primary-400 transition shrink-0"
      />
      <div className="text-primary-400 group-hover:text-primary-500 transition text-left">
        download your{" "}
        <span className="whitespace-nowrap">existing canvases</span>
      </div>
    </button>
  );
}

function RecentFilesSection({
  recentFiles,
  openCanvasFileBlob,
}: {
  recentFiles: FileSystemHandle[];
  openCanvasFileBlob: (
    blob: Blob,
    fileHandle: FileSystemHandle | undefined
  ) => Promise<void>;
}) {
  return (
    <div className="mt-4">
      <div className={CanvasSelectorHeaderClassName}>recent files</div>
      <div className="mb-2">
        {recentFiles.map((recentFile, i) => (
          <div className="relative" key={i}>
            <button
              onClick={async () => {
                if (
                  (await recentFile.queryPermission({
                    mode: "readwrite",
                  })) !== "granted"
                ) {
                  if (
                    (await recentFile.requestPermission({
                      mode: "readwrite",
                    })) !== "granted"
                  ) {
                    return;
                  }
                }
                try {
                  // @ts-ignore
                  const blob = await recentFile.getFile();
                  openCanvasFileBlob(blob, recentFile);
                  logEvent("open canvas file", {
                    "is recent": true,
                    "has handle": true,
                  });
                } catch (e) {
                  await removeRecentFile(recentFile);
                  const recentFiles = await getRecentFiles();
                  runInAction(() => {
                    recentFilesAtom.value = recentFiles;
                  });
                }
              }}
              title={recentFile.name}
              className={classNames(
                CanvasSelectorItemClassName,
                CanvasSelectorNotSelectedItemClassName,
                "pr-6"
              )}
            >
              {recentFile.name}
            </button>
            <button
              onClick={async () => {
                await removeRecentFile(recentFile);
                const recentFiles = await getRecentFiles();
                runInAction(() => {
                  recentFilesAtom.value = recentFiles;
                });
              }}
              className="flex p-1 absolute right-1 top-1.5 text-neutral-300 hover:text-neutral-500 transition"
            >
              <FeatherIcon icon="x" size="sm" />
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}

const UserCanvasSection = observer(
  ({
    canvasSelection,
    showHeader,
    onClose,
  }: {
    canvasSelection: CanvasSelection | undefined;
    showHeader: boolean;
    onClose: () => void;
  }) => {
    const user = userAtom.value;
    const publishedCanvasIdsAndNames = myPublishedCanvasIdsAndNamesAtom.value;
    const history = useHistory();
    return (
      <div className="mb-2">
        <div className={CanvasSelectorHeaderClassName}>
          <div className="grow mr-2">
            {user?.username !== undefined
              ? `@${user?.username}`
              : "user canvases"}
          </div>
          <Tooltip
            content={
              <div className="max-w-[16rem] text-left py-1 whitespace-normal">
                User canvases are stored on natto's server and are accessible to
                anyone with the link. You can view and edit them from any
                browser.
              </div>
            }
            side="right"
            sideOffset={8}
          >
            <FeatherIcon
              icon="help-circle"
              size="md"
              className="text-neutral-300 hover:text-neutral-400 transition-colors relative top-px"
            />
          </Tooltip>
        </div>
        {user !== undefined ? (
          <div>
            <button
              onClick={async () => {
                const canvasName = getNewCanvasName();
                const canvasId = await publishCanvas({
                  id: "ignored",
                  name: canvasName,
                  panes: [],
                  layouts: [],
                  createdTime: Date.now(),
                });
                addMyPublishedCanvasIdAndName(canvasId, canvasName);
                history.push(getPublishedCanvasPath(user.username!, canvasId));
                onClose();
              }}
              className={classNames(
                CanvasSelectorItemClassName,
                CanvasSelectorActionItemClassName
              )}
            >
              <FeatherIcon icon="plus" size="sm" />
              <span>new user canvas</span>
            </button>
            {publishedCanvasIdsAndNames !== undefined
              ? publishedCanvasIdsAndNames.map(([canvasId, canvasName]) => {
                  const isSelected =
                    canvasSelection !== undefined &&
                    canvasSelection[0] === "user" &&
                    canvasSelection[2] === canvasId;
                  const path = getPublishedCanvasPath(user.username!, canvasId);
                  return (
                    <a
                      href={path}
                      onClick={(e) => {
                        e.preventDefault();
                        if (!isSelected) {
                          history.push(
                            getPublishedCanvasPath(user.username!, canvasId)
                          );
                        }
                        onClose();
                      }}
                      className={classNames(
                        CanvasSelectorItemClassName,
                        isSelected
                          ? CanvasSelectorSelectedItemClassName
                          : CanvasSelectorNotSelectedItemClassName
                      )}
                      key={canvasId}
                    >
                      {canvasName}
                    </a>
                  );
                })
              : null}
            {user !== undefined ? (
              <button
                onClick={() => {
                  signOut();
                }}
                className={classNames(
                  CanvasSelectorItemClassName,
                  "text-neutral-400 hover:bg-red-500 hover:text-neutral-0 transition"
                )}
              >
                log out
              </button>
            ) : null}
          </div>
        ) : (
          <div>
            <button
              onClick={() => {
                showLoginDialog("navigation dialog");
              }}
              className={classNames(
                CanvasSelectorItemClassName,
                CanvasSelectorActionItemClassName
              )}
            >
              <FeatherIcon icon="user" size="sm" />
              log in
            </button>
          </div>
        )}
      </div>
    );
  }
);

export const NavigationDialog = observer(
  ({
    navigateToFileCanvasId,
    showHeader,
    onClose,
  }: {
    navigateToFileCanvasId: (
      canvasId: string,
      fileHandle?: FileSystemHandle | undefined
    ) => void;
    showHeader: boolean;
    onClose: () => void;
  }) => {
    const canvasSelection = canvasSelectionAtom.value;
    const history = useHistory();

    useEffect(() => {
      async function go() {
        const recentFiles = await getRecentFiles();
        runInAction(() => {
          recentFilesAtom.value = recentFiles;
        });
      }
      go();
    }, []);

    async function openCanvasFileBlob(
      blob: Blob,
      fileHandle: FileSystemHandle | undefined
    ) {
      const text = await blob.text();
      const { canvas, version } = JSON.parse(text);
      const migratedCanvas = migrateFileCanvasForLegacy(canvas, version);
      // TODO
      // if (migratedCanvas !== canvas) {
      //   // save migrated version
      // }
      setNextFileCanvas({
        ...migratedCanvas,
        name: fileHandle?.name ?? (blob as File).name ?? "",
      });
      navigateToFileCanvasId(canvas.id, fileHandle);
      if (fileHandle !== undefined) {
        await addRecentFile(fileHandle);
        const recentFiles = await getRecentFiles();
        runInAction(() => {
          recentFilesAtom.value = recentFiles;
        });
      }
    }

    return (
      <div className="bg-neutral-0 dark:bg-neutral-100 w-[33rem] max-w-full h-96 overflow-auto sm:rounded-md shadow-xl relative dark:border dark:border-neutral-150">
        {showHeader ? (
          <div className="bg-secondary-50 dark:bg-transparent dark:border-b dark:border-neutral-100 px-4 py-3">
            <span className="text-secondary-800 font-mono-natto text-lg mr-4">
              natto.dev
            </span>
            <span className="text-neutral-400">
              write JavaScript on a 2D canvas
            </span>
          </div>
        ) : null}
        <div className="flex gap-4 mx-4">
          <div className="w-1/3 max-w-[9rem]">
            <div className={CanvasSelectorHeaderClassName}>local</div>
            <div>
              <a
                href={`/?${NEW_LOCAL_QUERY_PARAM}`}
                onClick={(e) => {
                  e.preventDefault();
                  const canvasId = setNextFileCanvasNew();
                  navigateToFileCanvasId(canvasId);
                  logEvent("create local canvas");
                }}
                className={classNames(
                  CanvasSelectorItemClassName,
                  CanvasSelectorActionItemClassName
                )}
              >
                <FeatherIcon icon="plus" size="sm" />
                new canvas
              </a>
            </div>
            <div>
              <button
                onClick={async () => {
                  const blob = await fileOpen({ extensions: [FILE_EXTENSION] });
                  openCanvasFileBlob(blob, blob.handle);
                  logEvent("open canvas file", {
                    "has handle": blob.handle !== undefined,
                  });
                }}
                className={classNames(
                  CanvasSelectorItemClassName,
                  CanvasSelectorActionItemClassName
                )}
              >
                <FeatherIcon icon="file" size="sm" />
                open file
              </button>
            </div>
            <div>
              <button
                onClick={() => {
                  showSettingsDialog();
                }}
                className={classNames(
                  CanvasSelectorItemClassName,
                  CanvasSelectorActionItemClassName
                )}
              >
                <FeatherIcon icon="settings" size="sm" />
                settings
              </button>
            </div>
            {/* <IndexedDBCanvasDownloader /> */}
            {recentFilesAtom.value !== undefined &&
            recentFilesAtom.value.length > 0 ? (
              <RecentFilesSection
                recentFiles={recentFilesAtom.value}
                openCanvasFileBlob={openCanvasFileBlob}
              />
            ) : null}
          </div>
          <div className="grow">
            <UserCanvasSection
              canvasSelection={canvasSelection}
              showHeader={showHeader}
              onClose={onClose}
            />
          </div>
          <div className="w-1/3 max-w-[9rem]">
            <div className={CanvasSelectorHeaderClassName}>examples</div>
            <div className="mb-2">
              <a
                href="/tutorial/tip-calculator"
                onClick={(e) => {
                  e.preventDefault();
                  history.push("/tutorial/tip-calculator");
                  onClose();
                }}
                className={classNames(
                  CanvasSelectorItemClassName,
                  CanvasSelectorActionItemClassName
                )}
              >
                <FeatherIcon icon="book-open" size="sm" />
                tutorial
              </a>
              <button
                onClick={() => {
                  showGalleryDialog();
                  onClose();
                }}
                className={classNames(
                  CanvasSelectorItemClassName,
                  CanvasSelectorActionItemClassName
                )}
              >
                <FeatherIcon icon="grid" size="sm" />
                gallery
              </button>
              {EXAMPLE_CANVASES.map(([canvasId, canvasName]) => {
                const isSelected =
                  canvasSelection !== undefined &&
                  canvasSelection[0] === "example" &&
                  canvasSelection[1] === canvasId;
                const path = `/example/${removeUuidDashes(canvasId)}`;
                return (
                  <a
                    href={path}
                    onClick={(e) => {
                      e.preventDefault();
                      if (!isSelected) {
                        history.push(path);
                        onClose();
                      }
                    }}
                    className={classNames(
                      CanvasSelectorItemClassName,
                      isSelected
                        ? CanvasSelectorSelectedItemClassName
                        : CanvasSelectorNotSelectedItemClassName
                    )}
                    key={canvasId}
                  >
                    {canvasName}
                  </a>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }
);
