import { Position } from "./Types";

export * from "../../shared/Constants";

export const IN_DEV = import.meta.env.DEV;

const urlSearchParams = new URLSearchParams(location.search);
export const SANDBOX_ORIGIN =
  urlSearchParams.get("SANDBOX_ORIGIN") ?? VITE_APP_SANDBOX_ORIGIN;
export const PARENT_ORIGIN = IN_DEV ? "http://localhost:5173" : "*";
export const SERVER_HOST = VITE_APP_SERVER_HOST;
export const SERVER_SOCKET_HOST = VITE_APP_SERVER_SOCKET_HOST;

export const GRID_UNIT = 16;
export const HEADER_BAR_HEIGHT_PX = 48;
export const SMALL_WIDTH_BREAKPOINT = 640;
export const MEDIUM_WIDTH_BREAKPOINT = 768;
export const DEFAULT_CANVAS_OFFSET: Position = [-8, -8];

export const CODE_ANALYSIS_VALUE_PREFIX = "__VALUE_";
export const TUTORIAL_CANVAS_ID = "11111111-1111-1111-1111-111111111111";
export const WELCOME_CANVAS_ID = "11111111-1111-1111-1111-111111111111";
export const CUSTOM_RENDER_INPUT_LABEL = "render";
export const REACT_DOM_RENDER_INPUT_LABEL = "ReactDOM";

export const CURRENT_VERSION = 20220111;

export const FILE_EXTENSION = ".natto";
export const UNSAVED_CANVAS_NAME = "unsaved canvas";
export const GUEST_DEFAULT_NAME = "guest";
export const NEW_LOCAL_QUERY_PARAM = "newlocal";
