import React, { useState } from "react";
import { FeatherIcon } from "./FeatherIcon";

export function SecretValue({ value }: { value: string }) {
  const [isRevealed, setIsRevealed] = useState(false);
  return (
    <div className="flex items-center">
      <button
        onClick={() => {
          setIsRevealed((v) => !v);
        }}
        className="text-neutral-400 hover:text-neutral-600 flex p-1 -ml-1 mr-1 transition-colors"
      >
        <FeatherIcon icon={isRevealed ? "eye-off" : "eye"} size="sm" />
      </button>
      <div className="text-neutral-600 font-mono-natto overflow-hidden text-ellipsis select-text">
        {isRevealed ? value : "••••••••••••"}
      </div>
    </div>
  );
}
