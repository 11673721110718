import { fileSave, FileSystemHandle } from "browser-fs-access";
import classNames from "classnames";
import { action, computed, runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { LayerContainer } from "react-atmosphere";
import {
  BrowserRouter,
  Prompt,
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import { logEvent } from "../Amplitude";
import { atom } from "../atom";
import {
  CURRENT_VERSION,
  TUTORIAL_CANVAS_ID,
  UNSAVED_CANVAS_NAME,
  WELCOME_CANVAS_ID,
} from "../Constants";
import {
  canvasSelectionAtom,
  syncCanvasIdAtom,
  uiStateMobx,
  userAtom,
} from "../SharedIframeState";
import { Canvas, CanvasSelection, SyncCanvasId, User } from "../Types";
import { FeatherIcon } from "../ui/FeatherIcon";
import { TooltipProvider } from "../ui/Tooltip";
import { removeUuidDashes, throttle, useSyncRef } from "../Utils";
import {
  fetchAndSetUser,
  fetchCanvas,
  getCachedUsername,
  getSocketToken,
  sessionAtom,
  setSession,
} from "./API";
import { setMyPublishedCanvasName } from "./AppState";
import { CanvasNameUnitParent } from "./CanvasNameUnitParent";
import { EnvironmentVariablesDialogController } from "./EnvironmentVariablesDialog";
import { resetEnvironmentVariablesSession } from "./EnvironmentVariablesSession";
import {
  reloadSandbox,
  SandboxFrameAPI,
  SandboxMousedownEmitter,
  setNavigate,
} from "./FrameParentAPI";
import {
  GalleryDialogController,
  showGalleryDialog,
  showGalleryDialogBox,
} from "./GalleryDialog";
import { InvitesDialogController } from "./InvitesDialog";
import { LoginDialogController } from "./LoginDialog";
import { NavigationDialog } from "./NavigationDialog";
import {
  addUuidDashes,
  getCanvasId,
  getFileCanvasPath,
  getInvitePath,
  getPublishedCanvasPath,
  updateDocumentTitle,
} from "./ParentUtils";
import { fileCanvasIdAtom, getNextFileCanvas } from "./PathHandler";
import { SettingsDialogController } from "./SettingsDialog";
import { UserUsernameDialogController } from "./UserUsernameDialog";

declare global {
  interface Window {
    __canvasRef: { current: Canvas | undefined } | undefined;
  }
}

function useCanvasUpdateListeners(
  setLayoutId: (layoutId: string | undefined) => void,
  onCanvasUpdate: (canvas: Canvas) => void
) {
  useEffect(() => {
    function getCurrentCanvasId() {
      const canvasSelection = canvasSelectionAtom.value;
      return canvasSelection !== undefined
        ? getCanvasId(canvasSelection)
        : undefined;
    }
    function onUpdateCanvas({ canvas }: { canvas: Canvas }) {
      if (getCurrentCanvasId() === canvas.id) {
        onCanvasUpdate(canvas);
      }
    }
    function onSwitchLayoutId({ layoutId }: { layoutId: string | undefined }) {
      setLayoutId(layoutId);
    }
    SandboxFrameAPI.addListener("UPDATE_CANVAS", onUpdateCanvas);
    SandboxFrameAPI.addListener("SWITCH_LAYOUT_ID", onSwitchLayoutId);
    return () => {
      SandboxFrameAPI.removeListener("UPDATE_CANVAS", onUpdateCanvas);
      SandboxFrameAPI.removeListener("SWITCH_LAYOUT_ID", onSwitchLayoutId);
    };
  }, []);
}

function getFileCanvas(canvasId: string) {
  const nextFileCanvas = getNextFileCanvas();
  if (nextFileCanvas?.id === canvasId) {
    return nextFileCanvas;
  }
  return {
    id: canvasId,
    name: UNSAVED_CANVAS_NAME,
    panes: [],
    layouts: [],
    createdTime: Date.now(),
  };
}

function FileCanvasApp({
  canvasId,
  layoutId: layoutIdProp,
  isSafeMode,
}: {
  canvasId: string;
  layoutId: string | undefined;
  isSafeMode: boolean;
}) {
  const [initialCanvas] = useState<Canvas>(() => getFileCanvas(canvasId));
  const canvasRef = useRef(initialCanvas);
  let layoutId = layoutIdProp;
  const layoutIdRef = useRef<string | undefined | false>(false);
  const history = useHistory();
  const bypassUnloadPromptRef = useRef(false);
  const setLayoutId = useCallback((layoutId: string | undefined) => {
    if (layoutIdRef.current === layoutId) {
      return;
    }
    bypassUnloadPromptRef.current = true;
    history.replace(getFileCanvasPath(layoutId));
  }, []);
  let initialRenderDefaultLayoutId: string | undefined;
  if (layoutIdRef.current === false) {
    if (
      layoutId === undefined &&
      initialCanvas.defaultLayoutId !== undefined &&
      initialCanvas.layouts.some(
        (layout) => layout.id === initialCanvas.defaultLayoutId
      )
    ) {
      layoutId = initialCanvas.defaultLayoutId;
      initialRenderDefaultLayoutId = layoutId;
    }
  }
  useLayoutEffect(() => {
    layoutIdRef.current = layoutId;
  });
  useEffect(() => {
    if (initialRenderDefaultLayoutId !== undefined) {
      setLayoutId(initialRenderDefaultLayoutId);
    }
  }, [initialRenderDefaultLayoutId]);
  useEffect(() => {
    SandboxFrameAPI.setCanvas(["not-sync", initialCanvas], layoutId, {
      isSafeMode,
      usedDefaultLayout: initialRenderDefaultLayoutId !== undefined,
    });
  }, []);

  const isInitialRenderRef = useRef(true);
  useEffect(() => {
    if (!isInitialRenderRef.current) {
      SandboxFrameAPI.setLayoutId(layoutId);
    }
    isInitialRenderRef.current = false;
  }, [layoutId]);

  useEffect(() => {
    function onSaveCanvasToFile({
      fileCanvasHandle,
    }: {
      fileCanvasHandle: FileSystemHandle;
    }) {
      runInAction(() => {
        fileCanvasHandleAtom.value = fileCanvasHandle;
      });
    }
    SandboxFrameAPI.addListener("SAVE_CANVAS_TO_FILE", onSaveCanvasToFile);
    return () => {
      SandboxFrameAPI.removeListener("SAVE_CANVAS_TO_FILE", onSaveCanvasToFile);
    };
  }, []);

  const fileCanvasHandle = fileCanvasHandleAtom.value;
  const fileCanvasHandleRef = useSyncRef(fileCanvasHandle);
  const [fileSaveStatus, setFileSaveStatus] = useState<
    "saving" | "saved" | ["error", string]
  >("saved");
  const fileSaveStatusRef = useSyncRef(fileSaveStatus);
  const [saveFile] = useState(() =>
    throttle(async (canvas: Canvas, fileCanvasHandle: FileSystemHandle) => {
      try {
        const canvasWithoutName: any = { ...canvas };
        delete canvasWithoutName.name;
        // TODO: check last file modified time
        await fileSave(
          new Blob(
            [
              JSON.stringify({
                canvas: canvasWithoutName,
                version: CURRENT_VERSION,
              }),
            ],
            { type: "application/json" }
          ),
          { description: "save canvas" },
          fileCanvasHandle,
          true
        );
        setFileSaveStatus("saved");
      } catch (e: any) {
        setFileSaveStatus(["error", e.message]);
        alert(e);
      }
    }, 100)
  );
  useCanvasUpdateListeners(setLayoutId, async (canvas) => {
    bypassUnloadPromptRef.current = false;
    canvasRef.current = canvas;
    setFileSaveStatus("saving");
    const fileCanvasHandle = fileCanvasHandleRef.current;
    if (fileCanvasHandle !== undefined) {
      saveFile(canvas, fileCanvasHandle);
    }
  });
  const canvasName = fileCanvasHandle?.name ?? initialCanvas.name;
  useEffect(() => {
    updateDocumentTitle(canvasName);
  }, [canvasName]);

  useEffect(() => {
    function onBeforeUnload(e: BeforeUnloadEvent) {
      if (import.meta.env.DEV) {
        return;
      }
      if (
        canvasId !== WELCOME_CANVAS_ID &&
        fileSaveStatusRef.current !== "saved"
      ) {
        e.preventDefault();
        return (e.returnValue =
          "You have unsaved changes. Are you sure you want to leave?");
      }
    }
    window.addEventListener("beforeunload", onBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", onBeforeUnload);
    };
  }, []);

  useEffect(() => {
    if (canvasId === WELCOME_CANVAS_ID) {
      logEvent("load welcome canvas");
    } else {
      logEvent("load file canvas");
    }
  }, [canvasId]);

  return (
    <>
      <Prompt
        when={canvasId !== WELCOME_CANVAS_ID && fileSaveStatus !== "saved"}
        message={() => {
          if (bypassUnloadPromptRef.current) {
            return true;
          }
          return "You have unsaved changes. Are you sure you want to leave?";
        }}
      />
      <CanvasNameUnitParent
        canvasId={canvasId}
        hasUnsavedChanges={
          fileCanvasHandle === undefined && fileSaveStatus !== "saved"
        }
        value={["file", canvasName, fileCanvasHandle !== undefined]}
        localCanvasRef={canvasRef}
        fileSaveStatus={fileSaveStatus}
        onSync={() => {
          bypassUnloadPromptRef.current = true;
          setFileSaveStatus("saved");
        }}
      />
    </>
  );
}

function FetchCanvasLoader({
  canvasId,
  layoutId,
  urlUsername,
  isExample,
  isSafeMode,
  initialViewport,
  initialSearchParamEntries,
}: {
  canvasId: string;
  layoutId: string | undefined;
  urlUsername: string;
  isExample: boolean;
  isSafeMode: boolean;
  initialViewport: [number, number, number] | undefined;
  initialSearchParamEntries: Record<string, string>;
}) {
  const [canvasUser, setCanvasUser] = useState<User | undefined>(undefined);
  const canvasUserRef = useSyncRef(canvasUser);
  const [loadError, setLoadError] = useState<Error | undefined>(undefined);
  const layoutIdRef = useSyncRef(layoutId);
  const canvasRef = useRef<Canvas | undefined>(undefined);
  const [canvasName, setCanvasName] = useState<string | undefined>(undefined);
  useEffect(() => {
    let isMounted = true;
    async function go() {
      try {
        const canvas = await fetchCanvas(canvasId);
        if (canvas === undefined) {
          throw new Error("canvas not found");
        }
        if (isMounted) {
          setCanvasUser(canvas.user);
          setCanvasName(canvas.name);
          let layoutId = layoutIdRef.current;
          let usedDefaultLayout = false;
          if (
            layoutId === undefined &&
            canvas.defaultLayoutId !== undefined &&
            canvas.defaultLayoutId !== layoutId &&
            canvas.layouts.some(
              (layout) => layout.id === canvas.defaultLayoutId
            )
          ) {
            layoutId = canvas.defaultLayoutId;
            setLayoutId(layoutId);
            usedDefaultLayout = true;
          }
          SandboxFrameAPI.setCanvas(["not-sync", canvas], layoutId, {
            isSafeMode,
            initialViewport,
            initialSearchParamEntries,
            usedDefaultLayout,
          });
        }
        canvasRef.current = canvas;
      } catch (e: any) {
        setLoadError(e);
      }
    }
    go();
    if (isExample) {
      logEvent("load example canvas", {
        "canvas id": canvasId,
      });
    } else {
      logEvent("load published canvas", {
        "canvas id": canvasId,
      });
    }
    return () => {
      isMounted = false;
    };
  }, []);
  useEffect(() => {
    if (canvasRef.current !== undefined) {
      SandboxFrameAPI.setLayoutId(layoutId);
    }
  }, [layoutId]);
  useEffect(() => {
    if (canvasName !== undefined) {
      updateDocumentTitle(canvasName);
    }
  }, [canvasName]);

  const history = useHistory();
  const setLayoutId = useCallback((layoutId: string | undefined) => {
    if (layoutIdRef.current === layoutId) {
      return;
    }
    if (isExample) {
      history.replace(
        `/example/${removeUuidDashes(canvasId)}${
          layoutId !== undefined ? `?layout=${layoutId}` : ""
        }`
      );
      return;
    }
    history.replace(
      getPublishedCanvasPath(
        canvasUserRef.current?.username ?? urlUsername,
        canvasId,
        layoutId
      )
    );
  }, []);
  const [hasChanges, setHasChanges] = useState(false);
  useCanvasUpdateListeners(setLayoutId, (canvas) => {
    canvasRef.current = canvas;
    setHasChanges(true);
  });

  const canvasUsername = canvasUser?.username;
  useEffect(() => {
    if (
      !isExample &&
      canvasUsername !== undefined &&
      urlUsername !== canvasUsername
    ) {
      history.replace(
        getPublishedCanvasPath(canvasUsername, canvasId, layoutId)
      );
    }
  }, [isExample, canvasUsername]);

  return (
    <>
      {canvasName !== undefined ? (
        <CanvasNameUnitParent
          canvasId={canvasId}
          hasUnsavedChanges={hasChanges}
          value={
            isExample
              ? ["example", canvasName]
              : ["published", urlUsername, canvasName]
          }
          localCanvasRef={canvasRef}
          onSync={() => {
            setHasChanges(false);
          }}
        />
      ) : null}
      {loadError !== undefined ? (
        <div className="fixed right-4 top-14 bg-red-500 text-neutral-0 p-4 rounded">
          error loading canvas {canvasId}
        </div>
      ) : null}
    </>
  );
}

function SyncCanvasLoader({
  syncCanvasId,
  layoutId,
  isSafeMode,
  initialViewport,
  initialSearchParamEntries,
}: {
  syncCanvasId: SyncCanvasId;
  layoutId: string | undefined;
  isSafeMode: boolean;
  initialViewport: [number, number, number] | undefined;
  initialSearchParamEntries: Record<string, string>;
}) {
  const layoutIdRef = useSyncRef(layoutId);
  const hasLoadedCanvasRef = useRef(false);
  useEffect(() => {
    // warm up socket token cache
    getSocketToken(syncCanvasId);
  }, [syncCanvasId]);
  useEffect(
    action(() => {
      syncCanvasIdAtom.value = syncCanvasId;
      hasLoadedCanvasRef.current = true;
      if (syncCanvasId[0] === "canvas") {
        const canvasId = syncCanvasId[1];
        SandboxFrameAPI.setCanvas(["sync", canvasId], layoutIdRef.current, {
          isSafeMode,
          initialViewport,
          initialSearchParamEntries,
        });
        logEvent("load sync canvas", {
          "canvas id": canvasId,
        });
      } else {
        const inviteCode = syncCanvasId[1];
        SandboxFrameAPI.setCanvas(
          ["sync", undefined, inviteCode],
          layoutIdRef.current,
          { isSafeMode, initialViewport }
        );
        logEvent("load invite canvas", {
          "invite code": inviteCode,
        });
      }
      return action(() => {
        syncCanvasIdAtom.value = undefined;
      });
    }),
    []
  );
  useEffect(() => {
    if (hasLoadedCanvasRef.current) {
      SandboxFrameAPI.setLayoutId(layoutId);
    }
  }, [layoutId]);

  const history = useHistory();
  const setLayoutId = useCallback((layoutId: string | undefined) => {
    if (layoutIdRef.current === layoutId) {
      return;
    }
    if (syncCanvasId[0] === "canvas") {
      const canvasUsername = userAtom.value?.username;
      const canvasId = syncCanvasId[1];
      if (canvasUsername !== undefined && canvasId !== undefined) {
        history.replace(
          getPublishedCanvasPath(canvasUsername, canvasId, layoutId)
        );
      }
    } else {
      history.replace(getInvitePath(syncCanvasId[1], layoutId));
    }
  }, []);
  useCanvasUpdateListeners(setLayoutId, () => {});
  useEffect(() => {
    function onNameUpdate({
      canvasId,
      name,
    }: {
      canvasId: string;
      name: string;
    }) {
      if (syncCanvasId[0] === "canvas" && canvasId === syncCanvasId[1]) {
        setMyPublishedCanvasName(canvasId, name);
      }
      updateDocumentTitle(name);
    }
    SandboxFrameAPI.addListener("UPDATE_CANVAS_NAME", onNameUpdate);
    return () => {
      SandboxFrameAPI.removeListener("UPDATE_CANVAS_NAME", onNameUpdate);
    };
  }, []);

  return null;
}

function TutorialCanvasApp({
  tutorialId,
  layoutId,
}: {
  tutorialId: string;
  layoutId: string | undefined;
}) {
  const layoutIdRef = useSyncRef(layoutId);
  useEffect(() => {
    const canvas: Canvas = {
      id: TUTORIAL_CANVAS_ID,
      name: "tutorial",
      panes: [],
      layouts: [],
      createdTime: Date.now(),
    };
    SandboxFrameAPI.setCanvas(["not-sync", canvas], layoutIdRef.current, {});
  }, []);
  const isInitialRenderRef = useRef(true);
  useEffect(() => {
    if (!isInitialRenderRef.current) {
      SandboxFrameAPI.setLayoutId(layoutId);
    } else {
      isInitialRenderRef.current = false;
    }
  }, [layoutId]);

  const history = useHistory();
  const setLayoutId = useCallback((layoutId: string | undefined) => {
    if (layoutIdRef.current === layoutId) {
      return;
    }
    history.replace(
      `/tutorial/${tutorialId}${
        layoutId !== undefined ? `?layout=${layoutId}` : ""
      }`
    );
  }, []);
  useCanvasUpdateListeners(setLayoutId, (update) => {});

  return null;
}

const DISMISS_BEST_EXPERIENCE_KEY = "dismiss_best_experience_modal";
const BestExperienceModal = observer(() => {
  const [shouldShow, setShouldShow] = useState(
    () =>
      localStorage.getItem(DISMISS_BEST_EXPERIENCE_KEY) !== "true" &&
      (window.innerWidth <= 800 || window.innerHeight <= 600)
  );
  const shouldShowConsideringGallery =
    shouldShow && showGalleryDialogBox.get() === false;
  useEffect(() => {
    if (shouldShowConsideringGallery) {
      logEvent("view best experience modal");
    }
  }, [shouldShowConsideringGallery]);
  if (!shouldShowConsideringGallery) {
    return null;
  }
  return (
    <div className="fixed top-0 bottom-0 left-0 right-0 bg-neutral-200 bg-opacity-80 z-99999 flex items-center justify-center px-4">
      <div className="flex flex-col items-center bg-neutral-0 rounded-lg shadow-lg pt-8 px-4 pb-4 max-w-sm">
        <div className="natto-icon mx-auto mb-6 relative left-8" />
        <div className="text-center mb-4">
          natto.dev is best experienced on a larger screen with a keyboard and
          mouse. Please try it if you can!
        </div>
        <button
          className="button"
          onClick={() => {
            setShouldShow(false);
            localStorage.setItem(DISMISS_BEST_EXPERIENCE_KEY, "true");
          }}
        >
          Okay
        </button>
      </div>
    </div>
  );
});

function useRequestSocket(
  paneCanvasRef: React.MutableRefObject<{
    request: (data: any) => Promise<any>;
  } | null>
) {
  useEffect(() => {
    const socket = new WebSocket("ws://localhost:4000");
    let pingInterval: number | undefined = window.setInterval(() => {
      socket.send("ping");
    }, 10000);
    socket.addEventListener("open", (e) => {
      console.log("open", e);
    });
    socket.addEventListener("message", (e) => {
      console.log("message", e.data);
      const [requestId, data] = JSON.parse(e.data);
      paneCanvasRef
        .current!.request(data)
        .then((response: any) =>
          socket.send(JSON.stringify([requestId, response]))
        );
    });
    socket.addEventListener("close", (e) => {
      console.log("close", e);
      if (pingInterval !== undefined) {
        clearInterval(pingInterval);
        pingInterval = undefined;
      }
    });
    return () => {
      if (pingInterval !== undefined) {
        clearInterval(pingInterval);
      }
      socket.close();
    };
  }, []);
}

const fileCanvasKeyAtom = atom(1);
const fileCanvasHandleAtom = atom<FileSystemHandle | undefined>(undefined);

const NoCanvasRoute = observer(() => {
  const fileCanvasId = fileCanvasIdAtom.value;
  const fileCanvasKey = fileCanvasKeyAtom.value;
  const fileCanvasHandle = fileCanvasHandleAtom.value;

  useEffect(() => {
    if (fileCanvasId === WELCOME_CANVAS_ID) {
      localStorage.setItem("has_seen_welcome", "true");
    }
    return action(() => {
      fileCanvasIdAtom.value = undefined;
      fileCanvasHandleAtom.value = undefined;
    });
  }, []);

  return (
    <>
      {fileCanvasId !== undefined ? (
        <CanvasApp
          fileCanvasId={fileCanvasId}
          type="file"
          fileName={fileCanvasHandle?.name}
          key={fileCanvasKey}
        />
      ) : (
        <div className="fixed top-0 bottom-0 left-0 right-0 flex items-center justify-center">
          <div className="bg-neutral-200 bg-opacity-75 dark:bg-canvas-bg dark:bg-opacity-100 absolute top-0 bottom-0 left-0 right-0" />
          <NavigationDialog
            navigateToFileCanvasId={action((canvasId, fileHandle) => {
              fileCanvasKeyAtom.value = fileCanvasKeyAtom.value + 1;
              fileCanvasIdAtom.value = canvasId;
              fileCanvasHandleAtom.value = fileHandle;
            })}
            showHeader={true}
            onClose={() => {}}
          />
        </div>
      )}
    </>
  );
});

const HeaderCanvasSelector = forwardRef(
  (
    {
      hasMaximizedPane,
    }: {
      hasMaximizedPane: boolean;
    },
    ref
  ) => {
    const [isForceOpen, setIsForceOpen] = useState(false);
    const [isMouseOver, setIsMouseOverFn] = useState(false);
    const mouseOverTimeoutRef = useRef<number | undefined>(undefined);
    const setIsMouseOver = useCallback(
      (isMouseOver: boolean) => {
        if (!isMouseOver) {
          if (mouseOverTimeoutRef.current !== undefined) {
            clearTimeout(mouseOverTimeoutRef.current);
            mouseOverTimeoutRef.current = undefined;
          }
          setIsMouseOverFn(false);
        } else {
          if (mouseOverTimeoutRef.current === undefined) {
            mouseOverTimeoutRef.current = setTimeout(() => {
              setIsMouseOverFn(true);
              mouseOverTimeoutRef.current = undefined;
            }, 30);
          }
        }
      },
      [setIsMouseOverFn]
    );
    useEffect(
      () => () => {
        if (mouseOverTimeoutRef.current !== undefined) {
          clearTimeout(mouseOverTimeoutRef.current);
        }
      },
      []
    );
    const isVisible = isForceOpen || isMouseOver;
    useImperativeHandle(ref, () => ({
      open: () => setIsForceOpen(true),
    }));
    const rootRef = useRef<HTMLDivElement | null>(null);
    useEffect(() => {
      if (isForceOpen) {
        function onMouseDown(e: MouseEvent) {
          if (e.target instanceof Node && rootRef.current!.contains(e.target)) {
            return;
          }
          setIsForceOpen(false);
        }
        window.addEventListener("mousedown", onMouseDown);
        function onSandboxMouseDown() {
          setIsForceOpen(false);
        }
        SandboxMousedownEmitter.addListener("mousedown", onSandboxMouseDown);
        return () => {
          window.removeEventListener("mousedown", onMouseDown);
          SandboxMousedownEmitter.removeListener(
            "mousedown",
            onSandboxMouseDown
          );
        };
      } else {
        function onShowCanvasSelector() {
          setIsForceOpen(true);
        }
        SandboxFrameAPI.addListener(
          "SHOW_CANVAS_SELECTOR",
          onShowCanvasSelector
        );
        return () => {
          SandboxFrameAPI.removeListener(
            "SHOW_CANVAS_SELECTOR",
            onShowCanvasSelector
          );
        };
      }
    }, [isForceOpen]);

    const history = useHistory();

    return (
      <>
        <div
          className={classNames(
            "fixed left-0 top-0 h-10 md:h-12 md:w-36 group border-b border-secondary-200 md:border-b-0",
            {
              hidden: hasMaximizedPane,
            }
          )}
          onMouseEnter={() => {
            setIsMouseOver(true);
          }}
          onMouseLeave={() => {
            setIsMouseOver(false);
          }}
          ref={rootRef}
        >
          <button
            onClick={() => {
              setIsForceOpen((v) => !v);
              if (isForceOpen) {
                setIsMouseOver(false);
              }
            }}
            className="flex h-full w-full pl-3 items-center"
          >
            <div className="logo mr-2" />
            <h1 className="font-mono-natto text-secondary-700 mr-1">
              natto.dev
            </h1>
            <FeatherIcon
              icon="chevron-down"
              size="sm"
              className={classNames(
                "text-secondary-800 transform transition",
                isVisible ? "-rotate-180" : ""
              )}
            />
          </button>
          <div
            className={classNames(
              "absolute top-10 md:top-12 left-0 transform origin-top-left transition sm:pt-2 sm:pl-2 max-w-[calc(100vw)]",
              isMouseOver || isForceOpen
                ? "opacity-100"
                : "opacity-0 scale-90 pointer-events-none"
            )}
          >
            <NavigationDialog
              navigateToFileCanvasId={action((canvasId, fileHandle) => {
                fileCanvasKeyAtom.value = fileCanvasKeyAtom.value + 1;
                fileCanvasIdAtom.value = canvasId;
                fileCanvasHandleAtom.value = fileHandle;
                const fileCanvasPath = getFileCanvasPath();
                if (window.location.pathname !== fileCanvasPath) {
                  history.push(fileCanvasPath);
                }
              })}
              showHeader={false}
              onClose={() => {
                setIsMouseOver(false);
                setIsForceOpen(false);
              }}
            />
          </div>
        </div>
      </>
    );
  }
);

const CanvasApp = observer(
  ({
    type,
    fileCanvasId,
    fileName,
  }: {
    type: "file" | "published" | "invite" | "example" | "tutorial";
    fileCanvasId?: string;
    fileName?: string | undefined;
  }) => {
    const {
      canvasId: urlCanvasId,
      tutorialId,
      username: urlUsernameRaw,
      inviteCode,
    } = useRouteMatch<{
      canvasId?: string;
      tutorialId?: string;
      username?: string;
      inviteCode?: string;
    }>().params;
    let canvasId = fileCanvasId ?? urlCanvasId ?? TUTORIAL_CANVAS_ID;
    if (!canvasId.includes("-")) {
      canvasId = addUuidDashes(canvasId);
    }
    const urlUsername = urlUsernameRaw?.slice(1);
    const location = useLocation();
    const layoutId = useMemo(() => {
      const searchParams = new URLSearchParams(location.search);
      return searchParams.get("layout") ?? undefined;
    }, [location.search]);

    const hasMaximizedPane = computed(
      () => uiStateMobx.maximizedPaneId !== undefined
    ).get();
    const hasSession = computed(() => sessionAtom.value !== undefined).get();
    const user = userAtom.value;
    const [initialSearchParamEntries] = useState(() =>
      Object.fromEntries(new URLSearchParams(location.search))
    );
    const [[isSafeMode, initialViewport], setQueryParams] = useState(() => {
      let viewport: [number, number, number] | undefined;
      const viewportParam = initialSearchParamEntries["viewport"];
      if (viewportParam !== undefined) {
        const parsedViewport = viewportParam.split(",");
        if (parsedViewport.length >= 2) {
          const parsedZoom = parseFloat(parsedViewport[2]);
          viewport = [
            parseInt(parsedViewport[0]),
            parseInt(parsedViewport[1]),
            isNaN(parsedZoom) ? 1 : parsedZoom,
          ];
        }
      }
      return [initialSearchParamEntries["safemode"] === "1", viewport] as const;
    });
    const canvasSelection: CanvasSelection = useMemo(
      () =>
        type === "file"
          ? ["file", canvasId, fileName]
          : type === "example"
          ? ["example", canvasId]
          : type === "invite"
          ? ["invite", inviteCode!]
          : type === "tutorial"
          ? ["tutorial", tutorialId!]
          : urlUsername !== undefined
          ? ["user", urlUsername, canvasId]
          : ["draft", canvasId],
      [canvasId, fileName, urlUsername, inviteCode, type]
    );
    useEffect(
      action(() => {
        canvasSelectionAtom.value = canvasSelection;
      }),
      [canvasSelection]
    );
    const canvasSelectorRef = useRef<{ open: () => void } | null>(null);
    const showCanvasSelector = useCallback(() => {
      if (canvasSelectorRef.current !== null) {
        canvasSelectorRef.current.open();
      }
    }, []);
    const history = useHistory();
    useEffect(() => {
      if (isSafeMode || initialViewport !== undefined) {
        const searchParams = new URLSearchParams(location.search);
        searchParams.delete("safemode");
        searchParams.delete("viewport");
        const query = searchParams.toString();
        // remove safemode from url
        history.replace(
          `${location.pathname}${query !== "" ? `?${query}` : ""}`
        );
      }
      setQueryParams([false, undefined]);
    }, []);

    useEffect(() => {
      resetEnvironmentVariablesSession();
      return () => {
        document.body.style.backgroundSize = "";
        reloadSandbox();
      };
    }, [canvasId]);

    let canvasComponent = null;
    if (type === "file") {
      canvasComponent = (
        <FileCanvasApp
          canvasId={canvasId}
          layoutId={layoutId}
          isSafeMode={isSafeMode}
          key={canvasId}
        />
      );
    } else if (type === "tutorial") {
      canvasComponent = (
        <TutorialCanvasApp
          tutorialId={tutorialId!}
          layoutId={layoutId}
          key={tutorialId}
        />
      );
    } else if (type === "invite") {
      canvasComponent = (
        <SyncCanvasLoader
          syncCanvasId={["invite", inviteCode!]}
          layoutId={layoutId}
          isSafeMode={isSafeMode}
          initialViewport={initialViewport}
          initialSearchParamEntries={initialSearchParamEntries}
          key={inviteCode!}
        />
      );
    } else if (
      hasSession &&
      urlUsername !== undefined &&
      (user?.username ?? getCachedUsername()) === urlUsername
    ) {
      canvasComponent = (
        <SyncCanvasLoader
          syncCanvasId={["canvas", canvasId]}
          layoutId={layoutId}
          isSafeMode={isSafeMode}
          initialViewport={initialViewport}
          initialSearchParamEntries={initialSearchParamEntries}
          key={canvasId}
        />
      );
    } else {
      canvasComponent = (
        <FetchCanvasLoader
          canvasId={canvasId}
          layoutId={layoutId}
          urlUsername={urlUsername!}
          isExample={type === "example"}
          isSafeMode={isSafeMode}
          initialViewport={initialViewport}
          initialSearchParamEntries={initialSearchParamEntries}
          key={canvasId}
        />
      );
    }

    return (
      <>
        {canvasComponent}
        <HeaderCanvasSelector
          hasMaximizedPane={hasMaximizedPane}
          ref={canvasSelectorRef}
        />
      </>
    );
  }
);

function InvalidRouteRedirect() {
  const history = useHistory();
  useEffect(() => {
    history.push("/", { replace: true });
  }, []);
  return null;
}

const CanvasRouterApp = observer(() => {
  useEffect(() => {
    logEvent("start session", {
      referrer: document.referrer,
    });
  }, []);
  const user = userAtom.value;

  const history = useHistory();
  useEffect(() => {
    setNavigate((pathname) => {
      history.push(pathname);
    });
  }, []);

  if (user !== undefined && user.username === undefined) {
    return <UserUsernameDialogController />;
  }

  return (
    <Switch>
      <Route exact path="/">
        <NoCanvasRoute />
      </Route>
      <Route path="/example/:canvasId">
        <CanvasApp type="example" />
      </Route>
      <Route path="/tutorial/:tutorialId">
        <CanvasApp type="tutorial" />
      </Route>
      <Route path="/invite/:inviteCode">
        <CanvasApp type="invite" />
      </Route>
      <Route path="/:username/:canvasId">
        <CanvasApp type="published" />
      </Route>
      <Route>
        <InvalidRouteRedirect />
      </Route>
    </Switch>
  );
});

function AuthCallback() {
  const history = useHistory();
  const location = useLocation();
  useEffect(() => {
    const hashParams = new URLSearchParams(location.hash.slice(1));
    const accessToken = hashParams.get("access_token");
    const refreshToken = hashParams.get("refresh_token");
    if (accessToken === null || refreshToken === null) {
      throw new Error();
    }
    setSession(accessToken, refreshToken);
    fetchAndSetUser();
    const redirectTo = hashParams.get("redirect_uri");
    if (redirectTo !== null) {
      history.push(redirectTo);
    } else {
      history.push("/");
    }
  }, []);
  return null;
}

function ExternalRedirect({ to }: { path: string; to: string }) {
  useEffect(() => {
    window.location.href = to;
  }, []);
  return null;
}

function GalleryRedirect() {
  const { slug } = useRouteMatch<{
    slug?: string;
  }>().params;
  const history = useHistory();
  useEffect(() => {
    showGalleryDialog(slug);
    history.push("/", { replace: true });
  }, []);
  return null;
}

export default function App() {
  return (
    <TooltipProvider>
      <BrowserRouter>
        <div id="app" className="w-[100vw]">
          <Switch>
            {/* @ts-ignore */}
            <Route path="/auth">
              <AuthCallback />
            </Route>
            <Route path="/tutorial" exact={true}>
              <ExternalRedirect
                path="/tutorial"
                to="https://www.notion.so/ca316f2f2c434d408553d515a4ed4e21"
              />
            </Route>
            <Route path="/documentation">
              <ExternalRedirect
                path="/documentation"
                to="https://www.notion.so/44fea11f393a4e46b5a22f5799de58ca"
              />
            </Route>
            <Route path="/gallery/:slug?">
              <GalleryRedirect />
            </Route>
            <Route>
              <CanvasRouterApp />
            </Route>
          </Switch>
          <div className="fixed top-0 left-0 right-0 z-99999">
            <LayerContainer />
          </div>
          <BestExperienceModal />
          <SettingsDialogController />
          <EnvironmentVariablesDialogController />
          <LoginDialogController />
          <InvitesDialogController />
          <GalleryDialogController />
        </div>
      </BrowserRouter>
    </TooltipProvider>
  );
}
