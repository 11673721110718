import {
  directoryOpen,
  supported as isFileSystemAPISupported,
} from "browser-fs-access";

type DirectoryOfFiles = {
  [key: string]: File | DirectoryOfFiles;
};

function comparePathName(a: string, b: string) {
  if (a.startsWith(".")) {
    return b.startsWith(".") ? a.localeCompare(b) : 1;
  }
  if (b.startsWith(".")) {
    return -1;
  }
  return a.localeCompare(b);
}

function sortDirectoryOfFiles(
  directoryOfFiles: DirectoryOfFiles
): DirectoryOfFiles {
  return Object.fromEntries(
    Object.entries(directoryOfFiles)
      .sort((a, b) => {
        const isDirectoryA = !(a[1] instanceof File);
        const isDirectoryB = !(b[1] instanceof File);
        if (isDirectoryA) {
          return isDirectoryB ? comparePathName(a[0], b[0]) : -1;
        }
        if (isDirectoryB) {
          return 1;
        }
        return comparePathName(a[0], b[0]);
      })
      .map(([path, fileOrDirectory]) => [
        path,
        fileOrDirectory instanceof File
          ? fileOrDirectory
          : sortDirectoryOfFiles(fileOrDirectory),
      ])
  );
}

export async function openFileDirectory() {
  const rv: DirectoryOfFiles = {};
  if (!isFileSystemAPISupported) {
    const files = await directoryOpen({ recursive: true });
    const addFileToDirectory = (
      root: DirectoryOfFiles,
      file: File,
      path: string[]
    ) => {
      if (path.length === 1) {
        root[path[0]] = file;
      } else {
        if (root[path[0]] === undefined) {
          root[path[0]] = {};
        }
        addFileToDirectory(
          root[path[0]] as DirectoryOfFiles,
          file,
          path.slice(1)
        );
      }
    };
    for (const file of files) {
      const path = file.webkitRelativePath.split("/").slice(1);
      addFileToDirectory(rv, file, path);
    }
    return sortDirectoryOfFiles(rv);
  }

  const getFiles = async (
    root: DirectoryOfFiles,
    directoryHandle: any,
    path: string = directoryHandle.name
  ) => {
    const resolvePromises: Promise<void>[] = [];
    for await (const entry of directoryHandle.values()) {
      const nestedPath = `${path}/${entry.name}`;
      if (entry.kind === "file") {
        resolvePromises.push(
          entry.getFile().then((file: File) => {
            root[entry.name] = file;
          })
        );
      } else if (entry.kind === "directory") {
        const nestedDirectory = {};
        root[entry.name] = nestedDirectory;
        resolvePromises.push(getFiles(nestedDirectory, entry, nestedPath));
      }
    }
    await Promise.all(resolvePromises);
  };

  // @ts-ignore
  const directoryHandle = await window.showDirectoryPicker();
  await getFiles(rv, directoryHandle);
  return sortDirectoryOfFiles(rv);
}
