import { FileSystemHandle } from "browser-fs-access";
import { SettingsDB } from "./IndexedDB";

const RECENT_FILES_KEY = "recent_files";

export async function getRecentFiles(): Promise<
  FileSystemHandle[] | undefined
> {
  const setting = await SettingsDB.getSetting(RECENT_FILES_KEY);
  if (!Array.isArray(setting)) {
    return undefined;
  }
  return setting;
}

export async function addRecentFile(fileHandle: FileSystemHandle) {
  const recentFiles = (await getRecentFiles()) ?? [];
  const isSameEntriesArray = await Promise.all(
    recentFiles.map((h) => h.isSameEntry(fileHandle))
  );
  const newRecentFiles = [
    fileHandle,
    ...recentFiles.filter((_, i) => !isSameEntriesArray[i]),
  ].slice(0, 5);
  await SettingsDB.putSetting(RECENT_FILES_KEY, newRecentFiles);
}

export async function removeRecentFile(fileHandle: FileSystemHandle) {
  const recentFiles = (await getRecentFiles()) ?? [];
  const isSameEntriesArray = await Promise.all(
    recentFiles.map((h) => h.isSameEntry(fileHandle))
  );
  const newRecentFiles = recentFiles.filter((_, i) => !isSameEntriesArray[i]);
  await SettingsDB.putSetting(RECENT_FILES_KEY, newRecentFiles);
}
