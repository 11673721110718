import classNames from "classnames";
import featherSpriteSvg from "feather-icons/dist/feather-sprite.svg";
import React, { forwardRef } from "react";

const SIZE_CLASS_NAME = {
  xs: "w-2.5 h-2.5",
  sm: "w-3 h-3",
  md: "w-4 h-4",
};

export const FeatherIcon = forwardRef<
  SVGSVGElement,
  {
    icon: string;
    size: "xs" | "sm" | "md";
    className?: string;
    style?: React.CSSProperties;
  }
>(({ icon, size, className, style, ...props }, ref) => {
  return (
    <svg
      {...props}
      className={classNames(
        "feather shrink-0",
        SIZE_CLASS_NAME[size],
        size === "md" ? "stroke-[1.5]" : "stroke-2",
        className
      )}
      style={style}
      ref={ref}
    >
      <use href={`${featherSpriteSvg}#${icon}`} />
    </svg>
  );
});
