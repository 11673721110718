import { customAlphabet } from "nanoid";
import nanoidDictionary from "nanoid-dictionary";
import {
  DEFAULT_LIBRARY_PANE_NAME,
  DEFAULT_LLM_PANE_HEIGHT,
  DEFAULT_PANE_HEIGHT,
  DEFAULT_PANE_WIDTH,
  DEFAULT_TEMPLATE_PANE_HEIGHT,
  DEFAULT_TEMPLATE_PANE_WIDTH,
  LLM_OPENAI_CONFIG_DEFAULT_VALUES,
} from "./Constants.js";
import {
  Action,
  ActionType,
  ConstructedPane,
  Pane,
  PaneType,
  Position,
  Rect,
  SupportedBabelPlugin,
} from "./Types.js";

const { alphanumeric, lowercase, uppercase } = nanoidDictionary;

export function assert(condition: any, msg?: string): asserts condition {
  if (!condition) {
    throw new Error(msg);
  }
}

export function removeUuidDashes(uuid: string) {
  return uuid.replace(/-/g, "");
}

export function getNewPaneRect(
  paneType: PaneType,
  position: Position,
  size?: Position
): Rect {
  return [
    position[0],
    position[1],
    size !== undefined
      ? size[0]
      : paneType === PaneType.EvaluateTemplate
      ? DEFAULT_TEMPLATE_PANE_WIDTH
      : paneType === PaneType.Import || paneType === PaneType.EvaluateGlobal
      ? 320
      : paneType === PaneType.LLM
      ? 336
      : DEFAULT_PANE_WIDTH,
    size !== undefined
      ? size[1]
      : paneType === PaneType.LLM
      ? DEFAULT_LLM_PANE_HEIGHT
      : paneType === PaneType.EvaluateTemplate
      ? DEFAULT_TEMPLATE_PANE_HEIGHT
      : DEFAULT_PANE_HEIGHT,
  ];
}

export const PANE_KEY_PREFIX = "p/";
export const LAYOUT_KEY_PREFIX = "l/";
export function getPaneKey(paneId?: string) {
  return `${PANE_KEY_PREFIX}${paneId ?? ""}`;
}
export function getLayoutKey(layoutId?: string) {
  return `${LAYOUT_KEY_PREFIX}${layoutId ?? ""}`;
}

const nanoid20 = customAlphabet(alphanumeric, 20);
const ALPHABET = lowercase + uppercase;
export function generatePaneId(): string {
  // alphanumeric with alpha first letter (variable name)
  return `${
    ALPHABET[Math.floor(Math.random() * ALPHABET.length)]
  }${nanoid20()}`;
}

const nanoid7 = customAlphabet(alphanumeric, 7);
export function generatePaneInputId(): string {
  return `${ALPHABET[Math.floor(Math.random() * ALPHABET.length)]}${nanoid7()}`;
}

export function getNewPane(
  paneFields: { id: string; rect: Rect; z: number; name: string | undefined },
  paneType: PaneType
): Pane {
  switch (paneType) {
    case PaneType.Evaluate:
      return {
        ...paneFields,
        inputs: [],
        type: PaneType.Evaluate,
        autorun: true,
        expression: "",
        editorHeight: undefined,
        babelPlugins: [],
      };
    case PaneType.EvaluateGlobal:
      return {
        ...paneFields,
        type: PaneType.EvaluateGlobal,
        autorun: true,
        expression: "",
        editorHeight: undefined,
      };
    case PaneType.InputText:
      return {
        ...paneFields,
        type: PaneType.InputText,
        text: "",
      };
    case PaneType.State:
      return {
        ...paneFields,
        type: PaneType.State,
        initialExpression: "",
      };
    case PaneType.Import:
      return {
        ...paneFields,
        type: PaneType.Import,
        module: undefined,
        isGlobal: false,
        useDefault: false,
      };
    case PaneType.EnvironmentVariable:
      return {
        ...paneFields,
        type: PaneType.EnvironmentVariable,
        key: undefined,
      };
    case PaneType.Library:
      return {
        ...paneFields,
        type: PaneType.Library,
        name: paneFields.name ?? DEFAULT_LIBRARY_PANE_NAME,
      };
    case PaneType.LLM:
      return {
        ...paneFields,
        type: PaneType.LLM,
        config: {
          provider: "openai",
          model: "gpt-3.5-turbo",
          temperature: LLM_OPENAI_CONFIG_DEFAULT_VALUES.temperature,
          max_tokens: LLM_OPENAI_CONFIG_DEFAULT_VALUES.max_tokens,
        },
      };
  }
  throw new Error();
}

export function areBabelPluginsEqual(
  a: SupportedBabelPlugin,
  b: SupportedBabelPlugin
) {
  if (a === b) {
    return true;
  }
  if (Array.isArray(a) && Array.isArray(b)) {
    return a[1] === b[1];
  }
  return false;
}

export function isPresenceAction(action: Action) {
  return (
    action[0] === ActionType.PresenceCursor ||
    action[0] === ActionType.PresencePaneId ||
    action[0] === ActionType.PresenceTextRange ||
    action[0] === ActionType.PresenceName
  );
}

// (abcdefghijklmnopqrstuvwxyz, 12) => abcdefghijklmnopqrstuvwx12
export function getEjectedTemplatePaneId(
  originalPaneId: string,
  paneIndex: number
) {
  const paneIndexString = `${paneIndex}`;
  return `${originalPaneId.substring(
    0,
    originalPaneId.length - paneIndexString.length
  )}${paneIndexString}`;
}

// (abcdefgijklmn, 12) => abcdef12
export function getEjectedTemplatePaneInputId(
  paneId: string,
  inputIndex: number
): string {
  const inputIndexString = `${inputIndex}`;
  return `${paneId.substring(
    0,
    8 - inputIndexString.length
  )}${inputIndexString}`;
}

export function transformRect(rect: Rect, x: number, y: number): Rect {
  return [rect[0] + x, rect[1] + y, rect[2], rect[3]];
}

export function createEjectedPanes(
  templatePane: Pane,
  templateInputSources: Record<string, [paneId: string, outputIndex: number]>,
  constructedPanes: ConstructedPane[]
): Pane[] {
  const templatePaneId = templatePane.id;
  return constructedPanes.map((constructedPane, paneIndex) => {
    switch (constructedPane.type) {
      case PaneType.Evaluate: {
        return {
          id: getEjectedTemplatePaneId(templatePaneId, paneIndex),
          type: PaneType.Evaluate,
          rect:
            constructedPane.rect !== undefined
              ? transformRect(
                  constructedPane.rect,
                  templatePane.rect[0],
                  templatePane.rect[1]
                )
              : templatePane.rect,
          z: templatePane.z + (constructedPane.z ?? 0),
          name: paneIndex === 0 ? templatePane.name : constructedPane.name,
          inputs: constructedPane.inputs.map(
            (templatePaneInput, inputIndex) => ({
              id: getEjectedTemplatePaneInputId(templatePaneId, inputIndex),
              name: templatePaneInput.name,
              source: Array.isArray(templatePaneInput.source)
                ? [
                    getEjectedTemplatePaneId(
                      templatePaneId,
                      templatePaneInput.source[0]
                    ),
                    templatePaneInput.source[1] ?? 0,
                  ]
                : templateInputSources[templatePaneInput.source],
            })
          ),
          expression: constructedPane.expression,
          editorHeight: undefined,
          autorun: true,
          expressionType: constructedPane.expressionType,
          babelPlugins: [],
          renderOutput: constructedPane.renderOutput,
        };
      }
      case PaneType.Import: {
        return {
          id: getEjectedTemplatePaneId(templatePaneId, paneIndex),
          type: PaneType.Import,
          rect:
            constructedPane.rect !== undefined
              ? transformRect(
                  constructedPane.rect,
                  templatePane.rect[0],
                  templatePane.rect[1]
                )
              : templatePane.rect,
          z: templatePane.z + (constructedPane.z ?? 0),
          name: constructedPane.name,
          module: constructedPane.module,
          useDefault: constructedPane.useDefault,
        };
      }
      case PaneType.State: {
        return {
          id: getEjectedTemplatePaneId(templatePaneId, paneIndex),
          type: PaneType.State,
          rect:
            constructedPane.rect !== undefined
              ? transformRect(
                  constructedPane.rect,
                  templatePane.rect[0],
                  templatePane.rect[1]
                )
              : templatePane.rect,
          z: templatePane.z + (constructedPane.z ?? 0),
          name: constructedPane.name,
          initialExpression: constructedPane.initialExpression,
          control: constructedPane.control,
        };
      }
    }
  });
}

export function hasFlag(flags: number, flag: number) {
  return (flags & flag) === flag;
}

export function setFlag(flags: number, flag: number, on: boolean): number {
  return on ? flags | flag : flags & ~flag;
}

export function getThemeColor(colorVariable: string): string {
  return getComputedStyle(document.documentElement).getPropertyValue(
    colorVariable
  );
}
