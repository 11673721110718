import amplitude from "amplitude-js";

const SHOULD_LOG_EVENTS = import.meta.env.PROD;

if (SHOULD_LOG_EVENTS) {
  amplitude
    .getInstance()
    .init(
      import.meta.env.DEV
        ? "6c658ac1dddb1bc14537ae55d59a8da1"
        : "41c8b264d720a0080bd036de3e589bc7",
      undefined,
      {
        includeGclid: true,
        includeReferrer: true,
        includeUtm: true,
      }
    );
  if (VITE_APP_COMMIT_REF !== undefined) {
    amplitude.getInstance().setVersionName(VITE_APP_COMMIT_REF);
  }
}

export function getDeviceId() {
  return amplitude.getInstance().options.deviceId;
}

export function setUserId(userId: string | null) {
  amplitude.getInstance().setUserId(userId);
}

export function setDeviceId(deviceId: string) {
  return amplitude.getInstance().setDeviceId(deviceId);
}

export function logEvent(
  eventName: "start session",
  eventProperties: { referrer: string }
): void;
export function logEvent(eventName: "play intro video"): void;
export function logEvent(eventName: "create local canvas"): void;
export function logEvent(
  eventName: "fork canvas",
  eventProperties: { "canvas id": string }
): void;
export function logEvent(eventName: "delete canvas"): void;
export function logEvent(eventName: "view best experience modal"): void;
export function logEvent(eventName: "view settings modal"): void;
export function logEvent(eventName: "view gallery dialog"): void;
export function logEvent(eventName: "view environment variables modal"): void;
export function logEvent(
  eventName: "view login modal",
  eventProperties: { source: string }
): void;
export function logEvent(eventName: "click google login button"): void;
export function logEvent(eventName: "click settings logout button"): void;
export function logEvent(eventName: "view username dialog"): void;
export function logEvent(
  eventName: "submit username form",
  eventProperties: { username: string }
): void;
export function logEvent(
  eventName: "load example canvas",
  eventProperties: { "canvas id": string }
): void;
export function logEvent(eventName: "load welcome canvas"): void;
export function logEvent(eventName: "load file canvas"): void;
export function logEvent(
  eventName: "load published canvas",
  eventProperties: { "canvas id": string }
): void;
export function logEvent(
  eventName: "load sync canvas",
  eventProperties: { "canvas id": string }
): void;
export function logEvent(
  eventName: "load invite canvas",
  eventProperties: { "invite code": string }
): void;
export function logEvent(eventName: "apply elk layout"): void;
export function logEvent(eventName: "select magic layout"): void;
export function logEvent(
  eventName: "view tutorial",
  eventProperties: {
    "tutorial id": string;
  }
): void;
export function logEvent(
  eventName: "advance tutorial",
  eventProperties: { "tutorial id": string; "step index": number }
): void;
export function logEvent(eventName: "grant environment access"): void;
export function logEvent(eventName: "revoke environment access"): void;
export function logEvent(
  eventName: "add environment variable",
  eventProperties: {
    type: "browser" | "user";
  }
): void;
export function logEvent(
  eventName: "open canvas file",
  eventProperties: {
    "is recent"?: boolean;
    "has handle": boolean;
  }
): void;
export function logEvent(
  eventName: "save canvas file",
  eventProperties: {
    source: string;
  }
): void;
export function logEvent(eventName: "download legacy canvases"): void;
export function logEvent(
  eventName: "error",
  eventProperties: {
    type: string;
    metadata?: string;
  }
): void;
export function logEvent(
  eventName: "close socket",
  eventProperties: {
    code: number;
    reason: string;
  }
): void;
export function logEvent(
  eventName: "error refreshing token",
  eventProperties: {
    "refresh token": string;
    "expired at": number;
  }
): void;
export function logEvent(
  eventName: "debug",
  eventProperties: {
    type: string;
    metadata?: string;
  }
): void;
export function logEvent(
  eventName: string,
  eventProperties?: Record<string, any>
) {
  if (SHOULD_LOG_EVENTS) {
    amplitude.getInstance().logEvent(eventName, eventProperties);
  }
}
