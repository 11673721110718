import { fileSave } from "browser-fs-access";
import * as Comlink from "comlink";
import EventEmitter from "eventemitter3";
import { runInAction } from "mobx";
import React, { useEffect } from "react";
import { getDeviceId, logEvent } from "../Amplitude";
import {
  CURRENT_VERSION,
  FILE_EXTENSION,
  SANDBOX_ORIGIN,
  UNSAVED_CANVAS_NAME,
} from "../Constants";
import { ClipboardPane } from "../sandbox/CanvasClipboard";
import { syncCanvasIdAtom, userAtom } from "../SharedIframeState";
import {
  Canvas,
  CanvasData,
  CanvasSelection,
  EnvironmentVariableValue,
  SetCanvasOptions,
} from "../Types";
import { isModifierKeyDown, removeUuidDashes } from "../Utils";
import {
  fetchUserEnvironmentVariables,
  generateDebugVisualizerCode,
  generateInvite,
  getBearerToken,
  getSocketToken,
  publishCanvas,
  unpublishCanvas,
} from "./API";
import {
  addMyPublishedCanvasIdAndName,
  removeMyPublishedCanvasIdAndName,
} from "./AppState";
import { EnvironmentVariableDB } from "./EnvironmentVariablesDB";
import { showEnvironmentVariablesDialog } from "./EnvironmentVariablesDialog";
import {
  EnvironmentVariablesChangeEmitter,
  grantEnvironmentVariablesSession,
  isAuthorizedForEnvironmentVariables,
  revokeEnvironmentVariablesSession,
} from "./EnvironmentVariablesSession";
import { openFileDirectory } from "./FileSystemAPI";
import { showGalleryDialog } from "./GalleryDialog";
import { showInvitesDialog } from "./InvitesDialog";
import { addRecentFile, getRecentFiles } from "./LocalSettings";
import { showLoginDialog } from "./LoginDialog";
import { recentFilesAtom } from "./ParentAtoms";
import {
  getDraftCanvasPath,
  getNewCanvasName,
  getPublishedCanvasPath,
} from "./ParentUtils";
import { showSettingsDialog } from "./SettingsDialog";

let pendingCanvasData: CanvasData | undefined;
let pendingLayoutId: string | undefined;
let pendingOptions: SetCanvasOptions | undefined;
function pendingSetCanvas(
  canvasData: CanvasData,
  layoutId: string | undefined,
  options: SetCanvasOptions
) {
  pendingCanvasData = canvasData;
  pendingLayoutId = layoutId;
  pendingOptions = options;
}
function pendingSetLayoutId(layoutId: string | undefined) {
  pendingLayoutId = layoutId;
}
export const SandboxFrameAPI: EventEmitter & {
  setCanvas: (
    canvas: CanvasData,
    layoutId: string | undefined,
    options: SetCanvasOptions
  ) => void;
  setLayoutId: (layoutId: string | undefined) => void;
} = Object.assign(new EventEmitter(), {
  setCanvas: pendingSetCanvas,
  setLayoutId: pendingSetLayoutId,
});

declare global {
  var __IS_SANDBOX: boolean | undefined;
  var __DID_SANDBOX_INIT: boolean;
}

export const SandboxMousedownEmitter = new EventEmitter();
export function useClosePopperParent(
  setPopperVisibility: React.Dispatch<React.SetStateAction<boolean>>
) {
  useEffect(() => {
    function onSandboxMouseDown() {
      setPopperVisibility(false);
    }
    SandboxMousedownEmitter.addListener("mousedown", onSandboxMouseDown);
    return () => {
      SandboxMousedownEmitter.removeListener("mousedown", onSandboxMouseDown);
    };
  }, []);
}

let navigate: (pathname: string) => void = () => {};
export function setNavigate(fn: (pathname: string) => void) {
  navigate = fn;
}

export function initializeParentFrame() {
  const iframe = document.getElementById("sandbox-iframe") as HTMLIFrameElement;
  if (iframe.src.indexOf(SANDBOX_ORIGIN) === -1) {
    window.__DID_SANDBOX_INIT = false;
    iframe.src = SANDBOX_ORIGIN;
  }
  let uninitLastSandbox: (() => void) | undefined = undefined;
  function initSandbox() {
    if (uninitLastSandbox !== undefined) {
      uninitLastSandbox();
    }
    iframe.contentWindow!.postMessage(
      {
        type: "SET_DEVICE_ID",
        deviceId: getDeviceId(),
      },
      SANDBOX_ORIGIN
    );
    SandboxFrameAPI.setCanvas = (
      canvasData: CanvasData,
      layoutId: string | undefined,
      options: SetCanvasOptions
    ) => {
      iframe.contentWindow!.postMessage(
        {
          type: "SET_CANVAS",
          canvasData,
          layoutId,
          options,
        },
        SANDBOX_ORIGIN
      );
    };
    SandboxFrameAPI.setLayoutId = (
      layoutId: string | undefined,
      focusPaneId?: string | undefined
    ) => {
      iframe.contentWindow!.postMessage(
        {
          type: "SET_LAYOUT_ID",
          layoutId,
          focusPaneId,
        },
        SANDBOX_ORIGIN
      );
    };
    if (pendingCanvasData !== undefined) {
      SandboxFrameAPI.setCanvas(
        pendingCanvasData,
        pendingLayoutId,
        pendingOptions ?? {}
      );
    }
    function isFocusOnBody() {
      if (document.activeElement === document.body) {
        return true;
      }
      // handle when focus is on @reach/router div wrapper
      let iter = document.activeElement?.parentElement;
      if (iter?.getAttribute("id") === "app") {
        return true;
      }
      iter = iter?.parentElement;
      return iter?.getAttribute("id") === "app";
    }
    function onKeyDown(e: KeyboardEvent) {
      const isModifierKeyDownValue = isModifierKeyDown(e.metaKey, e.ctrlKey);
      if (e.code === "KeyS" && isModifierKeyDownValue) {
        e.preventDefault();
      }
      if (isFocusOnBody() && !e.repeat) {
        iframe.contentWindow?.postMessage(
          {
            type: "BODY_KEYDOWN",
            code: e.code,
            key: e.key,
            altKey: e.altKey,
            shiftKey: e.shiftKey,
            metaKey: e.metaKey,
            ctrlKey: e.ctrlKey,
          },
          SANDBOX_ORIGIN
        );
      }
    }
    function onKeyUp(e: KeyboardEvent) {
      if (isFocusOnBody()) {
        iframe.contentWindow?.postMessage(
          { type: "BODY_KEYUP", code: e.code, key: e.key },
          SANDBOX_ORIGIN
        );
      }
    }
    function onMouseDown(e: MouseEvent) {
      iframe.contentWindow?.postMessage(
        { type: "BODY_MOUSEDOWN" },
        SANDBOX_ORIGIN
      );
    }

    window.addEventListener("keydown", onKeyDown);
    window.addEventListener("keyup", onKeyUp);
    window.addEventListener("mousedown", onMouseDown);

    function onVariableChange(variableKey: string) {
      iframe.contentWindow?.postMessage(
        { type: "ENVIRONMENT_VARIABLE_CHANGE", variableKey },
        SANDBOX_ORIGIN
      );
    }
    EnvironmentVariablesChangeEmitter.addListener("change", onVariableChange);

    iframe.focus();
    uninitLastSandbox = () => {
      window.removeEventListener("keydown", onKeyDown);
      window.removeEventListener("keyup", onKeyUp);
      window.removeEventListener("mousedown", onMouseDown);
      EnvironmentVariablesChangeEmitter.removeListener(
        "change",
        onVariableChange
      );
    };
  }
  function onMessage(message: MessageEvent) {
    switch (message.data.type) {
      case "SANDBOX_INIT": {
        initSandbox();
        break;
      }
      case "NAVIGATE_CANVAS": {
        const { value }: { value: CanvasSelection | undefined } = message.data;
        if (value === undefined) {
          navigate("/");
        } else if (value[0] === "draft") {
          navigate(getDraftCanvasPath(value[1]));
        } else if (value[0] === "example") {
          navigate(`/example/${removeUuidDashes(value[1])}`);
        } else if (value[0] === "user") {
          navigate(getPublishedCanvasPath(value[1], value[2]));
        } else if (value[0] === "tutorial") {
          navigate(`/tutorial/${value[1]}`);
        }
        break;
      }
      case "UPDATE_CANVAS": {
        const { canvas } = message.data;
        SandboxFrameAPI.emit("UPDATE_CANVAS", { canvas });
        break;
      }
      case "SHOW_CANVAS_SELECTOR": {
        SandboxFrameAPI.emit("SHOW_CANVAS_SELECTOR");
        break;
      }
      case "SHOW_LOGIN_DIALOG": {
        const { source } = message.data;
        SandboxFrameAPI.emit("SHOW_LOGIN_DIALOG", { source });
        break;
      }
      case "SHOW_SETTINGS_DIALOG": {
        SandboxFrameAPI.emit("SHOW_SETTINGS_DIALOG");
        break;
      }
      case "BODY_MOUSEDOWN": {
        SandboxMousedownEmitter.emit("mousedown");
        break;
      }
      case "SET_ZOOM": {
        document.body.style.backgroundSize = `${16 * message.data.zoom}px`;
        break;
      }
    }
  }
  if (window.__DID_SANDBOX_INIT) {
    initSandbox();
  }
  window.addEventListener("message", onMessage);
}

export function reloadSandbox() {
  pendingCanvasData = undefined;
  pendingLayoutId = undefined;
  SandboxFrameAPI.setCanvas = pendingSetCanvas;
  SandboxFrameAPI.setLayoutId = pendingSetLayoutId;
  const iframe = document.getElementById("sandbox-iframe") as HTMLIFrameElement;
  iframe.src = iframe.src;
}

export function getCanvasFileName(canvasNameParam: string) {
  if (canvasNameParam === UNSAVED_CANVAS_NAME) {
    canvasNameParam = getNewCanvasName();
  }
  const canvasName = canvasNameParam.replace(/\//g, "_");
  return canvasName.endsWith(FILE_EXTENSION)
    ? canvasName
    : `${canvasName}${FILE_EXTENSION}`;
}

export async function saveCanvasToFile(canvas: Canvas, source: string) {
  const canvasWithoutName: any = { ...canvas };
  delete canvasWithoutName.name;
  const fileCanvasHandle = await fileSave(
    new Blob(
      [
        JSON.stringify({
          canvas: canvasWithoutName,
          version: CURRENT_VERSION,
        }),
      ],
      {
        type: "application/json",
      }
    ),
    {
      fileName: getCanvasFileName(canvas.name),
      description: "save canvas",
      extensions: [FILE_EXTENSION],
    }
  );
  if (fileCanvasHandle !== null) {
    SandboxFrameAPI.emit("SAVE_CANVAS_TO_FILE", {
      fileCanvasHandle,
    });
    await addRecentFile(fileCanvasHandle);
    const recentFiles = await getRecentFiles();
    runInAction(() => {
      recentFilesAtom.value = recentFiles;
    });
  }
  logEvent("save canvas file", { source });
}

const ParentComlinkAPI = {
  getAccessToken: getBearerToken,
  publishCanvas: async (canvas: Canvas) => {
    const canvasId = await publishCanvas(canvas);
    addMyPublishedCanvasIdAndName(canvasId, canvas.name);
    navigate(getPublishedCanvasPath(userAtom.value!.username!, canvasId));
  },
  unpublishCanvas: async (canvasId: string) => {
    await unpublishCanvas(canvasId);
    removeMyPublishedCanvasIdAndName(canvasId);
    navigate("/");
  },
  handleSyncCanvasUserMismatch: (canvasId: string) => {
    navigate("/");
  },
  saveCanvasToFile,
  switchLayoutId: (layoutId: string | undefined) => {
    SandboxFrameAPI.emit("SWITCH_LAYOUT_ID", { layoutId });
  },
  updateCanvasName: (canvasId: string, name: string) => {
    SandboxFrameAPI.emit("UPDATE_CANVAS_NAME", { canvasId, name });
  },
  sendCanvasUpdate(canvas: Canvas) {
    SandboxFrameAPI.emit("UPDATE_CANVAS", { canvas });
  },
  showCanvasSelector() {
    SandboxFrameAPI.emit("SHOW_CANVAS_SELECTOR");
  },
  showGalleryDialog() {
    showGalleryDialog();
  },
  showLoginDialog(source: string) {
    showLoginDialog(source);
  },
  showSettingsDialog() {
    showSettingsDialog();
  },
  navigateCanvas(value: CanvasSelection | undefined) {
    if (value === undefined) {
      navigate("/");
    } else if (value[0] === "draft") {
      navigate(getDraftCanvasPath(value[1]));
    } else if (value[0] === "example") {
      navigate(`/example/${removeUuidDashes(value[1])}`);
    } else if (value[0] === "user") {
      navigate(getPublishedCanvasPath(value[1], value[2]));
    } else if (value[0] === "tutorial") {
      navigate(`/tutorial/${value[1]}`);
    }
  },
  showEnvironmentDialog: (initialKey?: string | undefined) => {
    showEnvironmentVariablesDialog(initialKey);
  },
  isCanvasAuthorizedForEnvironment: (): boolean => {
    return isAuthorizedForEnvironmentVariables();
  },
  grantEnvironment: () => {
    grantEnvironmentVariablesSession();
  },
  revokeEnvironment: () => {
    revokeEnvironmentVariablesSession();
  },
  getEnvironmentVariable: async (
    key: string
  ): Promise<EnvironmentVariableValue> => {
    if (!isAuthorizedForEnvironmentVariables()) {
      return ["unauthorized"];
    }
    const browserVariable = await EnvironmentVariableDB.getVariable(key);
    if (browserVariable !== undefined) {
      return ["value", browserVariable];
    }
    const userVariables = await fetchUserEnvironmentVariables();
    const userVariable = userVariables?.find(({ key: k }) => k === key);
    if (userVariable === undefined) {
      return ["missing"];
    }
    return ["value", userVariable.value];
  },
  getSocketToken: async (): Promise<string | undefined> => {
    const syncCanvasId = syncCanvasIdAtom.value;
    if (syncCanvasId === undefined) {
      return undefined;
    }
    try {
      const token = await getSocketToken(syncCanvasId);
      return token;
    } catch {
      return undefined;
    }
  },
  generateInvite: async () => {
    return generateInvite();
  },
  generateDebugVisualizerCode: async (
    paneId: string,
    paneValues: { [paneId: string]: string }
  ) => {
    const syncCanvasId = syncCanvasIdAtom.value;
    if (syncCanvasId?.[0] !== "canvas") {
      throw new Error();
    }
    return generateDebugVisualizerCode(syncCanvasId[1], paneId, paneValues);
  },
  reload() {
    window.location.reload();
  },
  copyPanesToClipboard(clipboardPanes: ClipboardPane[]) {
    navigator.clipboard.writeText(JSON.stringify(clipboardPanes));
  },
  showInvitesDialog(canvasId: string) {
    showInvitesDialog(canvasId);
  },
  focusSandboxFrame() {
    const iframe = document.getElementById(
      "sandbox-iframe"
    ) as HTMLIFrameElement;
    iframe.focus();
  },
  openFileDirectory: () => openFileDirectory(),
};
export type ParentComlinkAPIType = typeof ParentComlinkAPI;

export async function exposeParentComlink() {
  const iframe = document.getElementById("sandbox-iframe") as HTMLIFrameElement;
  // Is this needed? When added, misses early calls from sandbox
  // await new Promise((resolve) => (iframe.onload = resolve));
  Comlink.expose(
    ParentComlinkAPI,
    Comlink.windowEndpoint(iframe.contentWindow!)
  );
}
