import { DBSchema, IDBPDatabase, openDB } from "idb";

interface EnvironmentVariableDBSchema extends DBSchema {
  variables: {
    value: {
      key: string;
      value: string;
      createdTime: number;
    };
    key: string;
    indexes: {
      createdTime: "createdTime";
    };
  };
  // TODO: delete this no longer used object store
  authorizedCanvases: {
    value: {
      canvasId: string;
      createdTime: number;
    };
    key: string;
    indexes: {
      createdTime: "createdTime";
    };
  };
}

let environmentVariableDBPromise:
  | Promise<IDBPDatabase<EnvironmentVariableDBSchema>>
  | undefined;
export function getEnvironmentVariableDB() {
  if (environmentVariableDBPromise === undefined) {
    environmentVariableDBPromise = openDB("environment", 1, {
      upgrade(db, oldVersion, newVersion, transaction) {
        const variableStore = db.createObjectStore("variables", {
          keyPath: "key",
        });
        variableStore.createIndex("createdTime", "createdTime", {
          unique: false,
        });
        const authorizedCanvasesStore = db.createObjectStore(
          "authorizedCanvases",
          {
            keyPath: "canvasId",
          }
        );
        authorizedCanvasesStore.createIndex("createdTime", "createdTime", {
          unique: false,
        });
      },
    });
  }
  return environmentVariableDBPromise;
}

export const EnvironmentVariableDB = {
  getVariable: async (key: string): Promise<string | undefined> => {
    const db = await getEnvironmentVariableDB();
    const row = await db.get("variables", key);
    if (row === undefined) {
      return undefined;
    }
    return row.value;
  },
  getAllVariables: async (): Promise<[key: string, value: string][]> => {
    const db = await getEnvironmentVariableDB();
    const variablesRaw = await db.getAllFromIndex("variables", "createdTime");
    return variablesRaw.map(({ key, value }) => [key, value]);
  },
  putVariable: async (key: string, value: string): Promise<void> => {
    const db = await getEnvironmentVariableDB();
    const tx = db.transaction("variables", "readwrite");
    const row = await tx.store.get(key);
    const createdTime = row?.createdTime ?? Date.now();
    await tx.store.put({ key, value, createdTime });
    await tx.done;
  },
  deleteVariable: async (key: string): Promise<void> => {
    const db = await getEnvironmentVariableDB();
    await db.delete("variables", key);
  },
};
