import classNames from "classnames";
import { computed } from "mobx";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { FILE_EXTENSION, UNSAVED_CANVAS_NAME } from "../Constants";
import { uiStateMobx, userAtom } from "../SharedIframeState";
import { Canvas } from "../Types";
import { FeatherIcon } from "../ui/FeatherIcon";
import {
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuPortal,
  DropdownMenuRoot,
  DropdownMenuTrigger,
} from "../ui/Menu";
import { Tooltip } from "../ui/Tooltip";
import { assert, modifierKey } from "../Utils";
import { publishCanvas } from "./API";
import { addMyPublishedCanvasIdAndName } from "./AppState";
import {
  EnvironmentVariablesChangeEmitter,
  hasEnvironmentVariablesSessionPermission,
  revokeEnvironmentVariablesSession,
} from "./EnvironmentVariablesSession";
import { saveCanvasToFile, useClosePopperParent } from "./FrameParentAPI";
import { getPublishedCanvasPath } from "./ParentUtils";

function FileSaveIndicator({
  status,
}: {
  status: "saved" | "saving" | ["error", string];
}) {
  return (
    <Tooltip
      content={
        status === "saving"
          ? "canvas is saving\nto file..."
          : status === "saved"
          ? "canvas is saved\nto file"
          : status[1]
      }
      delayDuration={100}
    >
      <FeatherIcon
        icon="file"
        size="sm"
        className={classNames(
          "transition",
          status === "saved" || status === "saving"
            ? "text-neutral-700"
            : "text-red-500",
          {
            "opacity-80": status === "saving",
          }
        )}
      />
    </Tooltip>
  );
}

export const CanvasNameUnitParent = observer(
  ({
    canvasId,
    hasUnsavedChanges,
    value,
    localCanvasRef,
    fileSaveStatus,
    onSync,
  }: {
    canvasId: string;
    hasUnsavedChanges: boolean;
    value:
      | [type: "file", fileName: string, isAutosaving: boolean]
      | [type: "published", username: string, canvasName: string]
      | [type: "example", canvasName: string];
    localCanvasRef: React.MutableRefObject<Canvas | undefined>;
    fileSaveStatus?: "saved" | "saving" | ["error", string];
    onSync: () => void;
  }) => {
    const user = userAtom.value;
    const history = useHistory();

    const [showSettingsMenu, setShowSettingsMenu] = useState(false);
    useClosePopperParent(setShowSettingsMenu);
    const [isAuthorizedForEnvironment, setIsAuthorizedForEnvironment] =
      useState(false);
    useEffect(() => {
      async function go() {
        const isAuthorizedForEnvironment =
          await hasEnvironmentVariablesSessionPermission();
        setIsAuthorizedForEnvironment(isAuthorizedForEnvironment);
      }
      go();
      function onVariableChange(variableKey: string) {
        if (variableKey === "*") {
          go();
        }
      }
      EnvironmentVariablesChangeEmitter.addListener("change", onVariableChange);
      return () => {
        EnvironmentVariablesChangeEmitter.removeListener(
          "change",
          onVariableChange
        );
      };
    }, []);

    return (
      <div
        className={classNames(
          "fixed top-0 left-32 md:left-36 right-0 h-10 md:h-12 flex items-center md:justify-center pointer-events-none",
          {
            hidden: computed(
              () => uiStateMobx.maximizedPaneId !== undefined
            ).get(),
          }
        )}
      >
        <div className="flex items-center pointer-events-auto md:text-sm">
          {value[0] === "file" ? (
            <>
              {value[2] ? <FileSaveIndicator status={fileSaveStatus!} /> : null}
              <div className="flex px-2">
                {value[1]}
                {hasUnsavedChanges && value[1] !== UNSAVED_CANVAS_NAME ? (
                  <span className="ml-1 text-neutral-400">(unsaved)</span>
                ) : null}
              </div>
            </>
          ) : value[0] === "example" ? (
            <div className="px-2">{value[1]}</div>
          ) : (
            <div className="px-2">
              @{value[1]}
              <span className="inline-block mx-1 text-neutral-500">/</span>
              {value[2]}
              {hasUnsavedChanges ? (
                <span className="ml-1 text-neutral-400">(unsaved)</span>
              ) : null}
            </div>
          )}
          <DropdownMenuRoot
            open={showSettingsMenu}
            onOpenChange={(open) => {
              setShowSettingsMenu(open);
            }}
          >
            <DropdownMenuTrigger asChild={true}>
              <button className="flex text-neutral-500 p-1 -ml-1 hover:text-neutral-700 transition-colors">
                <FeatherIcon icon="more-horizontal" size="sm" />
              </button>
            </DropdownMenuTrigger>
            <DropdownMenuPortal>
              <DropdownMenuContent sideOffset={8} align="end">
                {localCanvasRef !== undefined ? (
                  user !== undefined ? (
                    <DropdownMenuItem
                      onClick={async () => {
                        assert(localCanvasRef.current !== undefined);
                        const canvasName = localCanvasRef.current.name;
                        const canvas = {
                          ...localCanvasRef.current,
                          name: canvasName.endsWith(FILE_EXTENSION)
                            ? canvasName.substring(
                                0,
                                canvasName.length - FILE_EXTENSION.length
                              )
                            : canvasName,
                        };
                        const canvasId = await publishCanvas(canvas);
                        onSync();
                        addMyPublishedCanvasIdAndName(canvasId, canvas.name);
                        history.push(
                          getPublishedCanvasPath(
                            userAtom.value!.username!,
                            canvasId
                          )
                        );
                      }}
                    >
                      {value[0] === "file" ? "sync" : "fork"} to user canvas
                    </DropdownMenuItem>
                  ) : (
                    <Tooltip content={"log in for\nuser canvas"} side="right">
                      <DropdownMenuItem
                        onClick={() => {}}
                        className="opacity-50"
                      >
                        {value[0] === "file" ? "sync" : "fork"} to user canvas
                      </DropdownMenuItem>
                    </Tooltip>
                  )
                ) : null}
                <DropdownMenuItem
                  onClick={async () => {
                    await saveCanvasToFile(localCanvasRef.current!, "header");
                    setShowSettingsMenu(false);
                  }}
                >
                  <div className="grow">
                    {value[0] === "file" ? "save as..." : "save to file"}
                  </div>
                  <div className="text-neutral-400 ml-2">
                    {modifierKey === "Meta" ? "⌘" : "Ctrl-"}S
                  </div>
                </DropdownMenuItem>
                {isAuthorizedForEnvironment ? (
                  <DropdownMenuItem
                    onClick={async () => {
                      revokeEnvironmentVariablesSession();
                      setShowSettingsMenu(false);
                    }}
                  >
                    revoke environment access
                  </DropdownMenuItem>
                ) : null}
              </DropdownMenuContent>
            </DropdownMenuPortal>
          </DropdownMenuRoot>
        </div>
      </div>
    );
  }
);
