import * as DropdownMenuPrimitive from "@radix-ui/react-dropdown-menu";
import type * as Radix from "@radix-ui/react-primitive";
import classNames from "classnames";
import React, { forwardRef } from "react";

const MenuClassName =
  "bg-neutral-0 rounded-md shadow-lg flex flex-col overflow-hidden min-w-36 py-1.5 popover-scale-in no-focus-visible select-none";
export const HeaderMenuClassName =
  "relative border-t-2 flex flex-col border-secondary-100 bg-neutral-0 py-1.5 rounded-b shadow-xl min-w-36 popover-scale-in";
export const MenuItemClassName =
  "group w-full h-8 flex font-medium items-center justify-between text-left px-3 no-focus-visible cursor-default aria-disabled:text-opacity-50";

export const DropdownMenuRoot = DropdownMenuPrimitive.Root;
export const DropdownMenuPortal = DropdownMenuPrimitive.Portal;
export const DropdownMenuTrigger = DropdownMenuPrimitive.Trigger;

export const DropdownMenuContent = forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.Content>,
  Radix.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Content>
>((props, forwardedRef) => {
  const { className, ...itemProps } = props;
  return (
    <DropdownMenuPrimitive.Content
      {...itemProps}
      className={classNames(MenuClassName, className)}
      ref={forwardedRef}
    />
  );
});

export const DropdownMenuItem = forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.Item>,
  Radix.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Item> & {
    color?: "red" | "light-red";
  }
>((props, forwardedRef) => {
  const { className, color, ...itemProps } = props;
  return (
    <DropdownMenuPrimitive.Item
      {...itemProps}
      className={classNames(
        MenuItemClassName,
        color === "red"
          ? "text-red-500 focus:bg-red-500 focus:text-neutral-0"
          : color === "light-red"
          ? "text-red-400 focus:bg-red-500 focus:text-neutral-0"
          : "text-neutral-600 dark:focus:text-neutral-700 focus:bg-neutral-100 active:bg-neutral-150",
        className
      )}
      ref={forwardedRef}
    />
  );
});
