import { v4 } from "uuid";
import { removeUuidDashes } from "../../../shared/Utils";
import { TUTORIAL_CANVAS_ID } from "../Constants";
import { CanvasSelection } from "../Types";

export function getNewCanvasName(): string {
  const date = new Date();
  return `canvas ${date.getFullYear()}/${`${date.getMonth() + 1}`.padStart(
    2,
    "0"
  )}/${`${date.getDate()}`.padStart(2, "0")}`;
}

export function addUuidDashes(uuid: string) {
  const matches = /^(.{8})(.{4})(.{4})(.{4})(.{12})$/.exec(uuid);
  if (matches === null) {
    throw new Error(`invalid UUID: ${uuid}`);
  }
  return matches.slice(1).join("-");
}

export function getFileCanvasPath(layoutId?: string | undefined) {
  return `/${layoutId !== undefined ? `?layout=${layoutId}` : ""}`;
}

export function getDraftCanvasPath(
  canvasId: string,
  layoutId?: string | undefined
) {
  return `/d/${removeUuidDashes(canvasId)}${
    layoutId !== undefined ? `?layout=${layoutId}` : ""
  }`;
}

export function getPublishedCanvasPath(
  username: string,
  canvasId: string,
  layoutId?: string | undefined
) {
  return `/@${username}/${removeUuidDashes(canvasId)}${
    layoutId !== undefined ? `?layout=${layoutId}` : ""
  }`;
}

export function getInvitePath(
  inviteCode: string,
  layoutId?: string | undefined
) {
  return `/invite/${inviteCode}${
    layoutId !== undefined ? `?layout=${layoutId}` : ""
  }`;
}

export function createNewCanvasId(): string {
  return v4();
}

export function getCanvasId(canvasSelection: CanvasSelection): string {
  if (canvasSelection[0] === "tutorial") {
    return TUTORIAL_CANVAS_ID;
  }
  return canvasSelection[0] === "user"
    ? canvasSelection[2]
    : canvasSelection[1];
}

export function updateDocumentTitle(canvasName?: string | undefined) {
  document.title = `${canvasName !== undefined ? `${canvasName} – ` : ""}natto`;
}

export function formatDuration(seconds: number) {
  if (seconds <= 3600) {
    return `${Math.floor(seconds / 60)} minutes`;
  }
  if (seconds <= 24 * 3600) {
    return `${Math.floor(seconds / 3600)} hours`;
  }
  return `${Math.floor(seconds / 3600 / 24)} days`;
}
