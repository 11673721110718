import { action, runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { logEvent } from "../Amplitude";
import { atom } from "../atom";
import { appSettingsMobx, userAtom } from "../SharedIframeState";
import {
  DialogContent,
  DialogHeader,
  DialogOverlay,
  DialogPortal,
  DialogRoot,
} from "../ui/Dialog";
import { signOut } from "./API";
import { showEnvironmentVariablesDialog } from "./EnvironmentVariablesDialog";

const SettingsDialogContent = observer(() => {
  useEffect(() => {
    logEvent("view settings modal");
  }, []);

  const user = userAtom.value;

  return (
    <div className="overflow-auto pb-8">
      <DialogHeader>settings</DialogHeader>
      <div className="px-6 pt-6">
        <div>
          <div>
            Things are quickly changing while natto is in preview. I'll try to
            avoid breakages but this isn't guaranteed for now. If something
            isn't working for you, please reach out to{" "}
            <a
              href="https://twitter.com/_paulshen"
              target="_blank"
              rel="noreferrer"
              className="underline"
            >
              @_paulshen
            </a>
          </div>
        </div>
        <div className="mt-8">
          <div className="mb-2">
            Manage environment variables and secrets to use in canvases.
          </div>
          <div>
            <button
              onClick={action(() => {
                showEnvironmentVariablesDialog();
                showSettingsDialogAtom.value = false;
              })}
              className="button"
            >
              environment variables
            </button>
          </div>
        </div>
        <div className="mt-6">
          <div className="mb-2">Half-baked experiments</div>
          <div>
            <button
              onClick={action(() => {
                appSettingsMobx.vimEnabled = !appSettingsMobx.vimEnabled;
              })}
              className="button"
            >
              {appSettingsMobx.vimEnabled ? "disable" : "enable"} vim mode
            </button>
          </div>
        </div>
        {user !== undefined ? (
          <div className="mt-6">
            <div className="mb-2">You are logged in as {user.username}</div>
            <button
              onClick={action(() => {
                signOut();
                logEvent("click settings logout button");
                showSettingsDialogAtom.value = false;
              })}
              className="button"
            >
              log out
            </button>
          </div>
        ) : null}
      </div>
    </div>
  );
});

const showSettingsDialogAtom = atom(false);
export function showSettingsDialog() {
  runInAction(() => {
    showSettingsDialogAtom.value = true;
  });
}

export const SettingsDialogController = observer(() => {
  return (
    <DialogRoot
      open={showSettingsDialogAtom.value}
      onOpenChange={action((open) => {
        showSettingsDialogAtom.value = open;
      })}
    >
      <DialogPortal>
        <DialogOverlay />
        <DialogContent className="w-[90vw] max-w-md">
          <SettingsDialogContent />
        </DialogContent>
      </DialogPortal>
    </DialogRoot>
  );
});
