import { Side } from "@radix-ui/react-popper";
import type * as Radix from "@radix-ui/react-primitive";
import * as TooltipPrimitive from "@radix-ui/react-tooltip";
import React from "react";

export const TooltipProvider = TooltipPrimitive.Provider;

export const TooltipClassName =
  "border bg-neutral-700 text-neutral-50 border-neutral-700 dark:bg-neutral-100 dark:text-neutral-700 dark:border-neutral-150 font-medium rounded px-3 py-1.5 max-w-[16rem] text-center whitespace-pre";

export function Tooltip({
  children,
  content,
  side = "top",
  open,
  defaultOpen,
  onOpenChange,
  delayDuration,
  disabled = false,
  ...props
}: Radix.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content> & {
  children: React.ReactNode;
  content: React.ReactNode;
  side?: Side;
  open?: boolean;
  defaultOpen?: boolean;
  delayDuration?: number;
  onOpenChange?: (open: boolean) => void;
  disabled?: boolean;
}) {
  return (
    <TooltipPrimitive.Root
      open={open}
      defaultOpen={defaultOpen}
      onOpenChange={onOpenChange}
      delayDuration={delayDuration}
    >
      <TooltipPrimitive.Trigger asChild>{children}</TooltipPrimitive.Trigger>
      {!disabled ? (
        <TooltipPrimitive.Portal>
          <TooltipPrimitive.Content
            side={side}
            sideOffset={4}
            align="center"
            className={TooltipClassName}
            {...props}
          >
            {content}
          </TooltipPrimitive.Content>
        </TooltipPrimitive.Portal>
      ) : null}
    </TooltipPrimitive.Root>
  );
}
