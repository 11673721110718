import copy from "copy-to-clipboard";
import { action, runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { atom } from "../atom";
import { Invite } from "../Types";
import {
  DialogContent,
  DialogHeader,
  DialogOverlay,
  DialogPortal,
  DialogRoot,
} from "../ui/Dialog";
import { FeatherIcon } from "../ui/FeatherIcon";
import {
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuRoot,
  DropdownMenuTrigger,
} from "../ui/Menu";
import { Tooltip } from "../ui/Tooltip";
import { fetchInvites, generateInvite, revokeInvite } from "./API";
import { formatDuration, getInvitePath } from "./ParentUtils";

const invitesAtom = atom<Invite[] | undefined>(undefined);

function CopyLink({ url }: { url: string }) {
  const [showCopied, setShowCopied] = useState(false);
  const timeoutRef = useRef<number | undefined>(undefined);
  return (
    <div className="flex gap-1">
      <Tooltip content={url}>
        <button
          onClick={() => {
            copy(url);
            if (timeoutRef.current !== undefined) {
              clearTimeout(timeoutRef.current);
            }
            setShowCopied(true);
            timeoutRef.current = setTimeout(() => {
              timeoutRef.current = undefined;
              setShowCopied(false);
            }, 1500);
          }}
          className="flex p-0.5 text-neutral-300 hover:text-neutral-500 transition"
        >
          <FeatherIcon icon="link" size="sm" />
        </button>
      </Tooltip>
      {showCopied ? <div className="text-primary-400">copied!</div> : null}
    </div>
  );
}

function SettingsButton({
  canvasId,
  inviteCode,
}: {
  canvasId: string;
  inviteCode: string;
}) {
  return (
    <DropdownMenuRoot>
      <DropdownMenuTrigger asChild={true}>
        <button className="flex p-0.5 text-neutral-200 radix-open:text-neutral-400 hover:text-neutral-400 transition">
          <FeatherIcon icon="more-horizontal" size="sm" />
        </button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuItem
          onClick={async () => {
            await revokeInvite(canvasId, inviteCode);
            runInAction(() => {
              invitesAtom.value = invitesAtom.value?.filter(
                (i) => i.code !== inviteCode
              );
            });
          }}
          color="red"
        >
          revoke invite
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenuRoot>
  );
}

function CreateInviteButton({ canvasId }: { canvasId: string }) {
  const [isCreating, setIsCreating] = useState(false);
  return (
    <button
      onClick={async () => {
        setIsCreating(true);
        const invite = await generateInvite(canvasId);
        runInAction(() => {
          invitesAtom.value = [...(invitesAtom.value ?? []), invite];
        });
        setIsCreating(false);
      }}
      className="button"
      disabled={isCreating}
    >
      create invite
    </button>
  );
}

const InvitesDialogContent = observer(({ canvasId }: { canvasId: string }) => {
  useEffect(() => {
    async function go() {
      const invites = await fetchInvites(canvasId);
      runInAction(() => {
        invitesAtom.value = invites;
      });
    }
    go();
  }, []);
  const [, forceUpdate] = useState({});
  useEffect(() => {
    const interval = setInterval(() => {
      forceUpdate({});
    }, 60000);
    return () => {
      clearInterval(interval);
    };
  }, []);
  const now = Date.now();
  const invites = invitesAtom.value?.filter((invite) => invite.expiresAt > now);
  const sortedInvites = useMemo(
    () =>
      invites !== undefined
        ? [...invites].sort((a, b) => a.expiresAt - b.expiresAt)
        : undefined,
    [invites]
  );
  return (
    <div>
      <DialogHeader>
        invites <span className="text-secondary-300">beta</span>
      </DialogHeader>
      <div className="px-6 py-4">
        <div className="mb-4">
          Invite others to collaborate with you on your canvas. They will be
          able to edit and delete any pane so please use caution!
        </div>
        {sortedInvites !== undefined && sortedInvites.length > 0 ? (
          <div className="mb-4">
            <div className="flex font-mono-natto text-2xs uppercase text-neutral-400 mb-1">
              <div className="w-1/3">code</div>
              <div className="w-1/3">copy link</div>
              <div className="w-1/3">expires in</div>
              <div className="w-4" />
            </div>
            {sortedInvites.map((invite) => (
              <div
                className="flex py-0.5 hover:bg-neutral-100 -mx-2 px-2 rounded-sm transition"
                key={invite.code}
              >
                <div className="w-1/3 font-mono-natto">{invite.code}</div>
                <div className="w-1/3">
                  <CopyLink
                    url={`${location.protocol}//${location.host}${getInvitePath(
                      invite.code
                    )}`}
                  />
                </div>
                <div className="w-1/3">
                  <span title={new Date(invite.expiresAt).toLocaleString()}>
                    {formatDuration((invite.expiresAt - now) / 1000)}
                  </span>
                </div>
                <div className="w-4">
                  <SettingsButton
                    canvasId={canvasId}
                    inviteCode={invite.code}
                  />
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="mb-4 text-neutral-400">
            This canvas has no active invites.
          </div>
        )}
        <div>
          <CreateInviteButton canvasId={canvasId} />
        </div>
      </div>
    </div>
  );
});

const invitesDialogCanvasIdAtom = atom<string | undefined>(undefined);
export function showInvitesDialog(canvasId: string) {
  runInAction(() => {
    invitesDialogCanvasIdAtom.value = canvasId;
  });
}

export const InvitesDialogController = observer(() => {
  return (
    <DialogRoot
      open={invitesDialogCanvasIdAtom.value !== undefined}
      onOpenChange={action((open) => {
        if (!open) {
          invitesDialogCanvasIdAtom.value = undefined;
        }
      })}
    >
      <DialogPortal>
        <DialogOverlay />
        <DialogContent className="w-[90vw] max-w-md">
          <InvitesDialogContent canvasId={invitesDialogCanvasIdAtom.value!} />
        </DialogContent>
      </DialogPortal>
    </DialogRoot>
  );
});
