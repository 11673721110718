import { runInAction } from "mobx";
import { atom } from "../atom";
import {
  NEW_LOCAL_QUERY_PARAM,
  UNSAVED_CANVAS_NAME,
  WELCOME_CANVAS_ID,
} from "../Constants";
import { Canvas, PaneType } from "../Types";
import { isMacPlatform } from "../Utils";
import { sessionAtom } from "./API";
import { createNewCanvasId } from "./ParentUtils";
import WelcomeCanvas from "./WelcomeCanvas.json";

let nextFileCanvas: Canvas | undefined;
export function getNextFileCanvas() {
  return nextFileCanvas;
}
export function setNextFileCanvasNew() {
  nextFileCanvas = {
    id: createNewCanvasId(),
    name: UNSAVED_CANVAS_NAME,
    panes: [],
    layouts: [],
    createdTime: Date.now(),
  };
  return nextFileCanvas.id;
}
export function setNextFileCanvasWelcome() {
  nextFileCanvas = {
    // @ts-ignore
    ...(WelcomeCanvas as Canvas),
    id: WELCOME_CANVAS_ID,
  };
  if (!isMacPlatform) {
    nextFileCanvas.panes = nextFileCanvas.panes.map((p) => {
      if (p.type !== PaneType.InputText || !p.text.includes("⌘")) {
        return p;
      }
      return {
        ...p,
        text: p.text.replace(/⌘/g, "Ctrl").replace(/⌥/g, "Alt"),
      };
    });
  }
  return nextFileCanvas.id;
}
export function setNextFileCanvas(canvas: Canvas) {
  nextFileCanvas = canvas;
}

export const fileCanvasIdAtom = atom<string | undefined>(undefined);

function shouldShowWelcome(): boolean {
  if (localStorage.getItem("has_seen_welcome") !== null) {
    return false;
  }
  if (sessionAtom.value !== undefined) {
    return false;
  }
  return true;
}

export function initPath() {
  const searchParams = new URLSearchParams(location.search);
  if (searchParams.get(NEW_LOCAL_QUERY_PARAM) !== null) {
    const canvasId = setNextFileCanvasNew();
    runInAction(() => {
      fileCanvasIdAtom.value = canvasId;
    });
    searchParams.delete(NEW_LOCAL_QUERY_PARAM);
    const query = searchParams.toString();
    history.replaceState(
      {},
      "",
      `${location.pathname}${query !== "" ? `?${query}` : ""}`
    );
  } else if (window.location.pathname === "/" && shouldShowWelcome()) {
    const canvasId = setNextFileCanvasWelcome();
    runInAction(() => {
      fileCanvasIdAtom.value = canvasId;
    });
  }
}
