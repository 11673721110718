import classNames from "classnames";
import { action, computed, observable, reaction, runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import React from "react";
import Imgix from "react-imgix";
import { useHistory } from "react-router-dom";
import {
  DialogCloseButton,
  DialogContent,
  DialogOverlay,
  DialogPortal,
  DialogRoot,
} from "../ui/Dialog";
import { FeatherIcon } from "../ui/FeatherIcon";
import { getPublishedCanvasPath } from "./ParentUtils";

type GalleryItem = {
  id: string;
  slug: string;
  title: string;
  description: string;
  shortDescription?: string;
  tags: string[];
};

const GALLERY_ITEMS: GalleryItem[] = [
  {
    id: "086b7553564c404aa5edc08debf09f2e",
    slug: "gpt3",
    title: "GPT-3 Playground",
    description:
      "Natto is a great interactive playground for GPT-3. Use JavaScript as part of prompt creation or processing the generated text.",
    shortDescription: "Interactive playground for GPT-3",
    tags: ["npm"],
  },
  // {
  //   id: "2431f8d45393450381e1d64a6c86f77c",
  //   slug: "notion",
  //   title: "Notion",
  //   description:
  //     "Use the Notion API in an interactive playground. The Notion API does not support CORS so we need a proxy.",
  //   shortDescription: "Interact with the Notion API",
  //   tags: ["npm"],
  // },
  // {
  //   id: "7e9952e3439d4cb4b57efef93466da77",
  //   slug: "airtable",
  //   title: "Airtable",
  //   description: "Interact with the Airtable API",
  //   tags: ["npm"],
  // },
  {
    id: "a5dcb6452d5f4ac7b04e8ddab215921f",
    slug: "filesystem",
    title: "Filesystem",
    description:
      "Read and write files from your local filesystem using browser APIs.",
    tags: ["browser"],
  },
  {
    id: "2103ab9d460d443a912ba08e9bab718c",
    slug: "sf-movies",
    title: "SF Movie Locations",
    description:
      "The city of San Francisco provides an HTTP API to get the locations of movies filmed in the city. This canvas combines an HTTP template pane with an interactive table to browse films and their locations.",
    shortDescription: "Browse movie locations in San Francisco",
    tags: ["api", "http"],
  },
  {
    id: "ed6bb9ccd19c4f8b8c3417e408b89ab7",
    slug: "p5js",
    title: "p5.js",
    description:
      "Make creative sketches with p5js. This canvas is the Brownian Motion sketch from the p5js example pages, showing random movement as a continuous line.",
    shortDescription: "Make creative sketches with p5js",
    tags: ["creative", "npm"],
  },
  {
    id: "10d63c2653e44df9a9f71caf525dc958",
    slug: "logo-turtle",
    title: "Logo Turtle",
    description:
      "Logo is an educational programming language with commands for movement and drawing line graphics. This canvas implements a simple Logo environment.",
    shortDescription: "A Logo programming environment",
    tags: [],
  },
  {
    id: "3743087bb7c743d7938d7ffdcdbccf1e",
    slug: "sqlite",
    title: "SQLite",
    description:
      "Load and query a SQLite database. This example demonstrates using a WASM library inside natto.",
    shortDescription: "Load and query a SQLite database",
    tags: ["wasm"],
  },
  // {
  //   id: "de62259444864d818bb94e5a71862915",
  //   slug: "babel",
  //   title: "Babel",
  //   description: "Use natto as a playground to build Babel plugins.",
  //   shortDescription: "Interactively write a Babel plugin",
  //   tags: ["playground"],
  // },
  {
    id: "f33b7f5924634fb687a90d85ed03f289",
    slug: "artic",
    title: "Art Browser",
    description:
      "The Art Institute of Chicago provides a HTTP API to browse their collection. Click the rows in the table pane to see the artwork rendered with an HTML image tag.",
    shortDescription: "Browse artworks from the Art Institute of Chicago.",
    tags: ["http", "api"],
  },
  // {
  //   id: "tbdpokemon",
  //   slug: "pokemon",
  //   title: "Pokemon",
  //   description: "Build a Pokedex using the Pokedex API.",
  //   shortDescription: "Build a Pokedex using the Pokedex API",
  //   tags: ["http", "api"],
  // },
  // {
  //   id: "f17e20cf5d3a4597b3419429a231fe28",
  //   slug: "git",
  //   title: "Git browser",
  //   description:
  //     "Clone and interact with a git repo. Isomorphic-git is a pure JavaScript implementation of git that allows us to work with git repos in the browser!",
  //   shortDescription: "Clone and interact with a git repo",
  //   tags: ["npm"],
  // },
  {
    id: "e88779123ff64547a78a122314323cf4",
    slug: "wordle",
    title: "Wordle",
    description:
      "The word game Wordle built with React. React components are composed together like Lego blocks.",
    shortDescription: "A Wordle clone built with React",
    tags: ["react"],
  },
  {
    id: "510845d420d94cab9fd3afe7a6b4a15f",
    slug: "elementary-audio",
    title: "Elementary audio",
    description:
      "The Elementary.audio library makes it easy to write high performance audio in the browser using JavaScript.",
    shortDescription: "Generate music in the browser",
    tags: ["creative", "npm"],
  },
  {
    id: "215716c502f046d385ba179be2323aa2",
    slug: "fishdraw",
    title: "fishdraw",
    description:
      "Procedurally generated fish drawings by Lingdong. The single file JavaScript code is exploded on a natto canvas. https://github.com/LingDong-/fishdraw.",
    shortDescription: "procedurally generated fish drawings by Lingdong",
    tags: ["creative"],
  },
];

function GalleryImage({ item, sizes }: { item: GalleryItem; sizes: string }) {
  if (import.meta.env.DEV) {
    return <img src={`/gallery/${item.slug}.png`} />;
  }
  return (
    <Imgix
      src={`https://natto.imgix.net/gallery/${item.slug}.png`}
      sizes={sizes}
    />
  );
}

export const showGalleryDialogBox = observable.box(false);
const selectedGalleryItemIdBox = observable.box<string | undefined>(undefined);
reaction(
  () => showGalleryDialogBox.get(),
  action((showGalleryDialog) => {
    if (!showGalleryDialog) {
      selectedGalleryItemIdBox.set(undefined);
    }
  })
);

export function showGalleryDialog(slug?: string | undefined) {
  runInAction(() => {
    showGalleryDialogBox.set(true);
    const itemId =
      slug !== undefined
        ? GALLERY_ITEMS.find((item) => item.slug === slug)?.id
        : undefined;
    selectedGalleryItemIdBox.set(itemId);
  });
}

function GalleryDialogHome() {
  return (
    <div className="px-4 pb-4 xl:px-8 xl:pb-8">
      <div className="pt-8 mb-8">
        <div className="text-sm text-neutral-500">
          Browse natto canvases to use and for inspiration.
        </div>
      </div>
      <div className="grid grid-cols-2 lg:grid-cols-3 items-start gap-4 xl:gap-8">
        {GALLERY_ITEMS.map((galleryItem) => (
          <button
            onClick={action(() => {
              selectedGalleryItemIdBox.set(galleryItem.id);
            })}
            className="text-left group"
            key={galleryItem.id}
          >
            <div className="overflow-hidden rounded mb-2 group-hover:opacity-80 transition">
              <div className="bg-neutral-200 aspect-[1.6]">
                <GalleryImage
                  item={galleryItem}
                  sizes="(min-width: 1024px) 33vw, 50vw"
                />
              </div>
            </div>
            <div className="text-base font-medium group-hover:text-primary-500 transition mb-1">
              {galleryItem.title}
            </div>
            <div className="text-neutral-500">
              {galleryItem.shortDescription ?? galleryItem.description}
            </div>
          </button>
        ))}
      </div>
    </div>
  );
}

function GalleryDialogItem({ galleryItem }: { galleryItem: GalleryItem }) {
  const history = useHistory();
  return (
    <div className="p-4 sm:p-8">
      <div className="md:hidden mb-4">
        <button
          onClick={action(() => {
            selectedGalleryItemIdBox.set(undefined);
          })}
          className="text-neutral-400 hover:text-neutral-600 transition"
        >
          {"<- gallery"}
        </button>
      </div>
      <div className="block md:flex mb-4">
        <div className="md:w-2/3 pr-4">
          <div className="text-3xl font-medium mb-4">{galleryItem.title}</div>
          <div className="text-sm max-w-xl pb-4">{galleryItem.description}</div>
        </div>
        <div className="md:border-l border-neutral-150 md:pl-4">
          <a
            href={`https://natto.dev/@paul/${galleryItem.id}`}
            onClick={action((e) => {
              e.preventDefault();
              showGalleryDialogBox.set(false);
              history.push(getPublishedCanvasPath("paul", galleryItem.id));
            })}
            className="inline-block button button-dark"
          >
            {"visit canvas →"}
          </a>
        </div>
      </div>
      <div className="bg-neutral-100 overflow-hidden rounded-md aspect-[1.6]">
        <GalleryImage item={galleryItem} sizes="100vw" />
      </div>
    </div>
  );
}

const GalleryDialogContent = observer(() => {
  const selectedGalleryItem = computed(() => {
    const selectedGalleryItemId = selectedGalleryItemIdBox.get();
    if (selectedGalleryItemId === undefined) {
      return undefined;
    }
    return GALLERY_ITEMS.find((item) => item.id === selectedGalleryItemId);
  }).get();

  return (
    <div className="flex h-full">
      <div className="hidden md:block w-1/5 min-w-[12rem] flex-shrink-0 bg-secondary-50 pt-4">
        <button
          onClick={action(() => {
            selectedGalleryItemIdBox.set(undefined);
          })}
          className={classNames(
            "block w-full text-left px-4 py-2 transition focus:text-neutral-500 no-focus-visible",
            selectedGalleryItem === undefined
              ? "text-neutral-500"
              : "text-neutral-300 hover:text-neutral-500"
          )}
        >
          <FeatherIcon icon="grid" size="md" />
        </button>
        {GALLERY_ITEMS.map((galleryItem) => (
          <button
            onClick={action(() => {
              selectedGalleryItemIdBox.set(galleryItem.id);
            })}
            className={classNames(
              "block w-full text-left font-medium px-4 py-2 transition",
              galleryItem.id === selectedGalleryItemIdBox.get()
                ? "text-neutral-600"
                : "text-neutral-400 hover:text-neutral-600"
            )}
            key={galleryItem.slug}
          >
            <div className="text-base flex items-center justify-between">
              {galleryItem.title}
            </div>
          </button>
        ))}
      </div>
      <div className="flex-grow overflow-auto">
        {selectedGalleryItem !== undefined ? (
          <GalleryDialogItem
            galleryItem={selectedGalleryItem}
            key={selectedGalleryItem.id}
          />
        ) : (
          <GalleryDialogHome />
        )}
      </div>
    </div>
  );
});

export const GalleryDialogController = observer(() => {
  return (
    <DialogRoot
      open={showGalleryDialogBox.get()}
      onOpenChange={action((open) => {
        showGalleryDialogBox.set(open);
      })}
    >
      <DialogPortal>
        <DialogOverlay bgOpacityClassName="bg-opacity-75" />
        <DialogContent className="w-[95vw] xl:w-[90vw] h-[90vh]">
          <GalleryDialogContent />
          <DialogCloseButton className="absolute top-3 right-3" />
        </DialogContent>
      </DialogPortal>
    </DialogRoot>
  );
});
