import classNames from "classnames";
import React, { forwardRef } from "react";

export const TextInput = forwardRef<
  HTMLInputElement,
  React.InputHTMLAttributes<HTMLInputElement>
>((props, ref) => {
  const { className, ...otherProps } = props;
  return (
    <input
      type="text"
      {...otherProps}
      className={classNames(
        "font-medium bg-neutral-0 border border-neutral-200 h-8 px-2 rounded",
        className
      )}
      ref={ref}
    />
  );
});
