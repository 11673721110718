export type User = {
  id: string;
  username: string | undefined;
  beta?: string[] | undefined;
};

export type JSONValue =
  | boolean
  | string
  | JSONValue[]
  | { [key: string]: JSONValue }
  | Function
  | Symbol;
export type Position = [x: number, y: number];
export type Size = [w: number, h: number];
export type Rect = [x: number, y: number, w: number, h: number];

export type PaneOutput =
  | ["value", any]
  | ["error", any]
  | [type: "waiting", id?: string]
  | ["running"];

export type PaneExpression = string;
export enum PaneType {
  Evaluate = 0,
  InputRequest = 1,
  OutputResponse = 2,
  Datastore = 3,
  OutputRenderer = 4,
  InputText = 5,
  EvaluateGlobal = 6,
  DomElement = 7,
  State = 8,
  Import = 9,
  EvaluateTemplate = 10,
  EnvironmentVariable = 11,
  Library = 12,
  LLM = 13,
  Function = 14,
}

export const PaneTypeNames: Record<PaneType, string> = {
  [PaneType.Evaluate]: "eval",
  [PaneType.EvaluateGlobal]: "global",
  [PaneType.Import]: "import",
  [PaneType.State]: "state",
  [PaneType.DomElement]: "dom",
  [PaneType.InputText]: "text",
  [PaneType.OutputRenderer]: "table",
  [PaneType.InputRequest]: "request",
  [PaneType.OutputResponse]: "response",
  [PaneType.Datastore]: "datastore",
  [PaneType.EvaluateTemplate]: "template",
  [PaneType.EnvironmentVariable]: "environment",
  [PaneType.Library]: "library",
  [PaneType.LLM]: "LLM",
  [PaneType.Function]: "function",
};

export type SupportedBabelPlugin =
  | "transform-react-jsx"
  | [type: "import", url: string];

export type PaneInput = {
  id: string;
  name?: string;
  source?: [paneId: string, outputIndex: number];
};

export type TemplatePaneInput = {
  name?: string;
  source: string | [paneIndex: number, outputIndex?: number];
};

export type ImportModule =
  | [type: "npm", moduleId: string, pinUrl?: string]
  | [type: "url", url: string]
  | [type: "script", url: string];

export type ConstructedPane = {
  z?: number;
  rect?: Rect;
  name?: string;
} & (
  | {
      type: PaneType.Evaluate;
      inputs: TemplatePaneInput[];
      expressionType: EvaluateExpressionType;
      expression: string;
      renderOutput?: PaneRenderOutput;
      babelPlugins?: SupportedBabelPlugin[];
    }
  | {
      type: PaneType.Import;
      module: ImportModule;
      useDefault?: boolean;
    }
  | Extract<PaneCoreType, { type: PaneType.State }>
);

export type ConstructedTemplate = {
  panes: ConstructedPane[];
  inputs: { id: string; name: string; description?: string }[];
};

export type EvalPaneTemplate = {
  id: string;
  schema: any;
  uiSchema?: any;
  construct: (metadata: Record<string, any>) => ConstructedTemplate;
};

export type PaneRenderOutput =
  | ["dom"]
  | ["html"]
  | [
      type: "react",
      options?: { ReactDOM?: [paneId: string, outputIndex: number] }
    ]
  | ["table"]
  | ["text"]
  | ["graphviz"]
  | [type: "custom", source?: [paneId: string, outputIndex: number]];

export type EvalPaneInputId = string | ["render", "custom" | "react-dom"];
// A way to reference a pane input position, including virtual inputs
export type PaneInputGlobalKey =
  | [paneType: PaneType.Evaluate, paneId: string, inputId: EvalPaneInputId]
  | [paneType: PaneType.EvaluateTemplate, paneId: string, inputId: string]
  | [paneType: PaneType.LLM, paneId: string, inputId: string];

export enum EvaluateExpressionType {
  Expression = 1,
  FunctionBody = 2,
  Text = 3,
}

export type StateControl =
  | [type: "number", options?: { min?: number; max?: number; step?: number }]
  | [type: "boolean"]
  | [type: "select", options: { optionsExpression: string }]
  | [type: "text"];

export enum PaneFlag {
  Collapse = 1 << 2,
}

export type PaneCoreType =
  | {
      type: PaneType.Evaluate;
      inputs: PaneInput[];
      autorun: boolean;
      expression: string;
      editorHeight: number | undefined;
      babelPlugins?: SupportedBabelPlugin[];
      renderOutput?: PaneRenderOutput;
      // undefined = EvaluateExpressionType.Expression
      expressionType?: EvaluateExpressionType;
    }
  | {
      type: PaneType.EvaluateGlobal;
      autorun: boolean;
      expression: string;
      editorHeight?: number;
    }
  | {
      type: PaneType.EvaluateTemplate;
      templateId: string;
      templateInputSources: Record<
        string,
        [paneId: string, outputIndex: number]
      >;
      metadata: Record<string, any>;
      editorHeight?: number;
    }
  | {
      type: PaneType.InputText;
      text: string;
    }
  | {
      type: PaneType.State;
      initialExpression: string;
      control?: StateControl;
      editorHeight?: number;
    }
  | {
      type: PaneType.Import;
      module?: ImportModule;
      isGlobal?: boolean;
      useDefault?: boolean;
    }
  | {
      type: PaneType.EnvironmentVariable;
      key: string | undefined;
    }
  | {
      type: PaneType.Library;
    }
  | {
      type: PaneType.LLM;
      config: LLMConfig;
      inputSource?: [paneId: string, outputIndex: number];
      editorHeight?: number;
    }
  | {
      type: PaneType.Function;
      paneIds: string[];
      inputPaneId?: string;
      outputPaneId?: string;
    };

export type Pane = {
  id: string;
  z: number;
  rect: Rect;
  name?: string;
  flags?: number;
} & PaneCoreType;

export const OPENAI_MODELS = [
  "gpt-3.5-turbo",
  "text-davinci-003",
  "text-curie-001",
  "text-babbage-001",
  "text-ada-001",
  "text-davinci-002",
  "code-davinci-002",
  "code-cushman-001",
];

export type OpenAIModel = (typeof OPENAI_MODELS)[number];

export type LLMConfig = {
  provider: "openai";
  model: OpenAIModel;
  // suffix?: string;
  max_tokens?: number;
  temperature?: number;
  // top_p?: number;
  // n?: number;
  // stream?: boolean;
  // logprobs?: number;
  // echo?: boolean;
  stop?: string[];
  // presence_penalty?: number;
  // frequency_penalty?: number;
  // best_of?: number;
};

export type PaneLayoutLegacy = {
  id: string;
  name: string;
  panes: Record<
    string,
    {
      rect: Rect;
      z: number;
      editorHeight?: number | undefined;
      hidden?: true;
    }
  >;
};

export enum CanvasLayoutPaneRenderType {
  RunButton = 2,
}

export type CanvasLayoutPane = {
  id: string;
  rect: Rect;
  z: number;
  editorHeight?: number | undefined;
  render?: [CanvasLayoutPaneRenderType];
};

export type CanvasLayout = {
  id: string;
  name: string;
  panes: CanvasLayoutPane[];
};

export type Canvas = {
  id: string;
  name: string;
  panes: Pane[];
  layouts: CanvasLayout[];
  defaultLayoutId?: string;
  createdTime: number;
  user?: {
    id: string;
    username: string;
  };
};

export enum ActionType {
  NoOp = 0,
  SetCanvasName = 1,
  CreatePane = 2,
  CreateTransformPane = 3,
  EjectTemplatePane = 4,
  PastePanes = 5,
  SetPaneRect = 6,
  SetPaneZ = 7,
  SetPaneInputsDeprecated = 8,
  PushPaneInput = 9,
  SetPaneInputName = 10,
  SetPaneInputSource = 11,
  SetPaneTemplateInputSource = 12,
  DeletePaneInput = 13,
  SetPaneEditorHeight = 14,
  SetPaneName = 15,
  DeletePanes = 16,
  SetPaneExpression = 17,
  SetPaneInitialExpression = 18,
  SetPaneMetadata = 19,
  SetPaneRenderOutput = 20,
  AddPaneBabelPlugin = 21,
  RemovePaneBabelPlugin = 22,
  SetPaneIsFunctionBody = 23,
  SetPaneAutorun = 24,
  SetPaneModule = 25,
  SetPaneUseDefault = 26,
  SetPaneIsGlobal = 27,
  SetPaneText = 28,
  SetPaneEnvironmentKey = 29,
  SetPaneRectsMulti = 30,
  CreateLayout = 31,
  SetLayoutPaneRect = 32,
  SetLayoutPaneZ = 33,
  SetLayoutPaneEditorHeight = 34,
  SetLayoutPaneHidden = 35,
  SetLayoutPaneRectsMulti = 36,
  SetLayoutName = 37,
  DeleteLayout = 38,
  UpdatePaneCodemirror = 39,
  SetPaneExpressionType = 40,
  SetPaneStateControl = 41,
  PresenceCursor = 42,
  PresencePaneId = 43,
  PresenceTextRange = 44,
  PresenceName = 45,
  SetDefaultLayout = 46,
  UndoDeletePanes = 47,
  AddPaneToLayout = 48,
  RemovePaneFromLayout = 49,
  SetLayoutPaneRender = 50,
  SetLayoutPanePosition = 51,
  SetPanePosition = 52,
  SetPaneCollapse = 53,
  SetPaneLLMInputSource = 54,
  SetPaneLLMConfig = 55,
  CreateFunctionPane = 56,
  SetFunctionPaneInputPaneId = 57,
  SetFunctionPaneOutputPaneId = 58,
  GenerateLLMText = 59,
}

export type UndoDeletePaneOutput = {
  paneId: string;
  outputIndex: number;
} & (
  | { paneInputId: string }
  | { renderOutput: ["custom"] | ["react-dom"] }
  | { llmInput: true }
  | { templateInputSource: string }
);

export type Action =
  | [ActionType.NoOp]
  | [ActionType.SetCanvasName, { name: string }]
  | [
      ActionType.CreatePane,
      {
        paneId: string;
        type: PaneType;
        position: Position;
        size?: Position;
        name?: string;
        expression?: string;
        editorHeight?: number;
        templateId?: string;
        metadata?: any;
      }
    ]
  | [
      ActionType.CreateTransformPane,
      {
        paneId: string;
        position: Position;
        paneInput: PaneInput;
        expression: string;
        name?: string;
      }
    ]
  | [
      ActionType.CreateFunctionPane,
      {
        paneId: string;
        position: Position;
        paneIds: string[];
      }
    ]
  | [ActionType.EjectTemplatePane, { paneId: string }]
  | [ActionType.PastePanes, { panes: Pane[] }]
  | [ActionType.SetPaneRect, { paneId: string; rect: Rect }]
  | [ActionType.SetPanePosition, { paneId: string; position: Position }]
  | [ActionType.SetPaneZ, { paneId: string; z: number }]
  | [ActionType.SetPaneCollapse, { paneId: string; collapse: boolean }]
  | [
      ActionType.PushPaneInput,
      {
        paneId: string;
        paneInput: PaneInput;
      }
    ]
  | [
      ActionType.SetPaneInputName,
      {
        paneId: string;
        paneInputId: string;
        name: string;
      }
    ]
  | [
      ActionType.SetPaneInputSource,
      {
        paneId: string;
        paneInputId: string;
        source: [paneId: string, outputIndex: number] | undefined;
      }
    ]
  | [
      ActionType.SetPaneTemplateInputSource,
      {
        paneId: string;
        paneInputId: string;
        source: [paneId: string, outputIndex: number] | undefined;
      }
    ]
  | [
      ActionType.SetPaneLLMInputSource,
      {
        paneId: string;
        source: [paneId: string, outputIndex: number] | undefined;
      }
    ]
  | [
      ActionType.DeletePaneInput,
      {
        paneId: string;
        paneInputId: string;
      }
    ]
  | [
      ActionType.SetPaneEditorHeight,
      {
        paneId: string;
        editorHeight: number | undefined;
      }
    ]
  | [
      ActionType.SetPaneName,
      {
        paneId: string;
        name: string | undefined;
      }
    ]
  | [ActionType.DeletePanes, { paneIds: string[] }]
  | [
      ActionType.UndoDeletePanes,
      {
        deletions: {
          pane: Pane;
          outputs: UndoDeletePaneOutput[];
          layouts: { [layoutId: string]: CanvasLayoutPane };
        }[];
      }
    ]
  | [
      ActionType.SetPaneExpression,
      {
        paneId: string;
        expression: string;
      }
    ]
  | [
      ActionType.UpdatePaneCodemirror,
      {
        paneId: string;
        v: number;
        f: "expression" | "text";
        changes: any; // serialized ChangeSet
      }
    ]
  | [
      ActionType.SetPaneInitialExpression,
      {
        paneId: string;
        initialExpression: string;
      }
    ]
  | [
      ActionType.SetPaneMetadata,
      {
        paneId: string;
        metadata: Record<string, any>;
      }
    ]
  | [
      ActionType.SetPaneRenderOutput,
      {
        paneId: string;
        renderOutput: PaneRenderOutput | undefined;
      }
    ]
  | [
      ActionType.AddPaneBabelPlugin,
      {
        paneId: string;
        babelPlugin: SupportedBabelPlugin;
      }
    ]
  | [
      ActionType.RemovePaneBabelPlugin,
      {
        paneId: string;
        babelPlugin: SupportedBabelPlugin;
      }
    ]
  | [
      ActionType.SetPaneIsFunctionBody,
      {
        paneId: string;
        isFunctionBody: boolean;
      }
    ]
  | [
      ActionType.SetPaneExpressionType,
      {
        paneId: string;
        expressionType: EvaluateExpressionType;
      }
    ]
  | [
      ActionType.SetPaneAutorun,
      {
        paneId: string;
        autorun: boolean;
      }
    ]
  | [
      ActionType.SetPaneModule,
      {
        paneId: string;
        module: ImportModule | undefined;
      }
    ]
  | [
      ActionType.SetPaneUseDefault,
      {
        paneId: string;
        useDefault: boolean;
      }
    ]
  | [
      ActionType.SetPaneIsGlobal,
      {
        paneId: string;
        isGlobal: boolean;
      }
    ]
  | [ActionType.SetPaneText, { paneId: string; text: string }]
  | [ActionType.SetPaneEnvironmentKey, { paneId: string; key: string }]
  | [
      ActionType.SetPaneStateControl,
      { paneId: string; control: StateControl | undefined }
    ]
  | [ActionType.SetPaneLLMConfig, { paneId: string; key: string; value: any }]
  | [
      ActionType.SetFunctionPaneInputPaneId,
      { paneId: string; inputPaneId: string | undefined }
    ]
  | [
      ActionType.SetFunctionPaneOutputPaneId,
      { paneId: string; outputPaneId: string | undefined }
    ]
  | [ActionType.SetPaneRectsMulti, { paneRects: Record<string, Rect> }]
  | [
      ActionType.CreateLayout,
      {
        layoutId: string;
        name: string;
      }
    ]
  | [
      ActionType.SetDefaultLayout,
      {
        layoutId: string | undefined;
      }
    ]
  | [
      ActionType.AddPaneToLayout,
      {
        layoutId: string;
        paneId: string;
        rect: Rect;
        z: number;
        editorHeight?: number | undefined;
      }
    ]
  | [
      ActionType.SetLayoutPaneRect,
      {
        layoutId: string;
        paneId: string;
        rect: Rect;
      }
    ]
  | [
      ActionType.SetLayoutPanePosition,
      {
        layoutId: string;
        paneId: string;
        position: Position;
      }
    ]
  | [ActionType.SetLayoutPaneZ, { layoutId: string; paneId: string; z: number }]
  | [
      ActionType.SetLayoutPaneEditorHeight,
      { layoutId: string; paneId: string; editorHeight: number | undefined }
    ]
  | [
      ActionType.SetLayoutPaneHidden,
      { layoutId: string; paneId: string; hidden: boolean }
    ]
  | [
      ActionType.SetLayoutPaneRectsMulti,
      {
        layoutId: string;
        paneRects: Record<string, Rect>;
      }
    ]
  | [
      ActionType.RemovePaneFromLayout,
      {
        layoutId: string;
        paneId: string;
      }
    ]
  | [
      ActionType.SetLayoutPaneRender,
      {
        layoutId: string;
        paneId: string;
        render: [CanvasLayoutPaneRenderType] | undefined;
      }
    ]
  | [
      ActionType.SetLayoutName,
      {
        layoutId: string;
        name: string;
      }
    ]
  | [ActionType.DeleteLayout, { layoutId: string }]
  | [
      ActionType.GenerateLLMText,
      { inputPaneId: string; prompt: string; options?: Record<string, any> }
    ]
  | [type: ActionType.PresenceCursor, cursor: [x: number, y: number]]
  | [type: ActionType.PresencePaneId, paneId: string | null]
  | [
      type: ActionType.PresenceTextRange,
      data: [paneId: string, textRange: [from: number, to: number]]
    ]
  | [type: ActionType.PresenceName, name: string];

export type ActionMeta = [id: number];

export type PatchPath = (string | number | [string])[];
export enum PatchOp {
  Add = 1,
  Remove = 2,
  Replace = 3,
  CodemirrorUpdate = 4,
}
export type Patch =
  | [PatchOp.Add, PatchPath, any]
  | [PatchOp.Remove, PatchPath]
  | [PatchOp.Replace, PatchPath, any]
  | [PatchOp.CodemirrorUpdate, PatchPath, any];

// null means delete
export type PresenceUpdate = {
  name?: string;
  // cursor
  c?: Position;
  // paneId
  p?: string | null;
  // text range
  t?: [from: number, to: number] | null;
};

export enum ServerMessageType {
  Init = 1,
  Identify = 2,
  Update = 3,
  Error = 4,
  ActionMismatchVersion = 5,
  SetPresence = 6,
}

export enum ServerMessageErrorCode {
  TextTooLong = 1001,
  ExpressionTooLong = 1002,
}

export type ServerMessage =
  | [
      ServerMessageType.Init,
      {
        c: Canvas;
        p?: PresenceState;
        v: number;
        cl: number | undefined;
        la: number | undefined;
        rc?: { [paneId: string]: { [paneKey: string]: readonly number[] } };
      }
    ]
  | [
      ServerMessageType.Identify,
      {
        cl: number;
        la: number;
      }
    ]
  | [
      ServerMessageType.Update,
      {
        p?: Patch[];
        pu?: PresenceUpdate;
        cl: number;
        la: number;
        v?: number;
      }
    ]
  | [
      ServerMessageType.Error,
      {
        c: ServerMessageErrorCode;
        m: string | undefined;
      }
    ]
  | [
      ServerMessageType.ActionMismatchVersion,
      {
        v: number;
      }
    ]
  | [
      ServerMessageType.SetPresence,
      // `id = true` means this was the UNKNOWN_CLIENT_ID
      { cl: number; p: UserPresence | null; id?: true }
    ];

export type UserPresence = {
  cursor?: [x: number, y: number];
  color: string;
  name?: string;
} & (
  | { paneId?: undefined; textRange?: undefined }
  | {
      paneId: string;
      textRange?: [from: number, to: number];
    }
);

export type PresenceState = {
  [clientId: string]: UserPresence;
};

export enum ClientMessageType {
  Push = 1,
}

export type ClientMessage =
  | [ClientMessageType.Push, { a: [Action, ActionMeta][] }];

export type Invite = {
  canvasId: string;
  code: string;
  expiresAt: number;
};
