import jwtDecode from "jwt-decode";
import {
  action,
  makeObservable,
  observable,
  reaction,
  runInAction,
} from "mobx";
import { logEvent, setUserId } from "../Amplitude";
import { atom } from "../atom";
import { SERVER_HOST, UNSAVED_CANVAS_NAME } from "../Constants";
import { canvasSelectionAtom, userAtom } from "../SharedIframeState";
import { Canvas, Invite, SyncCanvasId, User } from "../Types";
import { areArraysEqual } from "../Utils";
import { myPublishedCanvasIdsAndNamesAtom } from "./AppState";
import { getNewCanvasName } from "./ParentUtils";
import WelcomeCanvas from "./WelcomeCanvas.json";

if (window.__IS_SANDBOX === true) {
  throw new Error("loading API in sandbox");
}

const LOCAL_STORAGE_SESSION_KEY = "natto:session";
const LOCAL_STORAGE_USERNAME_KEY = "natto:username";

export type Session = {
  expiresAt: number;
  accessToken: string;
  refreshToken: string;
  userId: string;
};

export const sessionAtom = atom<Session | undefined>(undefined);

reaction(
  () => sessionAtom.value?.userId,
  action(async (userId) => {
    if (userId === undefined) {
      userAtom.value = undefined;
      myPublishedCanvasIdsAndNamesAtom.value = [];
      userEnvironmentVariablesMobx.value = undefined;
      userEnvironmentVariablesMobx.loadingPromise = undefined;
    }
  })
);

reaction(
  () => userAtom.value?.username,
  (username) => {
    if (username === undefined) {
      localStorage.removeItem(LOCAL_STORAGE_USERNAME_KEY);
    } else {
      localStorage.setItem(LOCAL_STORAGE_USERNAME_KEY, username);
    }
  }
);
export function getCachedUsername(): string | undefined {
  return localStorage.getItem(LOCAL_STORAGE_USERNAME_KEY) ?? undefined;
}

function refreshSession(prevSession: Session): Session | undefined {
  const sessionJSON = localStorage.getItem(LOCAL_STORAGE_SESSION_KEY);
  if (sessionJSON === null) {
    return undefined;
  }
  const refreshedSession = JSON.parse(sessionJSON) ?? undefined;
  if (
    refreshedSession?.accessToken !== undefined &&
    refreshedSession.accessToken !== prevSession.accessToken
  ) {
    runInAction(() => {
      sessionAtom.value = refreshedSession;
    });
    return refreshedSession;
  }
  return undefined;
}

let didInit = false;
export async function initSession() {
  if (didInit) {
    return;
  }
  didInit = true;
  runInAction(() => {
    const sessionJSON = localStorage.getItem(LOCAL_STORAGE_SESSION_KEY);
    if (sessionJSON === null) {
      myPublishedCanvasIdsAndNamesAtom.value = [];
      if (window.location.pathname !== "/auth") {
        fetch(
          `${SERVER_HOST}/users/@me?href=${encodeURIComponent(
            window.location.href
          )}&ref=${encodeURIComponent(document.referrer)}`
        );
      }
      return;
    }
    const session = JSON.parse(sessionJSON);
    if (session.userId === undefined && session.user !== undefined) {
      session.userId = session.user.id;
      delete session.user;
      localStorage.setItem(LOCAL_STORAGE_SESSION_KEY, JSON.stringify(session));
    }
    // TODO: validate
    sessionAtom.value = session;
    if (session.userId !== undefined) {
      const username = getCachedUsername();
      if (username !== undefined) {
        userAtom.value = {
          id: session.userId,
          username,
        };
      }
    }
    fetchAndSetUser();
  });
}

export function signIn() {
  window.location.href = `${SERVER_HOST}/auth/login?redirect_uri=${window.location.pathname}${window.location.search}`;
}

export function signOut() {
  localStorage.removeItem(LOCAL_STORAGE_SESSION_KEY);
  runInAction(() => {
    sessionAtom.value = undefined;
  });
  setUserId(null);
}

export function setSession(accessToken: string, refreshToken: string) {
  runInAction(() => {
    const tokenContents: any = jwtDecode(accessToken);
    const session = {
      expiresAt: tokenContents.exp,
      accessToken,
      refreshToken,
      userId: tokenContents.sub,
    };
    localStorage.setItem(LOCAL_STORAGE_SESSION_KEY, JSON.stringify(session));
    sessionAtom.value = session;
    setUserId(session.userId);
  });
}

let refreshBearerTokenPromise: Promise<string> | undefined;
const REFRESH_TOKEN_TIMESTAMP_KEY = "refresh_token_timestamp";
async function refreshBearerToken(session: Session): Promise<string> {
  try {
    const refreshedSession = refreshSession(session);
    if (refreshedSession !== undefined) {
      return refreshedSession.accessToken;
    }
    const refreshTokenTimestampString =
      localStorage.getItem(REFRESH_TOKEN_TIMESTAMP_KEY) ?? undefined;
    // check if another tab is refreshing
    if (refreshTokenTimestampString !== undefined) {
      const refreshTokenTimestamp = parseInt(refreshTokenTimestampString);
      if (Date.now() - refreshTokenTimestamp < 1000) {
        await new Promise<void>((resolve) => {
          setTimeout(() => resolve(), 1000);
        });
        const refreshedSession = refreshSession(session);
        if (refreshedSession !== undefined) {
          return refreshedSession.accessToken;
        }
      }
    }
    localStorage.setItem(REFRESH_TOKEN_TIMESTAMP_KEY, `${Date.now()}`);
    const response = await fetch(`${SERVER_HOST}/auth/refreshToken`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        user_id: session.userId,
        refresh_token: session.refreshToken,
      }),
    });
    if (response.status >= 400) {
      signOut();
      throw new Error();
    }
    const json = await response.json();
    setSession(json.accessToken, json.refreshToken);
    return json.accessToken;
  } catch {
    logEvent("error refreshing token", {
      "refresh token": session.refreshToken,
      "expired at": session.expiresAt,
    });
    throw new Error();
  } finally {
    localStorage.removeItem(REFRESH_TOKEN_TIMESTAMP_KEY);
  }
}

export async function getBearerToken() {
  const session = sessionAtom.value;
  if (session === undefined) {
    throw new Error();
  }
  const now = Date.now();
  if (session.expiresAt > now / 1000 + 1 /* 1 second buffer */) {
    return session.accessToken;
  }
  if (refreshBearerTokenPromise === undefined) {
    refreshBearerTokenPromise = refreshBearerToken(session);
    refreshBearerTokenPromise.finally(() => {
      refreshBearerTokenPromise = undefined;
    });
  }
  return refreshBearerTokenPromise;
}

async function fetchSocketToken(syncCanvasId: SyncCanvasId): Promise<string> {
  let response;
  if (syncCanvasId[0] === "canvas") {
    const bearerToken = await getBearerToken();
    response = await fetch(
      `${SERVER_HOST}/auth/socketToken/${syncCanvasId[1]}`,
      {
        method: "POST",
        headers: { authorization: `Bearer ${bearerToken}` },
      }
    );
  } else if (syncCanvasId[0] === "invite") {
    let bearerToken;
    try {
      bearerToken = await getBearerToken();
    } catch {}
    response = await fetch(
      `${SERVER_HOST}/auth/inviteSocketToken/${syncCanvasId[1]}`,
      {
        method: "POST",
        headers:
          bearerToken !== undefined
            ? {
                authorization: `Bearer ${bearerToken}`,
              }
            : undefined,
      }
    );
  } else {
    throw new Error();
  }
  if (response.status >= 400) {
    logEvent("error", {
      type: "getSocketToken",
      metadata: JSON.stringify({ responseStatus: response.status }),
    });
    throw new Error();
  }
  const json = await response.json();
  const { token } = json;
  return token;
}

let socketTokenCache:
  | [
      syncCanvasId: SyncCanvasId,
      time: number,
      socketTokenPromise: Promise<string>
    ]
  | undefined;
export async function getSocketToken(
  syncCanvasId: SyncCanvasId
): Promise<string> {
  // The parent frame optimistically fetches a socket token that the sandbox
  // frame will use if request is made within 10 seconds.
  if (
    socketTokenCache !== undefined &&
    areArraysEqual(syncCanvasId, socketTokenCache[0]) &&
    socketTokenCache[1] > Date.now() - 10000
  ) {
    return socketTokenCache[2];
  }
  socketTokenCache = undefined;
  const socketTokenPromise = fetchSocketToken(syncCanvasId);
  socketTokenCache = [syncCanvasId, Date.now(), socketTokenPromise];
  return socketTokenPromise;
}

async function fetchMe(): Promise<{
  user: User;
  canvases: { id: string; name: string; createdTime: number }[];
}> {
  const bearerToken = await getBearerToken();
  const response = await fetch(
    `${SERVER_HOST}/users/@me?href=${encodeURIComponent(
      window.location.href
    )}&ref=${encodeURIComponent(document.referrer)}`,
    {
      method: "GET",
      headers: { authorization: `Bearer ${bearerToken}` },
    }
  );
  if (response.status >= 400) {
    logEvent("error", {
      type: "fetching me",
      metadata: JSON.stringify({
        "bearer token": bearerToken,
        status: response.status,
      }),
    });
    if (response.status === 401) {
      signOut();
    }
    throw new Error();
  }
  const json = await response.json();
  const { user, canvases } = json;
  return {
    user: {
      id: user.id,
      username: user.username ?? undefined,
      beta: user.beta ?? [],
    },
    canvases,
  };
}

export async function fetchAndSetUser() {
  const { user, canvases } = await fetchMe();
  if (user.id === sessionAtom.value?.userId) {
    runInAction(() => {
      userAtom.value = user;
      myPublishedCanvasIdsAndNamesAtom.value = canvases
        .sort((a, b) => b.createdTime - a.createdTime)
        .map(({ id, name }) => [id, name]);
    });
  } else {
    signOut();
  }
}

export async function updateUsername(username: string) {
  const bearerToken = await getBearerToken();
  const response = await fetch(`${SERVER_HOST}/users/@me`, {
    method: "PATCH",
    headers: {
      authorization: `Bearer ${bearerToken}`,
      "content-type": "application/json",
    },
    body: JSON.stringify({ username }),
  });
  const json = await response.json();
  if (response.status >= 400) {
    throw json;
  }
  runInAction(() => {
    userAtom.value = {
      id: json.id,
      username: json.username ?? undefined,
      beta: json.beta ?? [],
    };
  });
  return json;
}

export async function fetchMyPublishedCanvases(): Promise<void> {
  const bearerToken = await getBearerToken();
  const response = await fetch(`${SERVER_HOST}/users/@me/canvases`, {
    method: "GET",
    headers: { authorization: `Bearer ${bearerToken}` },
  });
  const json: {
    id: string;
    name: string;
    createdTime: number;
  }[] = await response.json();
  if (response.status >= 400) {
    throw json;
  }
  runInAction(() => {
    myPublishedCanvasIdsAndNamesAtom.value = json
      .sort((a, b) => b.createdTime - a.createdTime)
      .map(({ id, name }) => [id, name]);
  });
}

export async function fetchCanvas(
  canvasId: string
): Promise<Canvas | undefined> {
  if (canvasId === WelcomeCanvas.id) {
    return WelcomeCanvas as any;
  }
  const response = await fetch(`${SERVER_HOST}/canvases/${canvasId}`, {
    method: "GET",
  });
  if (response.status === 404) {
    return undefined;
  }
  if (response.status >= 400) {
    const json = await response.json();
    throw json;
  }
  const json = await response.json();
  const { canvas } = json;
  return canvas;
}

export async function publishCanvas(canvas: Canvas): Promise<string> {
  const bearerToken = await getBearerToken();
  const response = await fetch(`${SERVER_HOST}/canvases`, {
    method: "POST",
    headers: {
      authorization: `Bearer ${bearerToken}`,
      "content-type": "application/json",
    },
    body: JSON.stringify({
      name:
        canvas.name === UNSAVED_CANVAS_NAME ? getNewCanvasName() : canvas.name,
      panes: canvas.panes,
      layouts: canvas.layouts,
    }),
  });
  if (response.status >= 400) {
    const json = await response.json();
    throw json;
  }
  const { canvasId } = await response.json();
  return canvasId;
}

export async function unpublishCanvas(canvasId: string): Promise<void> {
  const bearerToken = await getBearerToken();
  const response = await fetch(`${SERVER_HOST}/canvases/${canvasId}`, {
    method: "DELETE",
    headers: {
      authorization: `Bearer ${bearerToken}`,
    },
  });
  if (response.status >= 400) {
    const text = await response.text();
    throw text;
  }
}

type UserEnvironmentVariable = { key: string; value: string };

export const userEnvironmentVariablesMobx = makeObservable<{
  value: UserEnvironmentVariable[] | undefined;
  loadingPromise: Promise<UserEnvironmentVariable[] | undefined> | undefined;
}>(
  {
    value: undefined,
    loadingPromise: undefined,
  },
  {
    value: observable.ref,
    loadingPromise: observable.ref,
  }
);

export function fetchUserEnvironmentVariables(force: boolean = false) {
  if (!force) {
    if (userEnvironmentVariablesMobx.value !== undefined) {
      return Promise.resolve(userEnvironmentVariablesMobx.value);
    }
    if (userEnvironmentVariablesMobx.loadingPromise !== undefined) {
      return userEnvironmentVariablesMobx.loadingPromise;
    }
  }
  const loadingPromise = new Promise<UserEnvironmentVariable[] | undefined>(
    async (resolve, reject) => {
      try {
        const bearerToken = await getBearerToken();
        const response = await fetch(
          `${SERVER_HOST}/users/@me/environment-variables`,
          {
            method: "GET",
            headers: {
              authorization: `Bearer ${bearerToken}`,
            },
          }
        );
        const json = await response.json();
        if (response.status >= 400) {
          return;
        }
        if (userEnvironmentVariablesMobx.loadingPromise === loadingPromise) {
          runInAction(() => {
            userEnvironmentVariablesMobx.value = json;
            userEnvironmentVariablesMobx.loadingPromise = undefined;
          });
        }
        resolve(json);
      } catch {
        resolve(undefined);
      }
    }
  );
  runInAction(() => {
    userEnvironmentVariablesMobx.loadingPromise = loadingPromise;
  });
  return loadingPromise;
}

export async function setUserEnvironmentVariable(
  key: string,
  value: string,
  allowUpdate: boolean = false
) {
  const bearerToken = await getBearerToken();
  const response = await fetch(
    `${SERVER_HOST}/users/@me/environment-variables`,
    {
      method: "POST",
      headers: {
        authorization: `Bearer ${bearerToken}`,
        "content-type": "application/json",
      },
      body: JSON.stringify({
        key,
        value,
        ...(allowUpdate ? { allowUpdate } : {}),
      }),
    }
  );
  if (response.status >= 400) {
    throw new Error(await response.text());
  }
  await fetchUserEnvironmentVariables(true);
}

export async function deleteUserEnvironmentVariable(key: string) {
  const bearerToken = await getBearerToken();
  const response = await fetch(
    `${SERVER_HOST}/users/@me/environment-variables`,
    {
      method: "DELETE",
      headers: {
        authorization: `Bearer ${bearerToken}`,
        "content-type": "application/json",
      },
      body: JSON.stringify({
        key,
      }),
    }
  );
  if (response.status >= 400) {
    throw await response.text();
  }
  await fetchUserEnvironmentVariables(true);
}

export async function generateInvite(canvasIdArg?: string): Promise<Invite> {
  let canvasId = canvasIdArg;
  if (canvasId === undefined) {
    const canvasSelection = canvasSelectionAtom.value;
    if (canvasSelection?.[0] !== "user") {
      throw new Error();
    }
    canvasId = canvasSelection[2];
  }
  const bearerToken = await getBearerToken();
  const response = await fetch(`${SERVER_HOST}/canvases/${canvasId}/invites`, {
    method: "POST",
    headers: {
      authorization: `Bearer ${bearerToken}`,
    },
  });
  const json = await response.json();
  return json;
}

// @ts-ignore
window.generateInvite = generateInvite;

export async function fetchInvites(canvasId: string): Promise<Invite[]> {
  const bearerToken = await getBearerToken();
  const response = await fetch(`${SERVER_HOST}/canvases/${canvasId}/invites`, {
    method: "GET",
    headers: {
      authorization: `Bearer ${bearerToken}`,
    },
  });
  const json = await response.json();
  return json;
}

export async function revokeInvite(
  canvasId: string,
  inviteCode: string
): Promise<void> {
  const bearerToken = await getBearerToken();
  await fetch(`${SERVER_HOST}/canvases/${canvasId}/invites/${inviteCode}`, {
    method: "DELETE",
    headers: {
      authorization: `Bearer ${bearerToken}`,
    },
  });
}

export async function generateDebugVisualizerCode(
  canvasId: string,
  paneId: string,
  paneValues: { [paneId: string]: string }
): Promise<{ code: string }> {
  const bearerToken = await getBearerToken();
  const response = await fetch(
    `${SERVER_HOST}/canvases/${canvasId}/debugVisualizerCode`,
    {
      method: "POST",
      headers: {
        authorization: `Bearer ${bearerToken}`,
        "content-type": "application/json",
      },
      body: JSON.stringify({
        paneId,
        paneValues,
      }),
    }
  );
  const json = await response.json();
  return json;
}
