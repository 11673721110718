import { runInAction } from "mobx";
import { atom } from "../atom";

export const myPublishedCanvasIdsAndNamesAtom = atom<
  [id: string, name: string][] | undefined
>(undefined);

export function addMyPublishedCanvasIdAndName(
  canvasId: string,
  canvasName: string
) {
  runInAction(() => {
    myPublishedCanvasIdsAndNamesAtom.value = [
      [canvasId, canvasName],
      ...(myPublishedCanvasIdsAndNamesAtom.value ?? []),
    ];
  });
}

export function setMyPublishedCanvasName(canvasId: string, canvasName: string) {
  runInAction(() => {
    myPublishedCanvasIdsAndNamesAtom.value = (
      myPublishedCanvasIdsAndNamesAtom.value ?? []
    ).map((item) => (item[0] === canvasId ? [canvasId, canvasName] : item));
  });
}

export function removeMyPublishedCanvasIdAndName(canvasId: string) {
  runInAction(() => {
    myPublishedCanvasIdsAndNamesAtom.value =
      myPublishedCanvasIdsAndNamesAtom.value?.filter(
        (item) => item[0] !== canvasId
      );
  });
}
