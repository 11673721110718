import * as DialogPrimitive from "@radix-ui/react-dialog";
import type * as Radix from "@radix-ui/react-primitive";
import classNames from "classnames";
import React, { forwardRef, useEffect } from "react";
import { logEvent } from "../Amplitude";
import { FeatherIcon } from "./FeatherIcon";

export const DialogRoot = DialogPrimitive.Root;
export const DialogPortal = DialogPrimitive.Portal;

export const DialogOverlay = forwardRef<
  React.ElementRef<typeof DialogPrimitive.Overlay>,
  Radix.ComponentPropsWithoutRef<typeof DialogPrimitive.Overlay> & {
    bgOpacityClassName?: string;
  }
>((props, forwardedRef) => {
  const { bgOpacityClassName, className, ...itemProps } = props;
  return (
    <DialogPrimitive.Overlay
      {...itemProps}
      className={classNames("fixed animate-fade-in inset-0", className)}
      ref={forwardedRef}
    >
      <div
        className={classNames(
          "absolute inset-0 bg-neutral-400 dark:bg-neutral-0",
          bgOpacityClassName ?? "bg-opacity-25 dark:bg-opacity-50"
        )}
      />
    </DialogPrimitive.Overlay>
  );
});

export const DialogContent = forwardRef<
  React.ElementRef<typeof DialogPrimitive.Content>,
  Radix.ComponentPropsWithoutRef<typeof DialogPrimitive.Content>
>((props, forwardedRef) => {
  const { className, children, ...itemProps } = props;
  useEffect(() => {
    logEvent("view gallery dialog");
  }, []);
  return (
    <DialogPrimitive.Content
      {...itemProps}
      className="fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
      ref={forwardedRef}
    >
      <div
        className={classNames(
          "bg-neutral-0 dark:bg-neutral-150 rounded-lg shadow-lg dialog-animate-in overflow-hidden",
          className
        )}
      >
        {children}
      </div>
    </DialogPrimitive.Content>
  );
});

export const DialogCloseButton = forwardRef<
  React.ElementRef<typeof DialogPrimitive.Close>,
  Radix.ComponentPropsWithoutRef<typeof DialogPrimitive.Close>
>((props, forwardedRef) => {
  const { className, ...itemProps } = props;
  return (
    <DialogPrimitive.Close {...itemProps} asChild={true} ref={forwardedRef}>
      <button
        className={classNames(
          "flex p-1 text-neutral-400 hover:text-neutral-700 transition",
          className
        )}
      >
        <FeatherIcon icon="x" size="md" />
      </button>
    </DialogPrimitive.Close>
  );
});

export function DialogHeader({ children }: { children: React.ReactNode }) {
  return (
    <div className="px-6 py-4 flex items-center justify-between border-b border-neutral-100 dark:border-neutral-200">
      <div className="text-lg font-medium">{children}</div>
      <DialogCloseButton className="" />
    </div>
  );
}
