import React, { useEffect, useRef, useState } from "react";
import { logEvent } from "../Amplitude";
import {
  DialogContent,
  DialogOverlay,
  DialogPortal,
  DialogRoot,
} from "../ui/Dialog";
import { signOut, updateUsername } from "./API";

const USERNAME_REGEX = /^[a-zA-Z0-9]*$/;

function UserUsernameDialogContent() {
  const usernameInputRef = useRef<HTMLInputElement | null>(null);
  useEffect(() => void usernameInputRef.current!.focus(), []);
  useEffect(() => {
    logEvent("view username dialog");
  }, []);

  const [username, setUsername] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [serverError, setServerError] = useState<string | undefined>(undefined);
  let usernameError: string | undefined;
  if (!USERNAME_REGEX.test(username)) {
    usernameError = "!isAlphanumeric(username)";
  } else if (username.length < 3) {
    usernameError = "username.length < 3";
  }

  return (
    <div className="h-full overflow-auto px-6 pt-6 pb-10">
      <div className="text-lg font-medium mb-6">welcome!</div>
      <div>
        <form
          onSubmit={async (e) => {
            e.preventDefault();
            logEvent("submit username form", { username });
            try {
              setIsLoading(true);
              setServerError(undefined);
              await updateUsername(username);
            } catch (e: any) {
              if (e.code === "23505") {
                setServerError("username already taken");
              } else {
                setServerError("something went wrong");
              }
              logEvent("error", {
                type: "submit username",
                metadata: JSON.stringify({ code: e.code }),
              });
            } finally {
              setIsLoading(false);
            }
          }}
        >
          <div className="flex">
            <span className="text-neutral-400 relative top-2">natto.dev/@</span>
            <div className="ml-1">
              <div className="inline-block relative">
                <input
                  type="text"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  className="bg-neutral-200 focus:bg-neutral-100 rounded w-40 px-2 py-2 transition-colors"
                  spellCheck="false"
                  ref={usernameInputRef}
                />
                {username !== "" && usernameError !== undefined ? (
                  <div className="absolute top-2.5 left-full ml-2 text-3xs text-red-400 whitespace-nowrap">
                    {usernameError}
                  </div>
                ) : null}
              </div>
              <div className="mt-4 flex items-center">
                <button
                  className="button disabled:bg-neutral-50 disabled:text-neutral-400"
                  type="submit"
                  disabled={usernameError !== undefined || isLoading}
                >
                  save username
                </button>
                {serverError !== undefined ? (
                  <div className="ml-4 text-red-400">Error: {serverError}</div>
                ) : null}
              </div>
            </div>
          </div>
        </form>
        <div className="mt-4">
          Choose your username wisely! You won't be able to change it later
          (without emailing paul@natto.dev).
        </div>
        <div className="mt-4">
          Don't want to choose right now?{" "}
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              signOut();
            }}
            className="underline"
          >
            Sign out
          </a>{" "}
          and choose later.
        </div>
      </div>
    </div>
  );
}

export function UserUsernameDialogController() {
  return (
    <DialogRoot open={true}>
      <DialogPortal>
        <DialogOverlay />
        <DialogContent className="w-[90vw] max-w-lg max-h-[24rem]">
          <UserUsernameDialogContent />
        </DialogContent>
      </DialogPortal>
    </DialogRoot>
  );
}
